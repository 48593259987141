import React, { Component } from "react";
import type { Settlement } from "../../redux/modules/settlement";
import type { Transaction } from "../../redux/modules/transaction";
import { Button } from "semantic-ui-react";
import { CMD } from "../../helpers/escpos";
import { formatDate } from "../../helpers/date";
import { t } from "../../constant/translations";

const LF = String.fromCharCode(10);

type Props = {
  settlement: Settlement,
  transactions: Array<Transaction>,
};

export class PrintReceiptButton extends Component<Props> {
  renderText = () => {
    const { settlement, transactions } = this.props;
    let content =
      `${CMD.TEXT_FORMAT.TXT_ALIGN_CT}${CMD.TEXT_FORMAT.TXT_4SQUARE}` +
      t("labels.payment") +
      `${CMD.TEXT_FORMAT.TXT_NORMAL}${settlement.block_details.name}
${CMD.TEXT_FORMAT.TXT_ALIGN_LT}` +
      t("labels.payment_number") +
      `: ${settlement.id}` +
      t("resources.estate_capital") +
      `: ${settlement.estate_details.name}` +
      t("labels.client_number") +
      `: ${settlement.estate_details.vendor_id}` +
      t("labels.date") +
      `: ${settlement.settle_date}` +
      t("labels.deposited_amount") +
      `: ${settlement.deposit_amount} ` +
      t("resources.bgn") +
      t("titles.duties_paid") +
      `: ${settlement.settled_amount} ` +
      t("resources.bgn") +
      t("labels.prepaid_amount") +
      `: ${settlement.prepaid_amount} ` +
      t("resources.bgn");

    transactions.map((trans) => {
      if (!trans.charge) {
        return;
      }
      const details = trans.charge.details;
      content += "--------------------------------";
      content += t("resources.charge_to") + `: ${trans.charge.fund}${LF}`;
      content +=
        t("titles.from_date") +
        `: ${formatDate(trans.charge.charge_date)}${LF}`;

      details.map((detail) => {
        content += CMD.TEXT_FORMAT.TXT_ALIGN_LT;
        content += `${detail.model}${LF}`;
        content +=
          `${detail.value} X ${detail.model_price} ` +
          t("resources.bgn") +
          ` (` +
          t("labels.minimum") +
          ` ${detail.model_min_price} ` +
          t("resources.bgn") +
          `)${LF}`;
        content += CMD.TEXT_FORMAT.TXT_ALIGN_RT;
        content += `----------${LF}`;
        content += `${detail.amount} ` + t("resources.bgn") + `${LF}`;
        content += `${LF}`;
      });
      content += CMD.TEXT_FORMAT.TXT_ALIGN_RT;
      content +=
        t("labels.amount_due") +
        `: ${trans.charge.amount} ` +
        t("resources.bgn") +
        `${LF}`;
      content +=
        t("labels.paid_amount") +
        `: ${trans.amount} ` +
        t("resources.bgn") +
        `${LF}`;
      content += CMD.TEXT_FORMAT.TXT_ALIGN_LT;
      content += `${LF}`;
    });
    content += `${LF}${LF}`;
    content +=
      `${CMD.TEXT_FORMAT.TXT_ALIGN_CT}${CMD.TEXT_FORMAT.TXT_4SQUARE}` +
      t("labels.official_receipt_cap");
    content += `${LF}${LF}`;
    return content;
  };

  render() {
    const content = window.encodeURI(this.renderText());
    return (
      <a href={`rawbt:${content}`}>
        <Button content={t("labels.print")} />
      </a>
    );
  }
}
