import { EditEntity } from "../../../components/common/edit-entity";

import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ErrorWrap } from "../../../helpers/error";
import {
  clearError,
  createFund,
  deleteFund,
  updateFund,
} from "../../../redux/modules/fund";
import { getBlock } from "../../../redux/modules/blocks";
import {
  FormdataManager,
  getInitialState,
} from "../../../components/common/formdata";
import { t } from "../../../constant/translations";

const EditFundComponent = ({ entity, header, error, ...props }) => {
  const manager = FormdataManager.useState(getInitialState(entity)).withError(
    error
  );
  useEffect(() => {
    if (!entity) {
      manager.setValue("reported", true);
    }
  }, [entity]);
  return (
    <EditEntity
      manager={manager}
      header={header}
      entity={entity}
      error={error}
      {...props}
      trigger_props={{
        floated: "right",
      }}
    >
      <Form.Input label={t("labels.name")} {...manager.getAttributes("name")} />
      <Form.Checkbox
        checked={manager.getValue("reported")}
        onChange={() =>
          manager.setValue("reported", !manager.getValue("reported"))
        }
        name="reported"
        label={t("fund.public_in_reports")}
      />
    </EditEntity>
  );
};

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.fund.error),
    loading: state.fund.pending,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      create: (data) => (dispatch) => {
        dispatch(createFund({ ...data, block: ownProps.block.id })).then(() =>
          dispatch(getBlock(ownProps.block.id, true))
        );
      },
      update: updateFund,
      del: deleteFund,
      clearError,
    },
    dispatch
  );
}

export const FundEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFundComponent);
