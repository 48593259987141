import React, { Component } from "react";
import { Button, Divider, Form, Message, Modal } from "semantic-ui-react";
import { ConfirmModalAction } from "./confirm-modal";
import { StyledTriggerLink } from "./trigger-link";
import { t } from "../../constant/translations";

export type FormProps = {
  entity: Object,
  error: ?Object,
  initialData: ?Object,
  loading: Boolean,
  create: Function,
  update: Function,
  del: ?Function,
  clearError?: Function,
  manager?: Object,
  useTextTrigger?: boolean,
  trigger_props?: Object,
  deleteProps?: Object,
};

export type FormState = {
  modalOpen: Boolean,
};

export class EditEntity extends Component<FormProps, FormState> {
  state = { modalOpen: false };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.loading &&
      !this.props.loading &&
      !this.props.error.exists()
    ) {
      this.setState({ modalOpen: false });
    }
    if (
      prevProps.lastModified == null &&
      this.props.lastModified &&
      !this.props.error.exists()
    ) {
      this.setState({ modalOpen: false });
    }
  }

  handleOpenModal = () => this.setState({ modalOpen: true });

  handleCloseModal = (event) => {
    this.setState({ modalOpen: false });
    if (this.props.clearError) {
      this.props.clearError();
    }
    this.props.manager.clear();
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      // console.log(event);
      // this.handleSubmit(event);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.entity) {
      this.props.update(this.props.manager.formData);
    } else {
      this.props.create(this.props.manager.formData);
    }
  };

  render() {
    const {
      error,
      loading,
      entity,
      header,
      del,
      children,
      useTextTrigger,
      trigger_props,
      deleteProps,
      customTrigger,
    } = this.props;

    let trigger = (
      <Button
        size={"tiny"}
        onClick={this.handleOpenModal}
        basic={true}
        icon={entity ? "edit" : "plus"}
        {...(trigger_props || {})}
      />
    );

    if (customTrigger) {
      trigger = (
        <StyledTriggerLink href={"#"} onClick={this.handleOpenModal}>
          {`${customTrigger.text}`}
        </StyledTriggerLink>
      );
    }

    if (useTextTrigger) {
      trigger = (
        <StyledTriggerLink href={"#"} onClick={this.handleOpenModal}>
          {entity ? t("labels.change") : t("labels.create")}
        </StyledTriggerLink>
      );
    }
    return (
      <Modal
        open={this.state.modalOpen}
        onOpen={this.handleOpenModal}
        onClose={this.handleCloseModal}
        closeIcon={"close"}
        trigger={trigger}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Form as="div">
            {this.state.modalOpen && children}
            <Divider horizontal />
            {error.exists() && (
              <Message error visible={true}>
                {error.getGlobal()}
              </Message>
            )}
            <Form.Group>
              {del && entity && (
                <ConfirmModalAction
                  header={t("labels.deletion")}
                  content={t("labels.confirm_continue")}
                  trigger_props={{
                    negative: true,
                    fluid: true,
                    size: "medium",
                    content:
                      deleteProps && deleteProps.triggerText
                        ? deleteProps.triggerText
                        : t("labels.delete"),
                    basic: false,
                  }}
                  {...deleteProps}
                  loading={loading}
                  error={error}
                  action={() => del(entity)}
                />
              )}
              <Button
                type="submit"
                positive
                fluid
                size="medium"
                loading={loading}
                onClick={this.handleSubmit}
              >
                {t("labels.save")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
