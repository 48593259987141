import styled from "styled-components";
import { logout } from "../../../redux/modules/auth";
import { routes } from "../../../routes";
import { Button, Divider, Dropdown, Image } from "semantic-ui-react";
import { getGravatarSrc } from "../../../components/common/avatar";
import { t } from "../../../constant/translations";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { LicenseAgreement } from "../../auth/license";
import { PasswordChange } from "../../auth/password-change";
import { DropdownSwitchLanguage } from "../../../components/common/switchLanguage";

const PullRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledUserContent = styled.div`
  text-align: center;
`;

const StyledTrigger = styled.span`
  cursor: pointer;
`;

const StyledNameSection = styled.p`
  margin: 10pt 0;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  :hover {
    background: None !important;
    cursor: auto !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  z-index: 111 !important;
`;

export const UserMenuSectionComponent = ({ user, logout }) => {
  return (
    <StyledDropdown
      trigger={
        <StyledTrigger>
          <Image
            src={getGravatarSrc(user.email)}
            avatar
            verticalAlign={"middle"}
          />
        </StyledTrigger>
      }
      direction={"left"}
      floating={true}
      icon={false}
    >
      <Dropdown.Menu>
        <StyledDropdownItem>
          <StyledUserContent>
            <div>
              <PullRight>
                <DropdownSwitchLanguage />
              </PullRight>

              <Image
                src={getGravatarSrc(user.email)}
                size={"tiny"}
                centered={true}
                circular={true}
              />
              <StyledNameSection>
                {`${user.email} (${t(`roles.${user.role}`)})`}{" "}
              </StyledNameSection>
              <PasswordChange />
              <Divider />
              <Button
                onClick={logout}
                content={t("labels.exit")}
                color={"red"}
                basic={true}
              />
              <Divider />
              <LicenseAgreement />
            </div>
          </StyledUserContent>
        </StyledDropdownItem>
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  function logoutAndRedirect() {
    dispatch(logout()).then(() => ownProps.history.replace(routes.baseURL));
  }

  return { logout: logoutAndRedirect };
};

export const UserMenuSection = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserMenuSectionComponent)
);
