import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Checkbox, Form } from "semantic-ui-react";

import type { FormProps } from "../../components/common/edit-entity";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  createProfile,
  deleteProfile,
  updateProfile,
} from "../../redux/modules/profile";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { PhoneNumberInput } from "../../components/common/phone-number-input";
import { t } from "../../constant/translations";

export class ProfileEditComponent extends Component<FormProps> {
  state = { formData: null };

  render() {
    const { error } = this.props;
    const initialState = getInitialState(
      this.props.entity,
      this.props.initialData
    );
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );
    const header = this.props.entity
      ? t("profile.change_profile")
      : t("profile.new_profile");

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={header}
        trigger_props={{
          floated: "right",
          ...this.props.customTrigger_props,
        }}
      >
        <Form.Field>
          <label>{t("labels.name")}</label>
          <Form.Input
            placeholder={t("labels.name")}
            error={error.has("name")}
            {...manager.getAttributes("name")}
          />
          {error.has("name") && <span>{error.get("name")}</span>}
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Form.Input
            placeholder="Email"
            error={error.has("email")}
            {...manager.getAttributes("email")}
          />
          {error.has("email") && <span>{error.get("email")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("management.contacts")}</label>
          <Form.TextArea
            placeholder={t("management.contacts")}
            {...manager.getAttributes("contact_info")}
            rows={4}
          />
        </Form.Field>
        <Form.Field>
          <label>{t("management.mobile_phone")}</label>
          <PhoneNumberInput
            {...manager.getAttributes("mobile_phone_number")}
            error={error.has("mobile_phone_number")}
          />
          {error.has("mobile_phone_number") && (
            <span>{error.get("mobile_phone_number")}</span>
          )}
        </Form.Field>
        <Form.Field>
          <label>IBAN</label>
          <Form.Input
            placeholder="BG18RZBB91550123456789"
            error={error.has("iban")}
            {...manager.getAttributes("iban")}
          />
          {error.has("iban") && <span>{error.get("iban")}</span>}
        </Form.Field>
        <Form.Field>
          <Checkbox
            toggle
            label={t("profile.board_member")}
            checked={Boolean(manager.getValue("board_member"))}
            onClick={() => {
              manager.setValue(
                "board_member",
                !manager.getValue("board_member")
              );
            }}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            toggle
            label={t("profile.company")}
            checked={Boolean(manager.getValue("company"))}
            onClick={() => {
              manager.setValue("company", !manager.getValue("company"));
            }}
          />
        </Form.Field>
        <Form.Field disabled={!manager.getValue("company")}>
          <label>{t("profile.unique_ID")}</label>
          <Form.Input
            placeholder="923456783"
            error={error.has("company_code")}
            {...manager.getAttributes("company_code")}
          />
          {error.has("company_code") && (
            <span>{error.get("company_code")}</span>
          )}
        </Form.Field>
        <Form.Field disabled={!manager.getValue("company")}>
          <label>{t("profile.manager")}</label>
          <Form.Input
            placeholder={t("profile.manager_placeholder")}
            error={error.has("company_manager")}
            {...manager.getAttributes("company_manager")}
          />
          {error.has("company_manager") && (
            <span>{error.get("company_manager")}</span>
          )}
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.profile.error),
    loading: state.profile.pending,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const create = (data) => (dispatch) => {
    dispatch(createProfile({ block: ownProps.block, ...data }));
  };
  return bindActionCreators(
    { create, update: updateProfile, del: deleteProfile, clearError },
    dispatch
  );
}

export const ProfileEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditComponent);
