import React from "react";
import { Route, Switch, withRouter } from "react-router";
import { routes } from "../../../routes";
import { TeamList } from "./list";
import { RoleList } from "./roles/list";
import { TeamMemberDetails } from "./details";

export const TeamPageComponent = () => {
  return (
    <Switch>
      <Route exact={true} path={routes.team} component={TeamList} />
      <Route path={routes.teamRoles} component={RoleList} />
      <Route path={routes.teamDetails} component={TeamMemberDetails} />
    </Switch>
  );
};

export const TeamPage = TeamPageComponent;
