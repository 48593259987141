import React from "react";
import { Menu } from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import { Redirect, Route, Switch, withRouter } from "react-router";

import BlockReportsPage from "./block-reports-page";
import BlockSettlementsPage from "./block-settlements-page";

import { RoleRequired } from "../auth/role-required";
import { BlockBudgetPage } from "./block-budget-page";
import { BlockTransactionsPage } from "./block-transactions-page";
import { reverse, routes } from "../../routes";
import { BlockChargesPage } from "./block-charges-page";
import { StyledTopMenu } from "../../components/base/elements";
import { permissions } from "../../constant/permissions";
import { BlockChargesList } from "./charge/list";
import { t } from "../../constant/translations";

export const BlockBillingComponent = (props: Props) => {
  const blockId = props.match.params.blockId;
  return (
    <React.Fragment>
      <StyledTopMenu stackable={true}>
        <Menu.Item
          as={() => (
            <Link
              activeClassName="active"
              className="item"
              to={reverse(routes.blockBudget, { blockId })}
            >
              {t("billing.budget")}
            </Link>
          )}
        />
        <RoleRequired
          admin={true}
          key={"g3"}
          permission={permissions.billingReportView}
        >
          <Menu.Item
            as={() => (
              <Link
                activeClassName="active"
                className="item"
                to={reverse(routes.blockReports, { blockId })}
              >
                {t("billing.reports")}
              </Link>
            )}
          />
        </RoleRequired>
        <Menu.Item
          as={() => (
            <Link
              activeClassName="active"
              className="item"
              to={reverse(routes.blockSettlements, { blockId })}
            >
              {t("billing.payments")}
            </Link>
          )}
        />
        <RoleRequired
          admin={true}
          permission={permissions.billingTransactionView}
        >
          <Menu.Item
            as={() => (
              <Link
                activeClassName="active"
                className="item"
                to={reverse(routes.blockTransactions, { blockId })}
              >
                {t("billing.transactions")}
              </Link>
            )}
          />
        </RoleRequired>
        <Menu.Item
          as={() => (
            <Link
              activeClassName="active"
              className="item"
              to={reverse(routes.blockCharges, { blockId })}
            >
              {t("billing.obligations")}
            </Link>
          )}
        />
      </StyledTopMenu>
      <Switch>
        <Redirect
          exact={true}
          path={routes.blockBilling}
          to={routes.blockBudget}
        />
        <Route path={routes.blockBudget} component={BlockBudgetPage} />
        <Route path={routes.blockReports} component={BlockReportsPage} />
        <Route
          path={routes.blockSettlements}
          render={(props) => (
            <BlockSettlementsPage blockId={blockId} {...props} />
          )}
        />
        <Route
          path={routes.blockTransactions}
          component={BlockTransactionsPage}
        />
        <Route path={routes.blockChargeList} component={BlockChargesList} />
        <Route path={routes.blockCharges} component={BlockChargesPage} />
      </Switch>
    </React.Fragment>
  );
};

export const BlockBilling = withRouter(BlockBillingComponent);
