import React from "react";
import { Button } from "semantic-ui-react";
import superagent from "superagent";
import Cookies from "js-cookie";
import { reverse, routes } from "../../routes";
import { ConfirmModalAction } from "../../components/common/confirm-modal";
import { t } from "../../constant/translations";

export const LoginAsUser = ({ profile }) => {
  const userLogin = (profileId) => {
    superagent
      .post(`/api/su/${profileId}/`)
      .set("X-CSRFToken", Cookies.get("csrftoken"))
      .then(() => (window.location.href = reverse(routes.baseURL)));
  };
  return (
    <ConfirmModalAction
      header={t("login.loginas") + ` ${profile.name}`}
      content={
        t("login.admin_loginas") + ` ${profile.name} (${profile.email}) ?`
      }
      trigger_props={{ color: "blue", icon: "key", size: "tiny" }}
      error={null}
      loading={false}
      action={() => userLogin(profile.id)}
      confirmLabel={t("labels.proceed")}
    />
  );
};
