import React, { Fragment } from "react";
import qs from "qs";
import dayjs from "dayjs";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import { amountToBGN, amountToBGNOrEmpty } from "../../../helpers/currency";
import { usePagination } from "../../../components/common/pagination";
import { Link } from "react-router-dom";
import { reverse, routes } from "../../../routes";
import { DownloadLink } from "../../../helpers/download-link";
import { t } from "../../../constant/translations";

const getQueryParams = (props) => {
  const queryParams = qs.stringify({ block: props.blockId, format: "csv" });
  return queryParams;
};

export const PerEstateTable = (props) => {
  const { blockId, data } = props;
  const { funds, per_estate } = data;
  const estateData = per_estate.map((estate) => {
    return {
      id: estate.id || null,
      name: estate.name || "",
      current: estate.charges.current || {},
      overdue: estate.charges.overdue || {},
      total: estate.charges.total || 0,
    };
  });

  const [page, paginationComponent] = usePagination(estateData, 15);

  return (
    <Fragment>
      <h5>{t("charge.to_property")}</h5>
      {paginationComponent}
      <DownloadLink
        size="tiny"
        style={{ float: "right" }}
        href={`/api/reports/current-charges-csv?${getQueryParams(props)}`}
        basic={true}
        target={"_blank"}
        download={`${props.blockName}-${t(
          "charge.obligations"
        )}-${dayjs().format("YYYY-MM-DD-HHmm")}.csv`}
        icon={"download"}
      />
      <DataTable data={page} unstackable={true} selectable={true} celled={true}>
        <DataTableColumn
          align="left"
          name={t("resources.estate_capital")}
          render={(el) => {
            return (
              <Link
                to={reverse(routes.estateDetails, {
                  blockId: blockId,
                  estateId: el.id,
                })}
              >
                {el.name}
              </Link>
            );
          }}
        />
        {funds.map((x, i) => {
          return [
            <DataTableColumn
              key={i}
              name={`${x} (` + t("charge.current") + `)`}
              align="center"
              render={(el) => amountToBGNOrEmpty(el.current[x])}
            />,
            <DataTableColumn
              key={i}
              name={`${x} (` + t("charge.overdue") + `)`}
              align="center"
              render={(el) => amountToBGNOrEmpty(el.overdue[x])}
            />,
          ];
        })}
        <DataTableColumn
          align="right"
          name={t("charge.total")}
          render={(el) => amountToBGN(el.total)}
        />
      </DataTable>
    </Fragment>
  );
};
