import React, { Component } from "react";
import { MarkdownEdit } from "../../components/common/markdownedit";
import { connect } from "react-redux";
import { updateTransaction } from "../../redux/modules/fundTransaction";
import { bindActionCreators } from "redux";
import { EditEntity } from "../../components/common/edit-entity";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { t } from "../../constant/translations";

class TransactionEditComponent extends Component {
  state = {
    formData: null,
  };

  render() {
    const { entity } = this.props;

    const manager = new FormdataManager(
      this.state.formData || getInitialState(entity),
      (formData) => this.setState({ formData })
    );

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={t("billing.change_Transaction")}
        entity={entity}
      >
        <MarkdownEdit rows={4} {...manager.getAttributes("description")} />
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.fundTransaction.error),
    loading: state.fundTransaction.pending,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      update: updateTransaction,
    },
    dispatch
  );
}

export const TransactionEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionEditComponent);
