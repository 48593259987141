export const AppTheme = {
  primary: "#4183c4",
  rowSelected: "#2185d0",
  rowHover: "rgba(0, 0, 0, 0.05)",
  btnPrimary: "#2185d0",
  border: "#e9e9e9",
  borderGrey: "#dfdfdf",
  textGray: "#666666",
  errorBg: "rgba(255,0,0,0.1)",
};
