import { newApi } from "../restdux/index";

type ChargeDetails = {
  model: string,
  value: string,
  amount: string,
  model_price: string,
  model_min_price: string,
};

type Charge = {
  id: number,
  estate: number,
  fund: string,
  charge_date: string,
  amount: string,
  amount_left: string,
  description: ?string,
  verbose_description: ?string,
  status: string,
  verbose_status: string,
  details: ChargeDetails[],
  settlement_date: ?string,
};

export type Transaction = {
  id: number,
  source: ?number,
  source_name: ?string,
  target: ?number,
  target_name: ?string,
  charge?: Charge,
  amount: string,
  description: string,
  transaction_date: string,
  verbose_description: string,
};

export const transactionManager = newApi(
  "fundTransaction",
  "/api/fund-transaction/",
  true
);
export const reducer = transactionManager.reducer;

export function fetchTransactions(filterParams) {
  return transactionManager.actions.list(filterParams);
}

export function createTransaction(data) {
  return transactionManager.actions.create(data, {
    pushToFront: true,
  });
}

export function updateTransaction(data) {
  return transactionManager.actions.update(data);
}

export function updateTransactionCategory(transactionId, categoryName) {
  return transactionManager.actions.update({
    id: transactionId,
    category: categoryName,
  });
}

export function cancelTransaction(data) {
  return transactionManager.actions.del(data);
}

export const clearError = transactionManager.actions.clearError;
