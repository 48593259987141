import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { entityGet } from "../../helpers/filter";
import { Header, Loader, Segment, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { listBillingForecast } from "../../redux/modules/billing-forecast";
import { fetchEstates } from "../../redux/modules/estates";
import { bindActionCreators } from "redux";
import { reverse, routes } from "../../routes";
import { amountToBGN } from "../../helpers/currency";
import { PayByCardAction } from "./pay-by-card";
import { device } from "../../config";
import { Inline } from "../../components/base/elements";
import { t } from "../../constant/translations";

const StyledHeader = styled(Header)({
  "margin-bottom": "0 !important",
});

const StyledHeaderWithMargins = styled(Header)({
  margin: "15px 0 5px 15px !important",
});

const StyledDiv = styled.div({
  margin: "15px 0 5px 15px !important",
});

const TextSmall = styled.div`
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${(props) => props.theme.textGray};
  text-transform: lowercase;
`;

const StyledAmount = styled.div`
  width: 70px;
  text-align: right;
  margin-left: 0.2rem;
  @media ${device.handheld} {
    margin-left: 1rem;
    font-size: 1.1rem;
  }
`;

class EstateOwnerSummary extends Component {
  componentDidMount() {
    this.props.fetchEstates({ block: this.props.block.id, owned: true });
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (this.props.estates !== prevProps.estates && this.props.estates) {
      // this.props.listBillingForecast();
    }
  }

  getForecast = (estate) => {
    return null;
    // TODO: Figure out if forecast should be shown to users on this screen
    return entityGet(
      this.props.billForecast || [],
      (entry) => entry.estate.id === estate.id
    );
  };

  renderEstateTableRow = (estate) => {
    const title = (
      <Link
        to={reverse(routes.estateDetails, {
          blockId: this.props.block.id,
          estateId: estate.id,
        })}
      >
        {estate.name}
      </Link>
    );
    const block = this.props.block;
    const forecast = this.getForecast(estate);
    return (
      <Table.Row key={`row-${estate.id}`}>
        <Table.Cell>
          <StyledHeader forwardedAs="h5" textAlign="left">
            {title}
          </StyledHeader>
          <i>
            {t("estate.branch_numb")}: {estate.vendor_id}
          </i>
        </Table.Cell>
        <Table.Cell>
          <Inline>
            {forecast && (
              <StyledAmount>
                <TextSmall>{t("labels.payment")}</TextSmall>
                {amountToBGN(forecast.total)}
              </StyledAmount>
            )}
            <StyledAmount>
              <TextSmall>{t("billing.obligations")}</TextSmall>
              {amountToBGN(estate.debt)}
            </StyledAmount>
            <StyledAmount>
              <TextSmall>{t("charge.balance")}</TextSmall>
              {amountToBGN(estate.balance)}
            </StyledAmount>
          </Inline>
        </Table.Cell>
        {block.config.billing.card_settlement_enabled && (
          <Table.Cell textAlign={"right"}>
            <PayByCardAction
              estate={estate}
              disabled={parseFloat(estate.debt) === 0}
            />
          </Table.Cell>
        )}
      </Table.Row>
    );
  };

  render() {
    const { estates, loading, block } = this.props;
    if (loading) {
      return (
        <StyledDiv>
          <Loader active inline={"centered"} />
        </StyledDiv>
      );
    }

    // No point in showing the table if there are no estates owned in this block
    if (!estates || estates.length === 0) {
      return null;
    }

    return (
      <div>
        <StyledHeaderWithMargins forwardedAs="h3">
          {t("estate.liab_own_estate")}
        </StyledHeaderWithMargins>
        <Table unstackable={true} size={"small"} compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("resources.estate_capital")}
              </Table.HeaderCell>
              <Table.HeaderCell />
              {block.config.billing.card_settlement_enabled && (
                <Table.HeaderCell />
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {estates && estates.map(this.renderEstateTableRow)}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    estates: state.estates.entities,
    billForecast: state.billingForecast.entities,
    loading: state.estates.pending || state.billingForecast.pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ listBillingForecast, fetchEstates }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EstateOwnerSummary);
