// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import type { Location, RouterHistory } from "react-router-dom";
import GoogleAnalytics from "react-ga4";

type Props = {
  debug: boolean,
  children?: React.Node,
  location: Location,
  history: RouterHistory,
};

class AnalyticsComponent extends React.Component<Props> {
  static defaultProps = {
    debug: false,
  };

  componentDidMount() {
    this.sendPageView(this.props.location);
    this.props.history.listen(this.sendPageView);
  }

  sendPageView = (location: Location) => {
    // Do nothing if GA was not initialized due to a missing tracking ID.
    GoogleAnalytics.send({ hitType: "pageview", page: location.pathname });

    if (this.props.debug) {
      console.info(`[react-router-ga] Page view: ${location.pathname}`);
    }
  };

  render() {
    return this.props.children;
  }
}

export const Analytics = withRouter(AnalyticsComponent);
