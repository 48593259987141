import { newApi } from "../restdux/index";

export const billingModelManager = newApi(
  "billing-model",
  "/api/billing-model/"
);
export const reducer = billingModelManager.reducer;

export function fetchBillingModels(blockId) {
  return billingModelManager.actions.list({ block: blockId });
}

export function createBillingModel(blockId, data) {
  return billingModelManager.actions.create({ block: blockId, ...data });
}

export function updateBillingModel(data) {
  return billingModelManager.actions.update(data);
}

export function deleteBillingModel(billingModel) {
  return billingModelManager.actions.del(billingModel);
}
