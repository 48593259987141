import { resolve } from "url";

export type Actions = {
  list: (params: Object, rest: Object) => any,
  get: (data: Object, rest: Object) => any,
  create: (data: Object, rest: Object) => any,
  update: (data: Object, rest: Object) => any,
  del: (data: Object, rest: Object) => any,
  clearError: (rest: Object) => any,
};

export function request(pendingAction, successAction, errorAction) {
  return (getPromise, dispatch, rest = {}) => {
    dispatch({ type: pendingAction, ...rest });
    return getPromise().then(
      (result) => dispatch({ type: successAction, result, ...rest }),
      (error) => dispatch({ type: errorAction, error, ...rest })
    );
  };
}

export function getActions(url, actionTypes): Actions {
  const actions = {};
  actions.list = (params, rest) => (dispatch, getState, client) => {
    const getPromise = () => client.get(url, { params });
    return request(
      actionTypes.LIST_PENDING,
      actionTypes.LIST_SUCCESS,
      actionTypes.LIST_ERROR
    )(getPromise, dispatch, rest);
  };

  actions.get = (data, rest) => (dispatch, getState, client) => {
    const getPromise = () =>
      client.get(resolve(url, data.id.toString()) + "/", { data });
    return request(
      actionTypes.FETCH_PENDING,
      actionTypes.FETCH_SUCCESS,
      actionTypes.FETCH_ERROR
    )(getPromise, dispatch, rest);
  };

  actions.create = (data, rest) => (dispatch, getState, client) => {
    const getPromise = () => client.post(url, { data });
    return request(
      actionTypes.CREATE_PENDING,
      actionTypes.CREATE_SUCCESS,
      actionTypes.CREATE_ERROR
    )(getPromise, dispatch, rest);
  };

  actions.update = (data, rest) => (dispatch, getState, client) => {
    const getPromise = () =>
      client.put(resolve(url, data.id.toString()) + "/", { data });
    return request(
      actionTypes.UPDATE_PENDING,
      actionTypes.UPDATE_SUCCESS,
      actionTypes.UPDATE_ERROR
    )(getPromise, dispatch, rest);
  };

  actions.del = (data, rest) => (dispatch, getState, client) => {
    const getPromise = () =>
      client.del(resolve(url, data.id.toString()) + "/", { data });
    return request(
      actionTypes.DELETE_PENDING,
      actionTypes.DELETE_SUCCESS,
      actionTypes.DELETE_ERROR
    )(getPromise, dispatch, { entity: data, ...rest });
  };

  actions.clearError = (rest) => (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_ERROR, ...rest });
  };

  return actions;
}
