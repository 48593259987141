import { connect } from "react-redux";
import { ErrorWrap } from "../../helpers/error";
import { EditEntity } from "../../components/common/edit-entity";
import { FormdataManager } from "../../components/common/formdata";
import { Form, Input } from "semantic-ui-react";
import React, { useState } from "react";
import { apirequest } from "../../helpers/api-client";
import { withRouter } from "react-router";
import { reverse, routes } from "../../routes";
import { bindActionCreators } from "redux";
import { getBlock } from "../../redux/modules/blocks";
import { t } from "../../constant/translations";

const NewBlockComponent = ({ onSuccess }) => {
  const manager = FormdataManager.useState();

  const [status, setStatus] = useState({
    loading: false,
    error: ErrorWrap.fromError(null),
  });
  const create = () => {
    setStatus({ loading: true, error: ErrorWrap.fromError(null) });
    apirequest(
      "post",
      "/api/create-block-from-template/",
      manager.formData
    ).then(
      (success) => {
        setStatus({ loading: false, error: ErrorWrap.fromError(null) });
        onSuccess(success.body.id);
      },
      (result) => {
        setStatus({
          loading: false,
          error: ErrorWrap.fromError(result.response.body),
        });
      }
    );
  };
  return (
    <EditEntity
      error={status.error}
      loading={status.loading}
      create={create}
      manager={manager}
      header={t("block.new_object")}
      trigger_props={{ content: t("block.new_object"), fluid: true }}
    >
      <Form.Field>
        <label>{t("labels.name")}</label>
        <Form.Input
          placeholder={t("labels.name")}
          error={status.error.get("name")}
          {...manager.getAttributes("name")}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("block.address")}</label>
        <Form.Input
          placeholder={t("block.address")}
          error={status.error.get("address")}
          {...manager.getAttributes("address")}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("block.number_of_properties")}</label>
        <Form.Input
          type={"number"}
          error={status.error.get("estate_num")}
          {...manager.getAttributes("estate_num")}
        />
      </Form.Field>
    </EditEntity>
  );
};

function stateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.blocks.error),
    loading: state.blocks.pending,
  };
}

function dispatchToProps(dispatch, ownProps) {
  const onSuccess = (blockId) => {
    return (dispatch) => {
      dispatch(getBlock(blockId)).then(() => {
        ownProps.history.push(reverse(routes.blockDetails, { blockId }));
      });
    };
  };

  return bindActionCreators({ onSuccess }, dispatch);
}

export const NewBlock = withRouter(
  connect(stateToProps, dispatchToProps)(NewBlockComponent)
);
