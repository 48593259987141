import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { amountToBGN } from "../../helpers/currency";
import { Link } from "react-router-dom";
import { Header, Label, Menu, Table } from "semantic-ui-react";
import { RoleRequired } from "../auth/role-required";
import { reverse, routes } from "../../routes";
import { BlockConfigDash } from "../../components/block/block-config-dash";
import { getSearchQ, SearchInput } from "../../components/common/search";
import { connect } from "react-redux";
import { filter, paginate } from "../../helpers/filter";
import {
  getCurrentPage,
  PaginationWithQuery,
} from "../../components/common/pagination";
import { Grid } from "../../components/base/elements";
import { device } from "../../config";
import { permissions } from "../../constant/permissions";
import { BlocksDownloadModal } from "./blocks-download-modal";
import { t } from "../../constant/translations";

const LINK_STYLE = { textDecoration: "hand" };

const FlexMenu = styled(Menu)`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;

  @media ${device.handheld} {
    flex-wrap: nowrap;
  }
`;

const FlexMenuItem = styled(Menu.Item)`
  flex-grow: 1 !important;
  @media ${device.handheld} {
    flex-grow: 0 !important;
  }
`;

const FlexMenuGutter = styled.span`
  display: none;
  @media ${device.handheld} {
    flex-grow: 1 !important;
  }
`;

const BlockDescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  > h5 {
    flex-grow: 1;
    width: auto !important;
  }

  @media ${device.handheld} {
    flex-direction: row;
    flex-wrap: wrap;
    > h5 {
      width: 400px !important;
    }
  }
`;

const BlockList = ({ entries }) => {
  const q = getSearchQ();
  const pageq = getCurrentPage();

  const filteredEntries = filter(entries, q, ["name"]) || [];
  const paginatedEntries = paginate(filteredEntries, pageq);
  return (
    <Fragment>
      <PaginationWithQuery entries={filteredEntries.length} />
      <Table unstackable={true} striped={true} selectable={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("labels.block")}</Table.HeaderCell>
            <RoleRequired
              admin={true}
              permission={permissions.billingAccountViewBalance}
            >
              <Table.HeaderCell textAlign={"right"}>
                {t("fund.balance")}
              </Table.HeaderCell>
            </RoleRequired>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {paginatedEntries.map((block) => {
            return (
              <Table.Row key={`landing-block-row-${block.id}`}>
                <Table.Cell>
                  <BlockDescriptionCard>
                    <Header as="h5" textAlign="left">
                      <Link
                        to={reverse(routes.blockDetails, { blockId: block.id })}
                      >
                        {block.name}
                      </Link>
                    </Header>
                    <RoleRequired
                      admin={true}
                      permission={permissions.blockEdit}
                    >
                      <BlockConfigDash block={block} />
                    </RoleRequired>
                  </BlockDescriptionCard>
                </Table.Cell>
                <RoleRequired
                  admin={true}
                  permission={permissions.billingAccountViewAtDisposal}
                >
                  <Table.Cell textAlign={"right"} collapsing>
                    <Header as={"h5"}>{amountToBGN(block.at_disposal)}</Header>
                  </Table.Cell>
                </RoleRequired>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Fragment>
  );
};

export class BlockLanding extends Component {
  state = {};
  renderEmpty = () => {
    return (
      <Grid.Row>
        <Grid.Column computer={5} tablet={16}>
          <h1>{t("block.none_available")}</h1>
          <Link to={routes.blockNew} style={LINK_STYLE}>
            {" "}
            {t("block.create_new")}{" "}
          </Link>
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    const { blocks } = this.props;
    const active = blocks.filter((element) => element.active === true);
    const deactivated = blocks.filter((element) => element.active === false);
    let displayable =
      this.state.activeItem === "archive" ? deactivated : active;

    return (
      <Grid centered={true}>
        <Grid.Column>
          <FlexMenu>
            <FlexMenuItem
              active={this.state.activeItem !== "archive"}
              onClick={() => this.setState({ activeItem: undefined })}
            >
              {t("block.active")}
              <Label>{active.length}</Label>
            </FlexMenuItem>
            {deactivated.length > 0 && (
              <FlexMenuItem
                name={t("block.archive")}
                active={this.state.activeItem === "archive"}
                onClick={() => this.setState({ activeItem: "archive" })}
              >
                {t("block.archive")}
                <Label>{deactivated.length}</Label>
              </FlexMenuItem>
            )}
            <FlexMenuGutter />
            <FlexMenuItem>
              <SearchInput replace={true} />
            </FlexMenuItem>
            <RoleRequired permission={permissions.billingReportView}>
              <FlexMenuItem position={"right"}>
                <BlocksDownloadModal />
              </FlexMenuItem>
            </RoleRequired>
          </FlexMenu>

          <BlockList entries={displayable} />
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return { blocks: state.blocks.entities };
}

export default connect(mapStateToProps)(BlockLanding);
