export const URLS = {
  AUTH: {
    LOGIN: "/api-auth/login/",
    LOGOUT: "/api-auth/logout/",
  },
};

export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";
export const ROLE_USER = "user";

export const PaymentMethod = {
  cash: "cash",
  card: "card",
  bank: "bank",
};

export const device = {
  handheld: "screen and (min-width: 624px)",
  desktop: "screen and (min-width: 1021px)",
  largeDesktop: "screen and (min-width: 1441px)",
};
