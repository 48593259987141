import { newApi } from "../restdux/index";
import superagent from "superagent";
import Cookies from "js-cookie";

export const fileManager = newApi("file", "/api/file/");
export const reducer = (state, action = {}) => {
  let newState = { ...state };
  if (action.type === fileManager.actionTypes.CREATE_SUCCESS) {
    return {
      ...state,
      pending: false,
      entities: state.entities.concat(action.result),
      lastUpload: action.result[action.result.length - 1],
    };
  }
  return fileManager.reducer(newState, action);
};

export function getDirectories(files) {
  files = files || [];
  const directories = new Set();
  files.map((entry) => directories.add(entry.directory_name));
  return Array.from(directories);
}

export function fetchFiles(blockId) {
  return fileManager.actions.list({ block: blockId });
}

export function updateFile(data) {
  return fileManager.actions.update(data);
}

export function deleteFile(data) {
  return fileManager.actions.del(data);
}

export function uploadFiles(data, files) {
  return (dispatch) => {
    dispatch({
      type: fileManager.actionTypes.CREATE_PENDING,
    });
    Promise.all(
      files.map((file) => {
        return superagent
          .post("/api/file/")
          .set("X-CSRFToken", Cookies.get("csrftoken"))
          .field(data)
          .attach("content", file);
      })
    ).then(
      (resp) => {
        dispatch({
          type: fileManager.actionTypes.CREATE_SUCCESS,
          result: resp.map((entry) => entry.body),
        });
      },
      (error) => {
        dispatch({
          type: fileManager.actionTypes.CREATE_ERROR,
          error: error,
        });
      }
    );
  };
}
