import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";

import { t } from "../../constant/translations";
import { NumberParam, StringParam } from "use-query-params";
import { connect } from "react-redux";
import { CalendarInput } from "../../components/common/calendar-input";

export const transactionQueryFilterParams = {
  q: StringParam,
  from_date: StringParam,
  to_date: StringParam,
  category: StringParam,
  transaction_type: StringParam,
};

export const transactionQueryKeyMap = {
  q: t("charge.search"),
  transaction_type: (value) =>
    `${t("labels.type_lowerc")}: ${t(
      `transactionTypes.${value}`
    ).toLowerCase()}`,
  category: t("labels.category"),
  from_date: t("labels.from_date"),
  to_date: t("labels.to_date"),
};

export const ContentFilterFormBodyComponent = ({
  data,
  onChange,
  transactionCategories,
}) => {
  return (
    <Fragment>
      <Form.Input
        icon="search"
        placeholder={t("charge.search_capital")}
        onChange={onChange("q")}
        value={data.q || ""}
        name={"search-transaction"}
      />

      <h5>{t("billing.from_type")}</h5>
      <Form.Select
        value={data.transaction_type || null}
        options={[null, "expense", "income"].map((e) => {
          return { text: t(`transactionTypes.${e}`), value: e };
        })}
        onChange={onChange("transaction_type")}
      />

      <h5>{t("labels.category")}</h5>
      <Form.Select
        value={data.category || ""}
        options={[
          { text: t("labels.all"), value: "" },
          ...transactionCategories.map((category) => ({
            text: category,
            value: category,
          })),
        ]}
        onChange={onChange("category")}
      />
      <h5>{t("labels.from_date")}</h5>
      <CalendarInput
        onChange={(event) => onChange("from_date")({}, event.target)}
        value={data.from_date || ""}
      />
      <h5>{t("labels.to_date")}</h5>
      <CalendarInput
        onChange={(event) => onChange("to_date")({}, event.target)}
        value={data.to_date || ""}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  transactionCategories: state.appSettings?.data?.transaction_categories || [],
});

export const ContentFilterFormBody = connect(mapStateToProps)(
  ContentFilterFormBodyComponent
);
