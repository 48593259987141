/* @flow */
import React from "react";
import { ErrorWrap } from "../helpers/error";
import { Header, Icon } from "semantic-ui-react";
import { t } from "../constant/translations";

type Props = { error?: Object };

class Error extends React.Component<Props> {
  render() {
    const error = ErrorWrap.fromError(this.props.error);
    return (
      <Header textAlign={"center"} icon>
        <Icon name={"x"} />
        {error.getCode()}
        <Header.Subheader>{t("error.page_not_found")}</Header.Subheader>
      </Header>
    );
  }
}

export default Error;
