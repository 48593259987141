import styled from "styled-components";
import React from "react";
import { Button, Dropdown, Flag } from "semantic-ui-react";

const StyledButton = styled(Button)`
  padding: 0.5em !important;
  vertical-align: bottom !important;
`;

const countryOptions = [
  {
    key: "bg",
    value: "bg-BG",
    text: (
      <span>
        <Flag name="bg" />
        БГ
      </span>
    ),
  },
  {
    key: "en",
    value: "en-US",
    text: (
      <span>
        <Flag name="us" />
        EN
      </span>
    ),
  },
];

export const DropdownSwitchLanguage = ({ fluid, className }) => {
  const handleChange = (e, { value }) => {
    localStorage.setItem("current--language", value);
    window.location.reload();
  };

  return (
    <StyledButton basic={true}>
      <Dropdown
        className={className}
        direction={"left"}
        fluid={fluid}
        icon={false}
        onChange={handleChange}
        options={countryOptions}
        defaultValue={localStorage.getItem("current--language") || "bg-BG"}
      />
    </StyledButton>
  );
};
