import React, { Fragment, useEffect, useState } from "react";
import {
  permissionDescriptions,
  permissions,
} from "../../../../constant/permissions";
import { FormdataManager } from "../../../../components/common/formdata";
import styled from "styled-components";
import { Button, Checkbox, Dropdown, Form, Message } from "semantic-ui-react";
import { ErrorWrap } from "../../../../helpers/error";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createUserRole,
  deleteUserRole,
  updateUserRole,
  clearError,
} from "../../../../redux/modules/user-role";
import { ConfirmModalAction } from "../../../../components/common/confirm-modal";
import { t } from "../../../../constant/translations";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
  align-items: flex-start;
  padding: 2em 0;
`;

const PermissionCol = styled.div`
  padding: 0 2em 0 0;
  display: flex;
  flex-direction: column;
`;

const PermissionCheckbox = styled(Checkbox)`
  font-size: 1em !important;
  padding: 0.4em 0;
  max-width: 240px;
`;

const RoleForm = styled(Form)`
  margin-top: 2em;
  max-width: 550px !important;
`;

const SectionHeader = styled.h3``;

const HeaderContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const Options = ({ role, action, loading }) => {
  return (
    <Dropdown icon={"ellipsis vertical"}>
      <Dropdown.Menu direction={"left"}>
        <Dropdown.Item>
          <ConfirmModalAction
            action={action}
            textTriggerText={t("userActions.delete")}
            useTextTrigger={true}
            header={`${t("management.delete_role")} ${role.name}`}
            content={`${t("management.confirm_role_deletion")} ${role.name}`}
            loading={loading}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const RoleEditSectionComponent = ({
  role,
  loading,
  error,
  createUserRole,
  updateUserRole,
  deleteUserRole,
  clearError,
  modified,
}) => {
  const [successMessageVisible, showSuccessMessage] = useState(false);
  const manager = FormdataManager.useState({}, () => {
    showSuccessMessage(false);
    clearError();
  });

  useEffect(() => {
    if (successMessageVisible && modified && modified !== role) {
      showSuccessMessage(false);
    }
  }, [modified, role]);

  useEffect(() => {
    let rolePermissions = {};
    const hasPermissions =
      role.policy &&
      role.policy.permissions &&
      role.policy.permissions.length > 0;
    if (hasPermissions) {
      role.policy.permissions.forEach((element) => {
        return (rolePermissions[element] = true);
      });
    }
    const initialState = {
      name: role.name,
      permissions: rolePermissions,
    };
    manager.setFormData(initialState);
  }, [role]);

  const availablePermissions = Object.values(permissions);
  const submitForm = () => {
    const action = role.id ? updateUserRole : createUserRole;
    role.name = manager.formData.name;
    role.policy = {
      permissions: Object.keys(manager.formData.permissions).filter((key) => {
        const value = manager.formData.permissions[key];
        return value && availablePermissions.includes(key);
      }),
    };
    action(role);
    showSuccessMessage(true);
  };

  const section1 = availablePermissions.slice(
    0,
    availablePermissions.length / 2
  );
  const section2 = availablePermissions.slice(availablePermissions.length / 2);

  const getCheckbox = (permission) => {
    const description = permissionDescriptions[permission];
    const permissionPath = `permissions.${permission}`;
    let permChecked = manager.getValue(permissionPath) ? true : false;
    return (
      <PermissionCheckbox
        key={permission}
        label={description.title}
        checked={permChecked}
        onClick={() => {
          manager.setValue(permissionPath, !manager.getValue(permissionPath));
        }}
      />
    );
  };

  return (
    <Fragment>
      <HeaderContent>
        {role.id ? (
          <SectionHeader>
            {t("fund.change_of")} {role.name}
          </SectionHeader>
        ) : (
          <SectionHeader>{t("management.new_role")}</SectionHeader>
        )}
        {role.id && (
          <Options
            role={role}
            action={() => deleteUserRole(role)}
            loading={loading}
          />
        )}
      </HeaderContent>
      <RoleForm>
        {successMessageVisible && modified && (
          <Message
            content={t("management.changes_saved_successfully")}
            positive
          />
        )}
        <Form.Field fluid="true">
          <label>{t("management.role_name")}</label>
          <Form.Input
            {...manager.getAttributes("name")}
            error={error.get("name")}
          />
        </Form.Field>

        <Section>
          <PermissionCol>{section1.map(getCheckbox)}</PermissionCol>
          <PermissionCol>{section2.map(getCheckbox)}</PermissionCol>
        </Section>
        <Button
          positive
          fluid
          onClick={() => submitForm()}
          loading={loading}
          disabled={!manager.getValue("name")}
        >
          {t("labels.save")}
        </Button>
      </RoleForm>
    </Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    loading: state.userRole.pending,
    error: ErrorWrap.fromError(state.userRole.error),
    modified: state.userRole.lastModified,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { createUserRole, updateUserRole, deleteUserRole, clearError },
    dispatch
  );
}

export const RoleEditSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleEditSectionComponent);
