export function getRestActionTypes(resourceName) {
  const actionTypes = { CLEAR_ERROR: `res/${resourceName}/CLEAR_ERROR` };
  const actionTypeNames = ["LIST", "FETCH", "CREATE", "UPDATE", "DELETE"];

  for (const actionType of actionTypeNames) {
    for (const actionState of ["PENDING", "SUCCESS", "ERROR"]) {
      const action = `${actionType}_${actionState}`;
      actionTypes[[action]] = `rest/${resourceName}/${action}`;
    }
  }
  return actionTypes;
}

export function formatActionTypeBuilder(resourceName) {
  return (actionName) => {
    return `rest/${resourceName}/${actionName}`;
  };
}
