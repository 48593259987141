import styled from "styled-components";

import React from "react";
import { Accordion, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { FileList } from "./filelist";
import { AccordionHead } from "./accordion-head";
import { UploadForm } from "./upload-form";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { t } from "../../constant/translations";

type Props = {};

const StyledItalicDiv = styled.div`
  font-style: italic;
`;

class FileBoxContainer extends React.Component<Props> {
  state = { active: {} };

  componentDidUpdate(prevProps): void {
    if (this.props.lastUpload !== prevProps.lastUpload) {
      this.openDirectory(this.props.lastUpload.directory_name);
    }
  }

  mapSections(files, dirs) {
    const dirRemap = {};
    dirs.map((entry) => {
      dirRemap[entry.id] = entry.name;
    });

    const sections = {};
    files.map((entry) => {
      const dir =
        dirRemap[entry.directory_id] ||
        entry.directory_name ||
        t("file.common");
      if (!sections[dir]) {
        sections[dir] = {
          id: entry.directory_id,
          name: dir,
          entries: [],
        };
      }
      sections[dir].entries.push(entry);
    });

    return sections;
  }

  openDirectory = (selected) => {
    const active = this.state.active;
    active[selected] = !active[selected];
    this.setState({ active });
  };

  renderPanels(sections) {
    const results = [];
    Object.keys(sections).forEach((key) => {
      results.push(
        <AccordionHead
          key={key}
          section={sections[key]}
          active={this.state.active[key] === true}
          activate={this.openDirectory}
        />
      );
      results.push(
        <Accordion.Content
          key={`${key}-filelist`}
          active={this.state.active[key] === true}
        >
          <FileList files={sections[key].entries} />
        </Accordion.Content>
      );
    });

    if (results.length === 0) {
      return (
        <StyledItalicDiv>{t("file.no_attached_documents")}</StyledItalicDiv>
      );
    }

    return (
      <Accordion styled fluid>
        {results}
      </Accordion>
    );
  }

  render() {
    const sections = this.mapSections(this.props.files, this.props.dirs);

    return (
      <Segment>
        <RoleRequired staff={true} permission={permissions.boardEdit}>
          <UploadForm />
        </RoleRequired>
        {this.renderPanels(sections)}
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    files: state.file.entities || [],
    dirs: state.fileDir.entities || [],
    lastUpload: state.file.lastUpload,
  };
}

export const FileBox = connect(mapStateToProps, undefined)(FileBoxContainer);
