/* @flow */
import React, { Component } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  clearError,
  createPaymentReturn as settlementCreatePaymentReturn,
} from "../../../redux/modules/charge";
import { getEstate } from "../../../redux/modules/estates";
import { CalendarInput } from "../../../components/common/calendar-input";
import { FormdataManager } from "../../../components/common/formdata";
import { ErrorWrap } from "../../../helpers/error";
import { withRouter } from "react-router";
import { PaymentMethod } from "../../../config";
import { t } from "../../../constant/translations";
import dayjs from "dayjs";

type Props = {
  estate: Object,
  charge: Object,
  loading: boolean,
  error?: ErrorWrap,
  triggerText?: string,
};

export class ReturnCharge extends Component<Props> {
  state = {
    formData: {},
    modalOpen: false,
  };

  componentDidMount() {
    this.setState({
      formData: {
        estate: this.props.estate.id,
        description: "",
        charge_id: this.props.charge.id,
      },
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.loading && !this.props.loading && !this.props.error.exists()) {
      this.setState({ modalOpen: false });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.props.loading) {
      this.props.submit(this.state.formData);
    }
  };

  render() {
    const { estate, loading, error, media, charge } = this.props;
    const form = new FormdataManager(this.state.formData, (formData) =>
      this.setState({ formData })
    );
    form.withError(error);

    return (
      <Modal
        onOpen={() => this.setState({ modalOpen: true })}
        onClose={() => {
          if (!loading) {
            this.setState({ modalOpen: false }, this.props.clearError);
          }
        }}
        open={this.state.modalOpen}
        trigger={
          <Button
            basic
            size={"tiny"}
            icon={"undo"}
            disabled={charge.amount_left === charge.amount}
          />
        }
        closeIcon={true}
      >
        <Modal.Header>
          {t("charge.return_obligation")} {estate.name} {t("labels.from")}{" "}
          {parseFloat(charge.amount - charge.amount_left).toFixed(2)}{" "}
          {t("resources.bgn")} {t("labels.in")}
          {t("charge.balance")}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={this.handleSubmit} error={error.exists()}>
              <Form.Field
                control="textarea"
                label={t("charge.cause")}
                rows={3}
                {...form.getAttributes("description")}
              />
              <Message
                error
                content={error.exists() ? error.getGlobal() : t("error.savng")}
              />
              <Button
                type="submit"
                fluid
                size="large"
                positive
                loading={loading}
              >
                {t("charge.return_to_balance")}
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.charge.pending,
    error: ErrorWrap.fromError(state.charge.error),
  };
}

const buildPaymentReturnAction = ({ history }) => (formData) => {
  return (dispatch) => {
    return dispatch(settlementCreatePaymentReturn(formData)).then(
      (resp) => !resp.error && dispatch(getEstate(formData.estate))
    );
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const submit = buildPaymentReturnAction(ownProps);
  return bindActionCreators({ submit, clearError }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReturnCharge)
);
