import "react-day-picker/lib/style.css";

import React from "react";
import { Form, Input } from "semantic-ui-react";

const validateAndSanitizePhoneNumber = (value) => {
  if (!value) {
    return value;
  }
  let phone = "";
  for (let i of value) {
    if (/^\d+$/.test(i)) {
      phone += i;
    }
  }
  return phone;
};

export const PhoneNumberInput = ({ value, onChange, name, error }) => {
  return (
    <Form.Field>
      <Input
        label="+"
        type="tel"
        name={name}
        placeholder={"359123456789"}
        value={value}
        error={error}
        onChange={(event) => {
          const phoneNumber = validateAndSanitizePhoneNumber(
            event.target.value
          );
          onChange(null, { value: phoneNumber });
        }}
      />
    </Form.Field>
  );
};
