import styled from "styled-components";
import React from "react";
import { imgSrc } from "../../helpers/static";
import { routes, reverse } from "../../routes";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { device } from "../../config";

const LogoWrapper = styled.div`
  text-align: center;
  width: 100%;
  overflow: hidden;
  height: 60px;
  margin-bottom: 10px;
  padding: 10px 18px;

  @media ${device.handheld} {
    height: 100px;
    margin-bottom: 10px;
  }
`;

export const LogoComponent = (props) => {
  let targetURL = routes.blockList;
  if (props.selected && props.blockNum === 1) {
    targetURL = reverse(routes.blockDetails, { blockId: props.selected.id });
  }
  return (
    <LogoWrapper style={{ ...props.style }}>
      <Link to={targetURL}>
        <img src={imgSrc("logo.png")} style={{ width: "100%" }} />
      </Link>
    </LogoWrapper>
  );
};

export const Logo = connect(
  (state) => {
    return {
      selected: state.blocks.selected,
      blockNum: state.blocks.entities ? state.blocks.entities.length : 0,
    };
  },
  () => {
    return {};
  }
)(LogoComponent);
