import React from "react";
import { Button } from "semantic-ui-react";
import { t } from "../../constant/translations";

export const QuickDateNavigation = ({ value, onClick }) => {
  const range = [1, 10, 30, 60];

  return (
    <Button.Group>
      {range.map((entry) => (
        <Button
          key={entry}
          type={"button"}
          size={"mini"}
          active={value === entry}
          toggle={true}
          basic={true}
          onClick={(event) => {
            event.preventDefault();
            onClick(entry !== value ? entry : null);
          }}
        >
          {entry} {entry === 1 ? t("labels.day") : t("labels.days")}
        </Button>
      ))}
    </Button.Group>
  );
};
