import React, { Component } from "react";
import BillingModelList from "./billing-model-list";
import BlockBudget from "./budget/list";
import { Grid } from "semantic-ui-react";
import { fetchBillingModels } from "../../redux/modules/billing-models";
import { listBillingForecast } from "../../redux/modules/billing-forecast";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FundList } from "./fund/list";
import { t } from "../../constant/translations";

class BlockBudgetPageComponent extends Component {
  componentDidMount() {
    this.props.listBillingForecast(this.props.block.id);
    this.props.fetchBillingModels(this.props.block.id);
  }

  render() {
    return (
      <Grid padded columns="equal">
        <Grid.Row>
          <Grid.Column tablet={16} computer={6}>
            <h5>{t("billing.tills")}</h5>
            <FundList block={this.props.block} />
          </Grid.Column>
          <Grid.Column tablet={16} computer={10}>
            <h5>{t("billing.payment_model")}</h5>
            <BillingModelList />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h5>{t("billing.budget")}</h5>
            <BlockBudget block={this.props.block.id} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { listBillingForecast, fetchBillingModels },
    dispatch
  );
};

export const BlockBudgetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockBudgetPageComponent);
