import "core-js/stable";
import "regenerator-runtime/runtime";

import "semantic-ui-css/semantic.min.css";
import "../../assets/css/less-space.css";
import "../../assets/css/global.css";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration from "dayjs/plugin/duration";

import "dayjs/locale/bg";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider as ReactReduxProvider } from "react-redux";
import { store } from "./redux/store";
import { MediaQueryProvider } from "react-media-query-hoc";
import getJSONGlobals from "safe-json-globals/get";
import { PrintProvider } from "react-easy-print";
import { QueryParamProvider } from "use-query-params";
import { App } from "./containers/app/app";
import { routes } from "./routes";
import { Analytics } from "./helpers/analytics";
import GoogleAnalytics from "react-ga4";
import { AppTheme } from "./theme/theme";
import { ThemeProvider } from "styled-components";
import { device } from "./config";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
dayjs.locale("bg");
dayjs.tz.setDefault("Europe/Sofia");

Sentry.init({ dsn: getJSONGlobals("sentry_dsn") });
GoogleAnalytics.initialize("G-N5XYE9726L");

ReactDOM.render(
  <ReactReduxProvider key="provider" store={store}>
    <PrintProvider id={"123alabala"}>
      <MediaQueryProvider queries={device}>
        <BrowserRouter>
          <Analytics>
            <QueryParamProvider ReactRouterRoute={Route}>
              <ThemeProvider theme={AppTheme}>
                <div>
                  <Route path={routes.baseURL} component={App} />
                </div>
              </ThemeProvider>
            </QueryParamProvider>
          </Analytics>
        </BrowserRouter>
      </MediaQueryProvider>
    </PrintProvider>
  </ReactReduxProvider>,
  document.getElementById("react-app")
);
