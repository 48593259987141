import styled from "styled-components";
import * as React from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";

type Props = {
  content: Object,
  onChange: Function,
  loading: Boolean,
  error: Object,
};

const StyledSegment = styled.div`
  min-height: 160px;
`;

export class MarkdownEdit extends React.Component<Props> {
  state = { editorState: EditorState.createEmpty() };

  componentDidMount() {
    this.setState({ editorState: this.getEditorState() });
  }

  getEditorState = () => {
    const content = convertFromRaw(markdownToDraft(this.props.value));
    return EditorState.createWithContent(content);
  };

  getMarkdown = () => {
    return draftToMarkdown(
      convertToRaw(this.state.editorState.getCurrentContent()),
      {
        remarkableOptions: {
          preserveNewlines: true,
        },
      }
    );
  };

  render() {
    return (
      <StyledSegment>
        <Editor
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          editorStyle={{
            minHeight: "160px",
            borderRadius: "2px",
            border: "1px solid #f1f1f1",
            padding: "6px 5px 0",
            marginBottom: "5px",
          }}
          editorState={this.state.editorState}
          onEditorStateChange={(editorState) => {
            const value = this.getMarkdown();
            this.setState({ editorState });
            this.props.onChange({ target: { value } }, { value });
          }}
          toolbar={{
            options: ["inline", "blockType", "list", "link", "emoji"],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </StyledSegment>
    );
  }
}
