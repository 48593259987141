import { newApi } from "../restdux/index";

export const billingSummaryManager = newApi(
  "billing-summary",
  "/api/billing-summary/"
);
export const reducer = billingSummaryManager.reducer;

export function fetchBillingSummary(blockId) {
  return billingSummaryManager.actions.get({ id: blockId });
}
