import React from "react";

import { Divider, Form, FormField } from "semantic-ui-react";
import { EditEntity } from "../../components/common/edit-entity";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { changePassword } from "../../redux/modules/auth";
import { t } from "../../constant/translations";

export class PasswordChangeComponent extends React.Component {
  state = {
    formData: null,
  };

  render() {
    const { error } = this.props;
    const manager = new FormdataManager(
      this.state.formData || getInitialState(),
      (formData) => this.setState({ formData })
    ).withError(error);
    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={t("password.change")}
        customTrigger={{ text: t("password.change2") }}
      >
        <FormField>
          <Form.Input
            icon="key"
            iconPosition="left"
            placeholder={t("login.password")}
            type="password"
            {...manager.getAttributes("new_password1")}
          />
          <Divider />
          <Form.Input
            icon="key"
            iconPosition="left"
            placeholder={t("login.repeat_password")}
            type="password"
            {...manager.getAttributes("new_password2")}
          />
        </FormField>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.auth.error),
    loading: state.auth.loading,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      create: changePassword,
      update: changePassword,
    },
    dispatch
  );
}

export const PasswordChange = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChangeComponent);
