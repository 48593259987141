import { newApi, request } from "../restdux/index";
import { defaultInitialState } from "../restdux";

type AccountDetails = {
  id?: number,
  email?: string,
  first_name: string,
  last_name?: string,
  role?: "user" | "staff" | "admin",
  is_active?: boolean,
};

type Block = {
  id: number,
  name: string,
};

type Estate = {
  id: number,
  name: string,
  vendor_id: string,
};

export type Settlement = {
  id: ?number,
  creator_details: ?AccountDetails,
  reviewer_details: ?AccountDetails,
  auto_settle: boolean,
  created_at: string,
  updated_at: string,
  deleted_at: ?string,
  description: ?string,
  approved: ?boolean,
  prepaid_amount: ?number,
  deposit_amount: ?number,
  settled_amount: ?number,
  creator: number,
  reviewer: ?number,
  block: number,
  block_details: Block,
  estate: number,
  estate_details: Estate,
  transactions: number[],
  settle_date: ?string,
  fee: number,
};

const ACTION_CLEAR_LAST_MODIFIED = "/mecasa/settlement/clear_last_modified";

const initialState = {
  ...defaultInitialState,
  created: null,
};

export const settlementManager = newApi("settlement", "/api/settlement/", true);
export function reducer(state = initialState, action = {}) {
  const newState = settlementManager.reducer(state, action);

  switch (action.type) {
    case ACTION_CLEAR_LAST_MODIFIED:
      return { ...newState, lastModified: null };
    default:
      return newState;
  }
}

export function fetchSettlements(params) {
  return settlementManager.actions.list(params);
}

export function getSettlement(params, inBackground) {
  return settlementManager.actions.get(params, { inBackground: inBackground });
}

export function create(data) {
  return settlementManager.actions.create(data);
}

export function createPaymentReverse(data) {
  return (dispatch, getState, client) => {
    const getPromise = () =>
      client.post(`/api/settlement/perform_create_payment_reverse/`, { data });
    return request(
      settlementManager.actionTypes.CREATE_PENDING,
      settlementManager.actionTypes.CREATE_SUCCESS,
      settlementManager.actionTypes.CREATE_ERROR
    )(getPromise, dispatch);
  };
}

export function deleteSettlement(data) {
  return settlementManager.actions.del(data);
}

export function clearCreated() {
  return { type: ACTION_CLEAR_LAST_MODIFIED };
}

export function clearError() {
  return settlementManager.actions.clearError();
}

export function sortBySettleDate(settlements) {
  if (!settlements) {
    return null;
  }

  return settlements.sort(
    (x, y) =>
      new Date(y.settle_date) - new Date(x.settle_date) ||
      new Date(y.created_at) - new Date(x.created_at)
  );
}

export function approveSettlement(data) {
  return (dispatch, getState, client) => {
    const getPromise = () => client.post(`/api/settlement/${data.id}/approve/`);
    return request(
      settlementManager.actionTypes.UPDATE_PENDING,
      settlementManager.actionTypes.UPDATE_SUCCESS,
      settlementManager.actionTypes.UPDATE_ERROR
    )(getPromise, dispatch);
  };
}
