import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { DateParam, StringParam } from "use-query-params";
import { t } from "../../../constant/translations";
import * as app from "../../../constant/app";
import { connect } from "react-redux";
import DayPickerInput from "react-day-picker/DayPickerInput";

export const auditQueryFilterParams = {
  user: StringParam,
  action: StringParam,
  resource: StringParam,
  block: StringParam,
  created_after: DateParam,
  created_before: DateParam,
  payment_method: StringParam,
};

export const ContentFilterFormBody = ({ data, onChange, users, blocks }) => {
  return (
    <Fragment>
      <h5>{t("labels.user")}</h5>
      <Form.Select
        value={data.users}
        options={[null, ...users].map((e) => {
          if (e) {
            return { text: e.full_name, value: e.id };
          } else {
            return { text: t("resources.null"), value: null };
          }
        })}
        onChange={onChange("user")}
      />
      <h5>{t("labels.block")}</h5>
      <Form.Select
        value={data.blocks}
        options={[null, ...blocks].map((e) => {
          if (e) {
            return { text: e.name, value: e.id };
          } else {
            return { text: t("resources.null"), value: null };
          }
        })}
        onChange={onChange("block")}
      />
      <h5>{t("labels.action")}</h5>
      <Form.Select
        value={data.action}
        options={[null, "create", "update", "destroy", "reconcile"].map((e) => {
          return { text: t(`logActions.${e}`), value: e };
        })}
        onChange={onChange("action")}
      />
      <h5>{t("labels.resource")}</h5>
      <Form.Select
        value={data.resource}
        options={[null, ...app.resourceTypes].map((e) => {
          return { text: t(`resources.${e}`, e), value: e };
        })}
        onChange={onChange("resource")}
      />

      {data.resource === "settlement" ? (
        <Form.Select
          placeholder={t("management.payment_method")}
          value={data.payment_method}
          options={[null, "cash", "bank"].map((e) => {
            return { text: t(`paymentTypes.${e}`), value: e };
          })}
          onChange={onChange("payment_method")}
        />
      ) : null}

      <h5>{t("charge.period")}</h5>
      <div>
        <DayPickerInput
          placeholder={t("management.from_date")}
          value={data.created_after}
          onDayChange={(dateValue) =>
            onChange("created_after")(null, { value: dateValue })
          }
        />
        <DayPickerInput
          placeholder={t("titles.to_date")}
          value={data.created_before}
          onDayChange={(dateValue) =>
            onChange("created_before")(null, { value: dateValue })
          }
        />
      </div>
    </Fragment>
  );
};

export const ContentFilterForm = connect((state) => {
  return {
    auditLog: state.auditLog.entities,
    users: state.user.entities.map(({ full_name, id, email }) => ({
      full_name: full_name || email,
      id,
    })),
    blocks: state.blocks.entities.map(({ name, id }) => ({
      name,
      id,
    })),
  };
})(ContentFilterFormBody);
