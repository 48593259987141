import type { FormProps } from "../../../components/common/edit-entity";
import { EditEntity } from "../../../components/common/edit-entity";
import { billingAttrManager } from "../../../redux/modules/billing-attributes";

import React, { Component } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import {
  FormdataManager,
  getInitialState,
} from "../../../components/common/formdata";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ErrorWrap } from "../../../helpers/error";
import { getBillingForecast } from "../../../redux/modules/billing-forecast";
import { amountToBGN } from "../../../helpers/currency";
import { t } from "../../../constant/translations";

export class AttributeEditComponent extends Component<FormProps> {
  state = { formData: null };

  getTypeOptions = (models) => {
    const combinedMaps = {};
    models.forEach((entity) => {
      const currentPrice = combinedMaps[entity.type] || 0;
      combinedMaps[entity.type] = currentPrice + parseFloat(entity.price);
    });
    return Object.keys(combinedMaps).map((ctype) => {
      return {
        text:
          `${ctype} - ${amountToBGN(combinedMaps[ctype])} / ` +
          t("billing.unt"),
        value: ctype,
      };
    });
  };

  render() {
    const { error, entity, models } = this.props;
    const initialState = getInitialState(
      this.props.entity,
      this.props.initialData
    );
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );
    const header = this.props.entity
      ? t("billing.change_of") + ` ${entity.type}`
      : t("billing.newattr");
    return (
      <EditEntity manager={manager} header={header} {...this.props}>
        <Form.Field>
          <label>{t("labels.type")}</label>
          <Dropdown
            placeholder={t("labels.type")}
            error={error.has("type")}
            fluid
            selection
            options={this.getTypeOptions(models)}
            onChange={(event, { value }) => manager.setValue("type", value)}
            value={manager.getValue("type")}
          />
          {error.has("value") && <span>{error.get("value")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("billing.value")}</label>
          <Form.Input
            as={"input"}
            type={"number"}
            placeholder={t("billing.value")}
            error={error.has("value")}
            step="0.01"
            {...manager.getAttributes("value")}
            control={1}
          />
          {error.has("value") && <span>{error.get("value")}</span>}
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    models: state.billingModel.entities || [],
    error: ErrorWrap.fromError(state.billingAttr.error),
    loading: state.billingAttr.pending,
  };
}

function mapDispatchToProps(dispatch) {
  const successGetForecast = (action) => (data) => {
    return (dispatch) => {
      dispatch(action(data)).then((action) =>
        dispatch(getBillingForecast(data.estate || action.result.estate))
      );
    };
  };

  return bindActionCreators(
    {
      create: successGetForecast(billingAttrManager.actions.create),
      update: successGetForecast(billingAttrManager.actions.update),
      del: successGetForecast(billingAttrManager.actions.del),
      clearError: billingAttrManager.actions.clearError,
    },
    dispatch
  );
}

export const AttributeEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributeEditComponent);
