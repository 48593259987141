import qs from "qs";
import dayjs from "dayjs";
import * as React from "react";
import { Form, Segment } from "semantic-ui-react";
import { t } from "../../../constant/translations";

import { DownloadLink } from "../../../helpers/download-link";
import FundsPicker from "./funds-picker";

const REPORT_TYPE = { complete: 0, monthlyIncome: 1, byFunds: 2 };

export const ReportDownloadForm = ({ block }) => {
  const dateFormat = "YYYY-MM-DD";
  const [fromDate, setFromDate] = React.useState(
    dayjs().subtract(3, "months").format(dateFormat)
  );
  const [toDate, setToDate] = React.useState(dayjs().format(dateFormat));
  const downloadActive = fromDate && toDate;

  const [reportType, setReportType] = React.useState(REPORT_TYPE.complete);

  const [selectedFunds, setSelectedFunds] = React.useState([]);

  let baseURL, queryParams, title;
  switch (reportType) {
    case REPORT_TYPE.complete:
      baseURL = "/api/reports/block-complete/";
      queryParams = qs.stringify(
        { from: fromDate, to: toDate, block: block.id, format: "pdf" },
        { addQueryPrefix: false }
      );
      title = t("titles.full_report") + ` - ${block.name}.pdf`;
      break;
    case REPORT_TYPE.monthlyIncome:
      baseURL = "/api/reports/monthly-block-income/";
      queryParams = qs.stringify(
        { from: fromDate, to: toDate, block: block.id, format: "csv" },
        { addQueryPrefix: false }
      );
      title = t("titles.debts_income") + ` - ${block.name}.csv`;
      break;
    case REPORT_TYPE.byFunds:
      const funds =
        selectedFunds.length == 0
          ? block.funds.map((fund) => {
              return fund.id;
            })
          : selectedFunds;
      baseURL = "/api/reports/by-fund/";
      queryParams = qs.stringify(
        {
          from: fromDate,
          to: toDate,
          block: block.id,
          funds: funds.toString(),
          format: "csv",
        },
        { addQueryPrefix: false }
      );
      const fundNames = block.funds
        .map((fund, index) => {
          return selectedFunds.includes(fund.id)
            ? block.funds[index].name
            : null;
        })
        .filter((value) => value != null);
      title =
        t("titles.cash_report") +
        ` - ${block.name} - ${fundNames.toString()}.csv`;
      break;
  }

  return (
    <Segment>
      <Form>
        <Form.Select
          label={t("labels.type")}
          placeholder={t("labels.select_report_type")}
          options={[
            {
              key: REPORT_TYPE.complete,
              value: 0,
              text: t("titles.full_report") + " (.pdf)",
            },
            {
              key: REPORT_TYPE.monthlyIncome,
              value: 1,
              text: t("titles.report_debt_deposits") + " (.csv)",
            },
            {
              key: REPORT_TYPE.byFunds,
              value: 2,
              text: t("titles.cash_report") + " (.csv)",
            },
          ]}
          value={reportType}
          onChange={(_, { value }) => {
            setReportType(value);
          }}
        />
        <Form.Group widths="equal">
          <Form.Input
            type={"date"}
            label={t("titles.from_date")}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <Form.Input
            label={t("titles.to_date")}
            type={"date"}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <div style={{ marginTop: "auto", marginBottom: "1px" }}>
            <DownloadLink
              href={`${baseURL}?${queryParams}`}
              target={"_blank"}
              download={title}
              disabled={!downloadActive}
              icon={"download"}
            />
          </div>
        </Form.Group>
        <FundsPicker
          active={reportType == REPORT_TYPE.byFunds}
          funds={block.funds}
          setSelectedFunds={setSelectedFunds}
        />
      </Form>
    </Segment>
  );
};
