import React from "react";

import { Button, Form, FormField, Input, Message } from "semantic-ui-react";
import { load, login } from "../../redux/modules/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ErrorWrap } from "../../helpers/error";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { withRouter } from "react-router";
import { StyledLinks } from "./styled-components";
import { t } from "../../constant/translations";

type Props = {
  user?: Number,
  error?: Object,
  login?: Function,
  setField?: Function,
};

class LoginComponent extends React.Component<Props> {
  state = {
    data: {},
  };

  onSubmit(e) {
    e.preventDefault();
    this.props.login(this.state.data.email, this.state.data.password);
  }

  setField(name, value) {
    const data = this.state.data || {};
    if (value) {
      data[name] = value;
    } else {
      delete data[name];
    }
    this.setState({ data: data });
  }

  render() {
    const error = new ErrorWrap(this.props.error);
    return (
      <Form
        action=""
        onSubmit={this.onSubmit.bind(this)}
        error={error.exists()}
      >
        <FormField>
          <Input
            name="email"
            icon="mail"
            iconPosition="left"
            placeholder="Е-mail"
            error={error.exists()}
            onChange={(event) => this.setField("email", event.target.value)}
          />
        </FormField>
        <FormField>
          <Input
            name="password"
            icon="key"
            iconPosition="left"
            placeholder={t("login.password")}
            type="password"
            error={error.exists()}
            onChange={(event) => this.setField("password", event.target.value)}
          />
        </FormField>
        <StyledLinks>
          <Link to={routes.authPasswordReset}>{t("login.pass_manage")}</Link>
        </StyledLinks>

        <Message error content={error.getGlobal()} />
        <Button
          type="submit"
          fluid
          size="large"
          primary={true}
          disabled={!(this.state.data.email && this.state.data.password)}
        >
          {t("labels.enter")}
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    error: state.auth.error,
    userKey: state.auth.userKey,
  };
};

const loginAndLoadUser = (username, password) => {
  return (dispatch) => {
    dispatch(login(username, password)).then(
      (resp) => {
        dispatch(load());
      },
      (err) => {
        return err;
      }
    );
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login: loginAndLoadUser }, dispatch);
};

export const Login = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
);
