import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";

import { t } from "../../../constant/translations";
import { StringParam } from "use-query-params";
import { CalendarInput } from "../../../components/common/calendar-input";
import { ChargeModelSelect } from "./model-select-field";

export const chargeQueryFilterParams = {
  q: StringParam,
  status: StringParam,
  from: StringParam,
  to: StringParam,
  model: StringParam,
  vendor_id: StringParam,
};

export const chargeQueryKeyMap = {
  q: t("charge.search"),
  vendor_id: (value) => t("charge.branch_numb") + ` ${value}`,
  status: (value) =>
    t("charge.condition") + `: ${t(`chargeStatus.${value}`).toLowerCase()}`,
  from: (value) => t("charge.from_date") + `: ${value}`,
  to: (value) => t("charge.to_date") + `: ${value}`,
  model: (value) => t("charge.model") + `: ${value}`,
};

export const ContentFilterFormBody = ({ data, onChange }) => {
  return (
    <Fragment>
      <Form.Input
        icon="search"
        placeholder={t("charge.search_capital")}
        onChange={onChange("q")}
        value={data.q || ""}
        name={"search-charge"}
      />
      <h5>{t("labels.client_number")}</h5>
      <Form.Input
        value={data.vendor_id || null}
        onChange={onChange("vendor_id")}
      />
      <h5>{t("labels.status")}</h5>
      <Form.Select
        value={data.status || null}
        options={[null, "settled", "unsettled", "partial"].map((e) => {
          return { text: t(`chargeStatus.${e}`), value: e };
        })}
        onChange={onChange("status")}
      />
      <h5>{t("charge.billing_model")}</h5>
      <ChargeModelSelect value={data.model} onChange={onChange("model")} />
      <h5>{t("titles.from_date")}</h5>
      <CalendarInput
        name="from"
        value={data.from}
        onChange={(e) => onChange("from")(null, { value: e.target.value })}
      />

      <h5>{t("titles.to_date")}</h5>
      <CalendarInput
        name="to"
        value={data.to}
        onChange={(e) => onChange("to")(null, { value: e.target.value })}
      />
    </Fragment>
  );
};
