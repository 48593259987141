/* @flow */
import styled from "styled-components";
import React, { Component } from "react";
import { Checkbox, Loader, Popup, Table } from "semantic-ui-react";
import classnames from "classnames";
import { Description } from "../../components/billing/charge-description";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchEstateCharges } from "../../redux/modules/charge";
import { getById } from "../../redux/restdux/utils";
import { amountToBGN } from "../../helpers/currency";
import { formatDate } from "../../helpers/date";
import { t } from "../../constant/translations";

type Props = {
  estate: Object,
  chargeQ: Object,
  charges: Array<any>,
  fetchCharges: Function,
  onSelect: Function,
  loading: boolean,
  selectableRows?: boolean,
  amountField?: string,
};

type State = {
  selected: Set<any>,
};

const StyledAmountSpan = styled.span`
  font-weight: bold;
  text-align: right;
`;

const StyledTableRow = styled(Table.Row)`
  &.rowSelected {
    background-color: ${(props) => props.theme.rowSelected};
    color: #fff;
    a,
    a:hover {
      color: #fff !important;
    }
    transition: background-color 0.3s ease;
  }
`;

const Totals = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 10px 10px 0;
`;

export class ChargeListSmallComponent extends Component<Props, State> {
  static defaultProps = {
    chargeQ: {},
  };
  constructor(props: Props) {
    super(props);
    this.state = { selected: new Set() };
  }

  componentDidMount() {
    const query = {
      page_size: this.props.chargeQ.page_size || 300,
      status: this.props.chargeQ.status || "unsettled",
    };

    if (this.props.fetchCharges) {
      this.props.fetchCharges(this.props.estate.id, query);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.selectableRows !== this.props.selectableRows) {
      this.setState({ selected: new Set() });
    }
  }

  flipSelected = (charge) => {
    if (!this.props.selectableRows) {
      return;
    }

    const selected = this.state.selected;
    if (selected.has(charge.id)) {
      selected.delete(charge.id);
    } else {
      selected.add(charge.id);
    }
    this.setState({ selected });
    this.props.onSelect(selected);
  };

  flipAll = () => {
    if (!this.props.selectableRows) {
      return;
    }

    const selected = new Set();
    if (!this.checkAllSelected()) {
      for (const charge of this.props.charges) {
        selected.add(charge.id);
      }
    }
    this.setState({ selected });
    this.props.onSelect(selected);
  };

  checkAllSelected = () => {
    if (!this.props.charges || this.props.charges.length === 0) {
      return false;
    }

    for (const charge of this.props.charges) {
      if (!this.state.selected.has(charge.id)) {
        return false;
      }
    }

    return true;
  };

  getTotalSelected = () => {
    const selected = this.state.selected;
    let total = 0;
    for (const itemId of selected) {
      const charge = getById(itemId, this.props.charges);
      total = total + parseFloat(charge[this.props.amountField]);
    }

    if (total === 0) {
      return null;
    }

    return total.toFixed(2).toString();
  };

  renderChargeList = () => {
    return this.props.charges.map((charge) => {
      return (
        <StyledTableRow
          key={charge.id}
          onClick={() => this.flipSelected(charge)}
          className={classnames({
            rowSelected: this.state.selected.has(charge.id),
          })}
        >
          {this.props.selectableRows && (
            <Table.Cell singleLine>
              <Checkbox checked={this.state.selected.has(charge.id)} />
            </Table.Cell>
          )}
          {!this.props.estate && (
            <Table.Cell>{charge.estate_details.name}</Table.Cell>
          )}
          <Table.Cell singleLine>{formatDate(charge.charge_date)}</Table.Cell>
          <Table.Cell singleLine>{charge.fund}</Table.Cell>
          <Table.Cell singleLine textAlign={"right"}>
            <Popup
              position={"left center"}
              hideOnScroll={true}
              hoverable
              flowing
              content={<Description charge={charge} />}
              trigger={
                <a href={"#"}>{amountToBGN(charge[this.props.amountField])}</a>
              }
            />
          </Table.Cell>
        </StyledTableRow>
      );
    });
  };

  render() {
    if (this.props.loading) {
      return <Loader active={true} />;
    }
    return (
      <>
        <Table unstackable={true} compact={true}>
          <Table.Header>
            <Table.Row>
              {this.props.selectableRows && (
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.checkAllSelected()}
                    onClick={this.flipAll}
                    disabled={!this.props.selectableRows}
                  />
                </Table.HeaderCell>
              )}
              {!this.props.estate && (
                <Table.HeaderCell>
                  {t("resources.estate_capital")}
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>{t("labels.date")}</Table.HeaderCell>
              <Table.HeaderCell>{t("labels.direction")}</Table.HeaderCell>
              <Table.HeaderCell textAlign={"right"}>
                {t("labels.amount")}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderChargeList()}</Table.Body>
        </Table>
        <Totals>
          {this.getTotalSelected() && (
            <StyledAmountSpan>
              {t("charge.total")}: {amountToBGN(this.getTotalSelected())}
            </StyledAmountSpan>
          )}
        </Totals>
      </>
    );
  }
}

function mapStateToProps(state) {
  const namespace = state.charge.payment || {};
  return {
    charges: namespace.entities,
    loading: !namespace.entities || namespace.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCharges: (estateId, query) =>
        fetchEstateCharges(estateId, query, "payment"),
    },
    dispatch
  );
}

export const ChargeListSmall = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargeListSmallComponent);
