import React from "react";
import { withMedia } from "react-media-query-hoc";
import { Button, Icon } from "semantic-ui-react";
import { t } from "../../constant/translations";

const FilterButtonComponent = ({ media, onClick, ...props }) => (
  <Button size="tiny" basic={true} onClick={onClick} {...props}>
    <Icon name={"filter"} />
    {media.handheld ? <span>{t("labels.filters")}</span> : null}
  </Button>
);

export const FilterButton = withMedia(FilterButtonComponent);
