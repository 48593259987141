import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ChargeListComponent } from "../../components/billing/charge-list";
import {
  deleteCharge,
  fetchEstateCharges,
  editCharge,
} from "../../redux/modules/charge";
import { withRefreshEstate } from "../../redux/modules/estates";
import { ErrorWrap } from "../../helpers/error";

function mapStateToProps(state) {
  return {
    charges: state.charge.entities,
    loading: state.charge.pending,
    error: new ErrorWrap(state.charge.error),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      fetchCharges: (estate, query) =>
        fetchEstateCharges(estate, {
          unsettled_first: true,
          page_size: 300,
          ...query,
        }),
      editCharge: withRefreshEstate(editCharge, ownProps.estate.id),
      deleteCharge: withRefreshEstate(deleteCharge, ownProps.estate.id),
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargeListComponent);
