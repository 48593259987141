import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Error from "../../containers/error";

export const Preload = (depsInstance, loadDeps, getIds) => {
  function stateToProps(state) {
    return { depState: state.deps[depsInstance.name] };
  }

  function dispatchToProps(dispatch) {
    return bindActionCreators({ loadDeps }, dispatch);
  }

  return (WrappedComponent) => {
    class Wrapper extends Component {
      componentDidMount() {
        const ids = getIds(this.props);
        this.props.loadDeps(ids);
      }

      componentDidUpdate(oldProps) {
        if (this.props === oldProps) {
          return;
        }
        const ids = getIds(oldProps);
        const newIds = getIds(this.props);
        for (const key of Object.keys(newIds)) {
          if (newIds[key] !== ids[key]) {
            this.props.loadDeps(newIds);
            return;
          }
        }
      }

      render() {
        const depstate = this.props.depState;
        if (depstate.pending || !depstate.loaded) {
          return <Loader active />;
        }
        if (depstate.error) {
          return <Error error={depstate.error} />;
        }

        return <WrappedComponent {...this.props} />;
      }
    }

    return connect(stateToProps, dispatchToProps)(Wrapper);
  };
};
