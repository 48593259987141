import React, { Component } from "react";
import styled from "styled-components";
import { Loader, Message } from "semantic-ui-react";
import type { Settlement } from "../../../redux/modules/settlement";
import {
  fetchSettlements,
  settlementManager,
  sortBySettleDate,
} from "../../../redux/modules/settlement";
import { ErrorWrap } from "../../../helpers/error";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SettlementTable } from "./settlement-table";
import { sliceList } from "../../../helpers/filter";
import { Pagination } from "../../../components/common/pagination";
import { Link } from "react-router-dom";
import { t } from "../../../constant/translations";

type Props = {
  settlement: ?Array<Settlement>,
  error: Object,
  loading: boolean,
  fetchSettlements: (args: Object) => any,
  clearError: () => any,
};

const PaginationWrapper = styled.div`
  margin: 1em 0;
`;

export class SettlementListEstateComponent extends Component<Props> {
  state = { currentPage: 1, pageSize: 5 };

  componentDidMount() {
    this.props.fetchSettlements({
      estate: this.props.estate.id,
      page_size: 1000,
    });
  }

  componentDidUpdate(prevProps: Readonly<P>) {
    if (prevProps.estate && prevProps.estate !== this.props.estate) {
      this.props.fetchSettlements({ estate: this.props.estate.id });
    }
  }

  render() {
    const { settlements, loading, error, clearError } = this.props;

    if (loading || !settlements) {
      return <Loader active={true} />;
    }

    let page = [];
    if (settlements.length > 0) {
      page = sliceList(
        settlements,
        this.state.currentPage - 1,
        this.state.pageSize
      );
    }
    return (
      <div>
        {error.exists() && (
          <Message
            error
            content={error.getGlobal()}
            onDismiss={() => clearError()}
          />
        )}
        <PaginationWrapper>
          <Pagination
            current={this.state.currentPage}
            total={settlements.length}
            onChange={(currentPage, pageSize) => {
              this.setState({ currentPage, pageSize });
            }}
            pageSize={this.state.pageSize}
          />
          <Link
            to={`/ui/block/${this.props.estate.block.id}/billing/settlements/?estate_vendor_id=${this.props.estate.vendor_id}`}
            style={{ float: "right" }}
          >
            {t("labels.toall")}
          </Link>
        </PaginationWrapper>
        <div>
          <SettlementTable settlements={page} estateLink={false} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settlements: sortBySettleDate(state.settlement.entities),
    loading: state.settlement.pending,
    error: ErrorWrap.fromError(state.settlement.error),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSettlements,
      clearError: settlementManager.actions.clearError,
    },
    dispatch
  );
};

export const SettlementListEstate = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementListEstateComponent);
