import React from "react";

import { Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { getById } from "../../redux/restdux/utils";

const FileLabel = ({ fileId, files }) => {
  const file = getById(fileId, files);
  if (file) {
    return (
      <Label
        icon="file"
        content={file.filename}
        as="a"
        href={file.content}
        download
      />
    );
  }
  return null;
};

export default connect((state) => {
  return { files: state.file.entities };
})(FileLabel);
