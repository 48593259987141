import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Checkbox, Form, Dropdown, Select } from "semantic-ui-react";

import { ProfileEdit } from "../profile/edit";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  createEstateCustomer,
} from "../../redux/modules/estate-customers";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import type { FormProps } from "../../components/common/edit-entity";
import { t } from "../../constant/translations";

function getCustomerDisplayable(customer: any) {
  const data = [];
  if (customer.name) {
    data.push(customer.name);
  }
  if (customer.email) {
    data.push(customer.email);
  }
  return data.join(" - ");
}

export class AddCustomerModalComponent extends Component<FormProps> {
  state = { formData: null };

  componentDidUpdate(prevProps) {
    if (
      this.props.lastModifiedProfile &&
      prevProps.lastModifiedProfile !== this.props.lastModifiedProfile
    ) {
      const formData = this.state.formData || { estate: this.props.estate.id };
      formData.customer = this.props.lastModifiedProfile.id;
      this.setState({ formData });
    }
  }

  render() {
    const { estate, error, lastModifiedProfile } = this.props;
    const initialState = getInitialState({
      estate: estate.id,
    });
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );

    const selectCustomerOptions = this.props.profiles
      .filter(
        (p) =>
          !this.props.estateCustomers.find(
            (c) => c.estate === estate.id && c.customer === p.id
          )
      )
      .map((p) => ({
        key: p.id,
        text: getCustomerDisplayable(p),
        value: p.id,
      }));

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={t("estate.add_inhabitat")}
        trigger_props={{
          floated: "right",
          color: "green",
          icon: "plus",
          content: t("estate.inhabitat"),
          basic: true,
        }}
      >
        <Form.Field>
          <label>{t("estate.inhabitat")}</label>
          <Dropdown
            placeholder={t("estate.choose_profile")}
            search
            selection
            options={selectCustomerOptions}
            {...manager.getAttributes("customer")}
          />
          {error.has("customer") && <span>{error.get("customer")}</span>}
        </Form.Field>
        <ProfileEdit
          block={estate.block.id}
          customTrigger_props={{
            color: "blue",
            icon: "plus",
            content: t("estate.profile"),
            basic: true,
          }}
        />
        <Form.Field>
          <label>{t("labels.type")}</label>
          <Select
            placeholder={t("estate.select_type")}
            options={[
              { key: "tenant", value: "tenant", text: t("estate.tenant") },
              { key: "owner", value: "owner", text: t("estate.owner") },
            ]}
            {...manager.getAttributes("type")}
          />
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    profiles: state.profile.entities,
    lastModifiedProfile: state.profile.lastModified,
    estateCustomers: state.estatecustomers.entities,
    loading: state.estatecustomers.pending,
    error: ErrorWrap.fromError(state.estatecustomers.error),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { create: createEstateCustomer, clearError },
    dispatch
  );
}

export const AddCustomerModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCustomerModalComponent);
