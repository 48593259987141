import styled from "styled-components";

import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { reverse, routes } from "../../../routes";
import { withRouter } from "react-router";
import { UserMenuSection } from "./user";
import { SandboxNotice } from "./sandbox";
import { DropdownSwitchLanguage } from "../../../components/common/switchLanguage";

const HeaderLink = styled(Link)`
  font-size: 1.3em;
  font-size: min(3.5vw, 1.1em);
  font-weight: bold;
  margin: auto 1em;
`;

const StyledHeader = styled.div`
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.04);
`;

const StyledAppBar = styled.div`
  padding: 6px 10px;
  width: 100%;
  display: flex;
  min-height: 24px;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButton = styled(Button)`
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")} !important;
`;

const SectionBox = styled.div``;

const StyledLanguageButton = styled.div``;

class BlockMenu extends React.Component {
  render() {
    const { selected, sideMenuDocked, location } = this.props;
    const inBlock =
      selected &&
      location.pathname.startsWith(
        reverse(routes.blockBase, { blockId: selected.id })
      );

    return (
      <StyledHeader>
        <StyledAppBar>
          <StyledButton
            icon="content"
            onClick={this.props.toggleVisibility}
            hidden={sideMenuDocked}
          />
          {inBlock && (
            <HeaderLink
              to={reverse(routes.blockDetails, { blockId: selected.id })}
            >
              {selected.name}
            </HeaderLink>
          )}
          <SectionBox>
            <SandboxNotice />
            <UserMenuSection />
          </SectionBox>
        </StyledAppBar>
      </StyledHeader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.blocks.entities,
    selected: state.blocks.selected,
  };
};

export default withRouter(
  connect(mapStateToProps, () => {
    return {};
  })(BlockMenu)
);
