import React, { Component } from "react";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";
import { sliceList } from "../../helpers/filter";
import { Pagination } from "../common/pagination";
import type { Charge } from "../../redux/modules/charge";
import { ChargeTable } from "./charge-table";
import { Link } from "react-router-dom";
import { reverse, routes } from "../../routes";
import { t } from "../../constant/translations";

type Props = {
  charges: Array<Charge>,
  loading: boolean,
  estate: Object,
  error: ?Object,
  clearError: Function,
  fetchCharges: Function,
};

const ChargeHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaginationWrapper = styled.div`
  margin: 1em 0;
  width: 100%;
`;

export class ChargeListComponent extends Component<Props> {
  state = { currentPage: 1, pageSize: 5 };

  componentDidMount() {
    this.props.fetchCharges(this.props.estate.id);
  }

  componentDidUpdate(prevProps: Readonly<P>) {
    if (prevProps.estate && prevProps.estate !== this.props.estate) {
      this.props.fetchCharges(this.props.estate.id);
    }
  }

  handlePaginate = (currentPage, pageSize) => {
    this.setState({ currentPage, pageSize });
  };

  render() {
    const {
      estate,
      charges,
      loading,
      error,
      deleteCharge,
      editCharge,
    } = this.props;

    if (!charges) {
      return <Loader active={true} />;
    }

    const page = sliceList(
      charges,
      this.state.currentPage - 1,
      this.state.pageSize
    );
    const chargeListURL = reverse(routes.blockChargeList, {
      blockId: this.props.estate.block.id,
    });
    return (
      <div>
        <ChargeHeader>
          <PaginationWrapper>
            <Pagination
              current={this.state.currentPage}
              total={charges.length}
              onChange={this.handlePaginate}
              pageSize={this.state.pageSize}
            />
            <Link
              to={`${chargeListURL}?vendor_id=${this.props.estate.vendor_id}`}
              style={{ float: "right" }}
            >
              {t("labels.toall")}
            </Link>
          </PaginationWrapper>
        </ChargeHeader>
        <ChargeTable
          estate={estate}
          charges={page}
          editCharge={editCharge}
          deleteCharge={deleteCharge}
          loading={loading}
          error={error}
        />
      </div>
    );
  }
}
