//@flow
import styled from "styled-components";
import dayjs from "dayjs";
import React, { Component, Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Accordion,
  Button,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
  Tab,
  Table,
} from "semantic-ui-react";

import { ErrorWrap } from "../../helpers/error";
import { DownloadLink } from "../../helpers/download-link";
import { ReportDownloadForm } from "../../components/billing/report/report-download-form";

import { t } from "../../constant/translations";
import { apirequest } from "../../helpers/api-client";
import { amountToBGN } from "../../helpers/currency";
import {
  listMonthlyReports,
  manager,
  togglePublished,
} from "../../redux/modules/billing-monthly-report";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { TransactionEdit } from "./transaction-edit";
import { CancelTransactionForm } from "./transaction/cancel-transaction-form";
import { TransactionCreate } from "./transactions-page-create";
import { cancelTransaction } from "../../redux/modules/fundTransaction";

const MonthlyReportLineChart = lazy(() =>
  import("./reports/monthly-report-line-chart")
);

const humanizeDate = (dateField, dateFormat = "YYYY-MM-DD") => {
  const mtime = dayjs(dateField);
  if (mtime.year() === 1970) {
    return t("billing.old_period");
  }
  return mtime.tz().format(dateFormat);
};

const AccordeonTitleHeader = styled.div`
  flex-grow: 1;
`;

const StyledTable = styled(Table)({
  "@media screen and (max-width: 1025px)": {
    "font-size": "11px !important",
  },
});

const LastUpdatedText = styled.span`
  font-style: italic;
  font-weight: 200;
  font-size: 12px;
  text-transform: none;
  color: gray;
`;

class BlockBillingPage extends Component<TypeProps, StateProps> {
  state = {
    activeIndex: -1,
    reportDetails: {},
  };

  componentDidMount() {
    this.props.listMonthlyReports(this.props.block.id);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lastModifiedTransaction &&
      prevProps.lastModifiedTransaction !== this.props.lastModifiedTransaction
    ) {
      const lastModified = this.props.lastModifiedTransaction;
      const matchingReport = this.props.reports.find((entry) => {
        return (
          dayjs(entry.date).startOf("month").format("YYYY-MM") ==
          dayjs(lastModified.date).startOf("month").format("YYYY-MM")
        );
      });

      matchingReport && this.refreshReport(matchingReport);
    }
  }

  renderFunds = () => {
    const rows = this.props.block.funds.map((fund) => {
      return (
        <Grid.Column style={{ marginBottom: "10px" }} key={fund.id}>
          <Segment>
            <Header sub>{fund.name}</Header>
            <span>{amountToBGN(fund.balance)}</span>
          </Segment>
        </Grid.Column>
      );
    });

    return (
      <Grid columns="equal">
        <Grid.Row columns={3}>{rows}</Grid.Row>
      </Grid>
    );
  };

  refreshReport = async (report) => {
    const result = await apirequest(
      "get",
      `/api/billing-monthly-report/${report.id}/export/`
    );
    this.setState({
      reportDetails: {
        ...this.state.reportDetails,
        [report.id]: result.body.report,
      },
    });
  };

  handleToggleReport = (index) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex }, async () => {
      const { reportDetails } = this.state;
      const report = this.props.reports[newIndex];

      if (!report) return;
      if (reportDetails[report.id]) return;
      await this.refreshReport(report);
    });
  };

  renderReports = () => {
    const { reports, togglePublished } = this.props;
    const { activeIndex, reportDetails } = this.state;

    return (
      <Accordion styled fluid>
        {reports.map((report, index) => {
          const details = reportDetails[report.id];
          const published = report.published;
          return (
            <React.Fragment key={report.id}>
              <Accordion.Title
                active={activeIndex === index}
                style={{
                  overflow: "hidden",
                  textTransform: "capitalize",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AccordeonTitleHeader
                  onClick={() => this.handleToggleReport(index)}
                >
                  <Icon name="dropdown" />
                  {humanizeDate(report.date, "YYYY MMMM")}
                </AccordeonTitleHeader>
                <div>
                  <DownloadLink
                    href={`/api/billing-monthly-report/${report.id}/export/?format=pdf`}
                    target={"_blank"}
                    download={`${report.block_name}-${dayjs(report.created_at)
                      .tz()
                      .format("YYYY-MMM")}.pdf`}
                    icon={"download"}
                    size={"small"}
                    floated={"right"}
                  />
                  <Button
                    onClick={() => togglePublished(report)}
                    icon={report.published ? "lock" : "unlock"}
                    title={
                      report.published
                        ? t("labels.published")
                        : t("labels.not_published")
                    }
                    size={"small"}
                    floated={"right"}
                    {...(report.published ? { color: "green" } : {})}
                  />
                  {!report.published && (
                    <RoleRequired
                      admin={true}
                      permission={permissions.billingTransactionCreate}
                    >
                      <TransactionCreate
                        block={this.props.block}
                        restrictMonthDate={dayjs(report.date).tz().toDate()}
                        trigger_props={{ basic: false, size: "small" }}
                        initialData={{
                          date: dayjs(report.date).tz().format("YYYY-MM-DD"),
                        }}
                      />
                    </RoleRequired>
                  )}
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === index}
                style={{ position: "relative", minHeight: "200px" }}
              >
                {details ? (
                  <React.Fragment>
                    <Grid padded columns="equal">
                      {[
                        {
                          date: details.start_date,
                          fundKey: "old_balance",
                          balance: details.balance.start_amount,
                          prepaid: details.prepaid.start_amount,
                          funds: details.per_fund,
                        },
                        {
                          date: details.end_date,
                          fundKey: "new_balance",
                          balance: details.balance.end_amount,
                          prepaid: details.prepaid.end_amount,
                          funds: details.per_fund,
                        },
                      ].map(({ date, fundKey, balance, prepaid, funds }, i) => (
                        <Grid.Column key={i} width={8}>
                          <Table stackable={false}>
                            <Table.Header>
                              <Table.Row active>
                                <Table.HeaderCell>
                                  {t("billing.balance_to")} {humanizeDate(date)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {funds.map((fund) => (
                                <Table.Row key={fund.fund}>
                                  <Table.Cell>{fund.fund}</Table.Cell>
                                  <Table.Cell textAlign={"right"}>
                                    {amountToBGN(fund[fundKey])}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                              <Table.Row>
                                <Table.Cell>
                                  <p>
                                    <b>{t("billing.total_per_floor")}</b>
                                  </p>
                                  <p>{t("billing.prepaid_fees")}</p>
                                </Table.Cell>
                                <Table.Cell textAlign={"right"}>
                                  <p>
                                    <b>{amountToBGN(balance)}</b>
                                  </p>
                                  <p>{amountToBGN(prepaid)}</p>
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Column>
                      ))}
                      <Grid.Column />
                    </Grid>
                    {this.renderTables(details, published)}
                  </React.Fragment>
                ) : (
                  <Loader active />
                )}
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    );
  };

  renderTables = (details, published) => {
    const { error, loading } = this.props;
    const genTable = (headers, rows, stats) =>
      rows.length === 0 ? (
        <Tab.Pane>{t("labels.none")}</Tab.Pane>
      ) : (
        <Tab.Pane>
          <StyledTable stackable={false}>
            <Table.Header>
              <Table.Row active>
                {headers.map((h, idx) => {
                  if (typeof h === "function") {
                    return <Fragment key={idx}>{h()}</Fragment>;
                  }
                  return (
                    <Table.HeaderCell
                      key={idx}
                      textAlign={idx === headers.length - 1 ? "right" : "left"}
                    >
                      {h}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows.map((values, i) => (
                <Table.Row key={i}>
                  {values.map((value, valueIdx) => {
                    if (typeof value === "function") {
                      return <Fragment key={valueIdx}>{value()}</Fragment>;
                    }
                    return (
                      <Table.Cell
                        key={valueIdx}
                        textAlign={
                          valueIdx === values.length - 1 ? "right" : "left"
                        }
                      >
                        {value}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={rows[0].length}
                  style={{ padding: "0 0" }}
                >
                  <Grid
                    style={{
                      margin: "5px 0",
                      maxWidth: "500px",
                      float: "right",
                    }}
                    columns={"equal"}
                  >
                    {stats.map(([k, v]) => (
                      <Grid.Row key={k} style={{ padding: "2px 0" }}>
                        <Grid.Column floated={"right"}>{k}</Grid.Column>
                        <Grid.Column
                          textAlign={"right"}
                          floated={"right"}
                          width={6}
                          style={{ paddingRight: ".78571429em" }}
                        >
                          {v}
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </StyledTable>
        </Tab.Pane>
      );

    return (
      <Tab
        panes={[
          {
            menuItem: t("labels.incomes"),
            render: () =>
              genTable(
                [
                  `${t("labels.direction")}`,
                  `${t("billing.obligations_to")} ${humanizeDate(
                    details.start_date
                  )}`,
                  `${t("billing.accrued_period")}`,
                  `${t("billing.collected_contr")}`,
                  `${t("billing.other_incomes")}`,
                  `${t("billing.obligations_to")} ${humanizeDate(
                    details.end_date
                  )}`,
                ],
                details.collections.per_fund.map((entry) => [
                  entry.fund_name,
                  amountToBGN(entry.old_charges),
                  amountToBGN(entry.charged),
                  amountToBGN(entry.collected),
                  amountToBGN(entry.other_income),
                  amountToBGN(entry.remaining),
                ]),
                [
                  [
                    t("billing.collection_remaining"),
                    amountToBGN(details.collections.total.remaining),
                  ],
                  [
                    t("billing.collected_contr"),
                    amountToBGN(details.collections.total.collected),
                  ],
                  [
                    t("billing.other_incomes"),
                    amountToBGN(details.income.other),
                  ],
                  [
                    t("billing.total_income"),
                    amountToBGN(details.income.total),
                  ],
                ]
              ),
          },
          {
            menuItem: t("labels.expenses"),
            render: () =>
              genTable(
                [
                  t("labels.source"),
                  t("labels.date"),
                  t("labels.description"),
                  () => (
                    <Table.HeaderCell textAlign={"right"} key={"amount1"}>
                      {t("labels.amount")}
                    </Table.HeaderCell>
                  ),
                  () =>
                    !published && (
                      <RoleRequired
                        admin={true}
                        permission={permissions.billingTransactionEdit}
                      >
                        <Table.HeaderCell collapsing={true} key={"mock"} />
                      </RoleRequired>
                    ),
                  () =>
                    !published && (
                      <RoleRequired
                        admin={true}
                        permission={permissions.billingTransactionDelete}
                      >
                        <Table.HeaderCell collapsing={true} key={"mock1"} />
                      </RoleRequired>
                    ),
                ],
                details.expense.transactions.map((entry) => [
                  entry.source_name,
                  dayjs(entry.transaction_date).tz().format("YYYY-MM-DD"),
                  entry.description,
                  () => (
                    <Table.Cell textAlign={"right"} key={"amount"}>
                      {amountToBGN(entry.amount)}
                    </Table.Cell>
                  ),

                  () =>
                    !published && (
                      <RoleRequired
                        admin={true}
                        permission={permissions.billingTransactionEdit}
                      >
                        <Table.Cell
                          key="control1"
                          textAlign={"right"}
                          collapsing={true}
                          horizontal={"true"}
                        >
                          <TransactionEdit entity={entry} />
                        </Table.Cell>
                      </RoleRequired>
                    ),
                  () =>
                    !published && (
                      <RoleRequired
                        admin={true}
                        permission={permissions.billingTransactionDelete}
                      >
                        <Table.Cell
                          key="control2"
                          textAlign={"right"}
                          collapsing={true}
                          horizontal={"true"}
                        >
                          <CancelTransactionForm
                            entity={entry}
                            error={ErrorWrap.fromError(error)}
                            loading={loading}
                            action={this.props.cancelTransaction}
                          />
                        </Table.Cell>
                      </RoleRequired>
                    ),
                ]),
                [
                  ...details.expense.per_fund.map((entry) => [
                    `${t("billing.total_expense")} ${entry.fund}`,
                    amountToBGN(entry.amount),
                  ]),
                  [
                    t("billing.total_expense"),
                    amountToBGN(details.expense.total),
                  ],
                ]
              ),
          },
        ]}
      />
    );
  };

  render() {
    const { reports, loading, togglePublished, error, clearError } = this.props;
    const errorWrap = new ErrorWrap(error);

    if (loading) {
      return <Loader active={true} />;
    }

    return (
      <Grid padded columns="equal">
        <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <h5>{t("billing.tills")}</h5>
            {this.renderFunds()}
          </Grid.Column>
          <Grid.Column computer={8} tablet={16}>
            <h5>{t("billing.reports")}</h5>
            <ReportDownloadForm block={this.props.block} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Suspense fallback={<Loader />}>
                <h5>{t("billing.balances_per_month")}</h5>
                <MonthlyReportLineChart block={this.props.block} />
              </Suspense>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h5>{t("billing.monthly_reports")}</h5>
            {errorWrap.exists() && (
              <Message
                error
                content={errorWrap.getGlobal()}
                onDismiss={() => clearError()}
              />
            )}
            {this.renderReports()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected,
    reports: state.billingMonthlyReport.entities,
    loading: state.billingMonthlyReport.pending,
    error: state.billingMonthlyReport.error,
    lastModifiedTransaction: state.fundTransaction.lastModified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      listMonthlyReports,
      togglePublished,
      clearError: manager.actions.clearError,
      cancelTransaction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockBillingPage);
