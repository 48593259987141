import styled from "styled-components";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { Remarkable } from "remarkable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBlock } from "../../../redux/modules/blocks";
import { ErrorWrap } from "../../../helpers/error";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";
import { t } from "../../../constant/translations";

const md = new Remarkable();

const StyledSegment = styled(Segment)`
  min-height: 80px;
`;

const BlockInfoComponent = ({ block, updateBlock, loading, error }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorOpen, setEditorOpen] = useState(false);

  useEffect(() => {
    setEditorState(getEditorState());
  }, []);

  useEffect(() => {
    if (!loading && !error.exists()) {
      setEditorState(getEditorState());
      setEditorOpen(false);
    }
  }, [loading, error]);

  const getEditorState = () => {
    const blockInfo = block.config.info;
    const content = convertFromRaw(markdownToDraft(blockInfo));
    return EditorState.createWithContent(content);
  };

  const getMarkdown = () => {
    return draftToMarkdown(convertToRaw(editorState.getCurrentContent()), {
      remarkableOptions: {
        preserveNewlines: true,
      },
    });
  };

  const updateBlockInfo = () => {
    block.config.info = getMarkdown();
    updateBlock(block);
  };

  const content = getMarkdown();

  return (
    <StyledSegment>
      {editorOpen ? (
        <div>
          {error.exists() && error.getGlobal()}

          <Button
            floated="right"
            icon={"close"}
            size={"tiny"}
            basic={true}
            disabled={loading}
            onClick={() => setEditorOpen(false)}
          />
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorStyle={{
              borderRadius: "2px",
              border: "1px solid #f1f1f1",
              padding: "6px 5px 0",
              marginBottom: "5px",
            }}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "list",
                "link",
                "emoji",
                "remove",
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
          <Button loading={loading} onClick={updateBlockInfo}>
            {t("labels.save")}
          </Button>
        </div>
      ) : (
        <div>
          <RoleRequired staff={true} permission={permissions.boardEdit}>
            <Button
              floated="right"
              icon={"edit"}
              size={"tiny"}
              basic={true}
              onClick={() => setEditorOpen(true)}
            />
          </RoleRequired>
          <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
        </div>
      )}
    </StyledSegment>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.blocks.pending,
    error: ErrorWrap.fromError(state.blocks.error),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateBlock,
    },
    dispatch
  );
}

export const BlockInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockInfoComponent);
