export function genDeps(dependencyName) {
  const name = dependencyName;
  const prefix = `multideps/${dependencyName}/DEPS_`;
  const DEPS_PENDING = prefix + "PENDING";
  const DEPS_SUCCESS = prefix + "SUCCESS";
  const DEPS_ERROR = prefix + "ERROR";

  const initialState = {
    pending: false,
    loaded: false,
    error: null,
  };

  function reducer(state = initialState, action) {
    switch (action.type) {
      case DEPS_PENDING:
        return { ...state, pending: true, loaded: false, error: null };
      case DEPS_SUCCESS:
        return {
          ...state,
          pending: false,
          loaded: true,
          error: getFirstError(action.result),
        };
      case DEPS_ERROR:
        return { ...state, pending: false, loaded: false, error: action.error };
      default:
        return state;
    }
  }

  function loadDeps(getPromises) {
    return (dispatch) => {
      dispatch({ type: DEPS_PENDING });
      const promises = getPromises();
      return Promise.all(promises.map(dispatch)).then(
        (result) => dispatch({ type: DEPS_SUCCESS, result }),
        (error) => dispatch({ type: DEPS_ERROR, error })
      );
    };
  }

  return { name, reducer, loadDeps };
}

function getFirstError(result) {
  for (const promise of result) {
    if (promise.error) {
      // Return the first error
      return promise.error;
    }
  }
  return null;
}
