import React from "react";
import { Loader, Segment, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { amountToBGN } from "../../../helpers/currency";
import { bindActionCreators } from "redux";
import { fetchFunds } from "../../../redux/modules/fund";
import { ErrorWrap } from "../../../helpers/error";
import { RoleRequired } from "../../auth/role-required";
import { FundEdit } from "./edit";
import { permissions } from "../../../constant/permissions";
import { t } from "../../../constant/translations";

export class FundListComponent extends React.Component {
  componentDidMount() {
    this.props.fetchFunds(this.props.block.id);
  }

  render() {
    const { funds, block } = this.props;
    if (!funds) {
      return <Loader active={true} />;
    }
    return (
      <Segment>
        <Table unstackable={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell positive>{t("labels.name")}</Table.HeaderCell>
              <RoleRequired
                staff={true}
                permission={permissions.billingAccountView}
              >
                <Table.HeaderCell positive>
                  {t("fund.balance")}
                </Table.HeaderCell>
              </RoleRequired>
              <Table.HeaderCell
                collapse={"true"}
                permission={permissions.billingAccountAdd}
              >
                <RoleRequired
                  admin={true}
                  permission={permissions.billingAccountAdd}
                >
                  <FundEdit block={block} header={t("fund.create_fund")} />
                </RoleRequired>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {funds.map((fund) => (
              <Table.Row key={`balance-${fund.name}`}>
                <Table.Cell>{fund.name}</Table.Cell>
                <RoleRequired
                  staff={true}
                  permission={permissions.billingAccountView}
                >
                  <Table.Cell>{amountToBGN(fund.balance)}</Table.Cell>
                </RoleRequired>
                <Table.Cell>
                  <RoleRequired
                    admin={true}
                    permission={permissions.billingAccountEdit}
                  >
                    <FundEdit
                      block={block}
                      entity={fund}
                      header={t("fund.change_of") + ` ${fund.name}`}
                    />
                  </RoleRequired>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    funds: state.fund.entities,
    error: ErrorWrap.fromError(state.fund.error),
    loading: state.fund.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFunds,
    },
    dispatch
  );
}

export const FundList = connect(
  mapStateToProps,
  mapDispatchToProps
)(FundListComponent);
