import React, { Fragment } from "react";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import { amountToBGN } from "../../../helpers/currency";
import { t } from "../../../constant/translations";

export const TotalTable = (props) => {
  let rows = props.data.funds.map((x) => {
    return {
      fund: x || "",
      current: props.data.per_fund[x].current || 0,
      overdue: props.data.per_fund[x].overdue || 0,
      total: props.data.per_fund[x].total || 0,
    };
  });
  return (
    <Fragment>
      <h5>{t("charge.total")}</h5>
      <DataTable data={rows}>
        <DataTableColumn name={t("charge.fund")} render={(el) => el.fund} />
        <DataTableColumn
          name={t("charge.current_capital")}
          render={(el) => amountToBGN(el.current)}
        />
        <DataTableColumn
          name={t("charge.overdue_capital")}
          render={(el) => amountToBGN(el.overdue)}
        />
        <DataTableColumn
          name={t("charge.total")}
          align="right"
          render={(el) => amountToBGN(el.total)}
        />
      </DataTable>
    </Fragment>
  );
};
