import styled from "styled-components";
import React, { Component } from "react";
import { Divider, Menu } from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import Sidebar from "react-sidebar";
import { Logo } from "../../../components/common/logo.js";
import { RoleRequired } from "../../auth/role-required";
import { reverse, routes } from "../../../routes";
import { permissions } from "../../../constant/permissions";
import { NewBlock } from "../../block/new";
import { ViberModal } from "../../../components/common/viber-modal";
import { t } from "../../../constant/translations.js";

const DEFAULT_STYLE = {
  sidebar: {
    backgroundColor: "rgb(244, 244, 244)",
    zIndex: 904,
    overflow: "auto",
    overflowX: "hidden",
  },
  overlay: {
    zIndex: 900,
  },
};

const StyledMenuHeader = styled(Menu.Header)`
  text-align: center;
`;

const Bottom = styled.div`
  margin-top: auto;
  padding: 1.5em 1em;
`;

const NewBlockItem = styled(Menu.Item)`
  margin: 0 5px !important;
`;

const SidebarContent = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-content: space-between;
`;

export class SideMenu extends Component {
  state = {};

  renderGlobal = (hasMany) => {
    let global = [
      <RoleRequired key={"g0-role"} managerOnly={true}>
        <Link activeClassName="active" className="item" to={routes.manage}>
          {t("side.management")}
        </Link>
      </RoleRequired>,
    ];
    if (hasMany) {
      global = global.concat([
        <Link
          key={"g1"}
          activeClassName="active"
          className="item"
          to={routes.blockList}
        >
          {t("side.locations")}
        </Link>,
        <RoleRequired
          staff={true}
          key={"g2-role1"}
          permission={permissions.estateGlobalView}
        >
          <Link
            key={"g2"}
            activeClassName="active"
            className="item"
            to={routes.estateList}
          >
            {t("side.properties")}
          </Link>
        </RoleRequired>,
        <RoleRequired
          admin={true}
          key={"g5-role"}
          permission={permissions.ticketGlobalView}
        >
          <Link
            key={"g5"}
            activeClassName="active"
            className="item"
            to={routes.tickets}
          >
            {t("side.signals")}
          </Link>
        </RoleRequired>,
      ]);
    }
    return global;
  };

  renderSelected = (hasMany) => {
    let selected = this.props.selected;
    if (!selected && !hasMany && this.props.blocks) {
      selected = this.props.blocks[0];
    }
    if (!selected) {
      return null;
    }

    const blockId = selected.id;
    let components = [];
    if (hasMany) {
      components.push(<Divider key={"divider-key"} />);
      components.push(
        <StyledMenuHeader key={"menu"}>{selected.name}</StyledMenuHeader>
      );
    }

    components.push(
      ...[
        <Link
          key={1}
          activeClassName="active"
          className="item"
          to={reverse(routes.blockDetails, { blockId })}
        >
          {t("side.dashboard")}
        </Link>,
        <Link
          key={2}
          activeClassName="active"
          className="item"
          to={reverse(routes.blockEstateList, { blockId })}
        >
          {t("side.properties")}
        </Link>,
        <RoleRequired
          staff={true}
          key={"g2"}
          permission={permissions.customerView}
        >
          <Link
            key={3}
            activeClassName="active"
            className="item"
            to={reverse(routes.blockProfiles, { blockId })}
          >
            {t("side.residents")}
          </Link>
        </RoleRequired>,
        <Link
          key={4}
          activeClassName="active"
          className="item"
          exact={false}
          to={reverse(routes.blockBilling, { blockId })}
        >
          {t("side.accounting")}
        </Link>,
        <Link
          key={7}
          activeClassName="active"
          className="item"
          to={reverse(routes.blockTickets, { blockId })}
        >
          {t("side.signals")}
        </Link>,
      ]
    );
    return components;
  };

  render() {
    const { children, visible, docked, shadow, toggleVisibility } = this.props;
    const hasBlocks = this.props.blocks.length > 0;
    const hasMany = this.props.blocks.length > 1;
    const sidebar = (
      <SidebarContent>
        <Menu vertical secondary>
          <Logo />
          <RoleRequired staff={true} permission={permissions.blockAdd}>
            <NewBlockItem>
              <NewBlock />
            </NewBlockItem>
          </RoleRequired>
          <Menu.Item>
            {this.renderGlobal(hasMany)}
            {hasBlocks && this.renderSelected(hasMany)}
          </Menu.Item>
        </Menu>
        <Bottom>
          <ViberModal />
        </Bottom>
      </SidebarContent>
    );

    return (
      <Sidebar
        sidebar={sidebar}
        open={visible}
        onSetOpen={toggleVisibility}
        docked={docked}
        shadow={shadow}
        styles={DEFAULT_STYLE}
      >
        {children}
      </Sidebar>
    );
  }
}
