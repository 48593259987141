import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Transaction } from "../../../redux/modules/transaction";
import { fetchTransactions } from "../../../redux/modules/transaction";
import type { Settlement } from "../../../redux/modules/settlement";
import { Button, Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import dayjs from "dayjs";
import { PrintReceiptButton } from "../../../components/billing/print-receipt-button";
import { Print } from "react-easy-print";
import { isAndroid } from "react-device-detect";
import { amountToBGN } from "../../../helpers/currency";
import { formatDate } from "../../../helpers/date";
import { SettlementCancelTransactionForm } from "./settlement-cancel-transaction";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";
import { t } from "../../../constant/translations";

type BreakdownListProps = {
  transactions: Transaction[],
  settlement: Settlement,
};

const BreakdownContainer = styled.div`
  ul {
    list-style: none;
  }
`;

const TransactionContainer = styled.div`
  > div {
    margin: 10px 0;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

function TransactionItem(props) {
  const transaction: Transaction = props.transaction;
  const charge = transaction.charge;
  const chargeDetails = charge.details;
  return (
    <Segment>
      <RoleRequired
        admin={true}
        permission={permissions.billingSettlementDelete}
      >
        <SettlementCancelTransactionForm
          entity={transaction}
          settlementId={props.settlementId}
        />
      </RoleRequired>
      {t("resources.charge_to")} {charge.fund} {t("charge.from_date")}{" "}
      {formatDate(charge.charge_date)}
      <br />
      {chargeDetails.map((details, idx) => {
        return (
          <div key={idx}>
            <span id={idx}>
              {details.model} -{" "}
              <BoldText>{amountToBGN(details.amount)}</BoldText>
            </span>
            <br />
          </div>
        );
      })}
      <br />
      <BoldText>
        {" "}
        {t("settlement.paid")}: {amountToBGN(transaction.amount)}
      </BoldText>
    </Segment>
  );
}

const BreakdownList = (props: BreakdownListProps) => {
  const settlement = props.settlement;
  const transactions = props.transactions
    .filter((t) => t.charge !== null)
    .sort((t1, t2) => {
      return dayjs(t1.charge.charge_date) - dayjs(t2.charge.charge_date);
    });
  return (
    <BreakdownContainer>
      <div>
        <h4>{t("settlement.payment_info")}</h4>

        <ul>
          <li>
            {t("settlement.payment_number")}: {settlement.id}{" "}
          </li>
          <li>
            {t("labels.date")}: {settlement.settle_date}{" "}
          </li>
          <li>
            {t("resources.estate_capital")}: {settlement.block_details.name} -{" "}
            {settlement.estate_details.name}
          </li>
          <li>
            {t("labels.client_number")}: {settlement.estate_details.vendor_id}
          </li>
        </ul>
        <ul>
          <li>
            {t("labels.deposited_amount")}:{" "}
            {amountToBGN(settlement.deposit_amount)}
          </li>
          <li>
            {t("titles.duties_paid")}: {amountToBGN(settlement.settled_amount)}
          </li>
          <li>
            {t("labels.prepaid_amount")}:{" "}
            {amountToBGN(settlement.prepaid_amount)}
          </li>
        </ul>
      </div>
      {transactions && transactions.length > 0 && (
        <TransactionContainer>
          <h4>{t("titles.duties_paid")}</h4>
          {transactions.map((transaction) => (
            <TransactionItem
              transaction={transaction}
              key={transaction.id}
              settlementId={settlement.id}
            />
          ))}
        </TransactionContainer>
      )}
    </BreakdownContainer>
  );
};

type SettlementBreakdownProps = {
  transactions: ?(Transaction[]),
  settlement: Settlement,
  loading: boolean,
  error: Object,
  fetchTransactions: Function,
  onClose: Function,
};

export class SettlementBreakdownComponent extends Component<SettlementBreakdownProps> {
  componentDidMount() {
    this.props.fetchTransactions(
      {
        settlement: this.props.settlement.id,
        page_size: 400,
      },
      true
    );
  }

  render() {
    const { transactions, settlement, loading, error, onClose } = this.props;
    if (loading) {
      return (
        <Dimmer page={true} active={true}>
          <Loader />
        </Dimmer>
      );
    }

    return (
      <Modal defaultOpen={true} onClose={onClose} closeIcon={true}>
        <Modal.Header>
          {t("settlement.payment_to")} {settlement.block_details.name} -{" "}
          {settlement.estate_details.name}
        </Modal.Header>
        <Modal.Content>
          <Print name={"settlement"}>
            <BreakdownList
              transactions={transactions}
              settlement={settlement}
            />
          </Print>
        </Modal.Content>
        <Modal.Actions>
          {isAndroid && (
            <PrintReceiptButton
              settlement={settlement}
              transactions={transactions}
            />
          )}
          <Button onClick={onClose}>{t("labels.close")}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transaction.entities || [],
    error: state.transaction.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTransactions }, dispatch);
}

export const SettlementBreakdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementBreakdownComponent);
