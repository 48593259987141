import React, { Component } from "react";
import { Loader } from "semantic-ui-react";

export const Loading = (WrappedComponent) => {
  class Wrapper extends Component {
    render() {
      if (this.props.loading) {
        return <Loader active />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return Wrapper;
};
