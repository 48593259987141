import dayjs from "dayjs";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Button,
  Comment,
  CommentGroup,
  Container,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";

import { Avatar } from "../../components/common/avatar";
import { TicketEdit } from "./edit";
import { UserFile } from "./user-file";
import { TicketEditComment } from "./edit-comment";
import { ErrorWrap } from "../../helpers/error";
import { BackButton } from "../common/back-button";
import { RoleRequired } from "../auth/role-required";
import { ConfirmModalAction } from "../../components/common/confirm-modal";

import type Ticket from "../../redux/modules/ticket";
import { deleteTicket, getTicket } from "../../redux/modules/ticket";
import {
  createTicketComment,
  deleteTicketComment,
  fetchTicketComments,
  updateTicketComment,
} from "../../redux/modules/ticketComment";

import { ROLE_ADMIN, ROLE_STAFF } from "../../config";
import { t } from "../../constant/translations";
import styled from "styled-components";
import type Profile from "../../redux/modules/profile";
import { FilePreview } from "../common/file-preview";
import { Markdown } from "../../components/common/markdown";
import { permissions } from "../../constant/permissions";

type Props = {
  profiles: ?Array<Profile>,
  currentUser: Object,
  estateCustomers: ?Array,

  tickets: ?Array<Ticket>,
  ticketsLoading: boolean,
  ticketsError: Object,

  ticketComments: ?Array,
  ticketCommentsLoading: boolean,
  ticketCommentsError: Object,

  fetchDeps: Function,
  deleteTicket: Function,
  createTicketComment: Function,
  updateTicketComment: Function,
  deleteTicketComment: Function,
};

const StyledComment = styled(Comment)`
  clear: both;
  min-height: 60px;
`;

class TicketDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentFiles: [],
    };
  }

  getTicketId() {
    return parseInt(this.props.match.params.ticketId);
  }

  componentDidMount() {
    this.props.fetchDeps(this.getTicketId());
  }

  handleAttachCommentFiles = (_, { value }) => {
    this.setState({ commentFiles: value });
  };

  handleSubmitComment = (e) => {
    const data = new FormData(e.target);
    const content = data.get("comment").trim();
    const files = this.state.commentFiles;

    if (!content) return;
    e.target.reset();

    this.setState({ commentFiles: [] });
    this.props.createTicketComment({
      ticket: this.getTicketId(),
      files,
      content,
    });
  };

  render() {
    const { currentUser, tickets, ticketsLoading } = this.props;
    if (!tickets || ticketsLoading) {
      return <Loader active />;
    }
    const ticketId = this.getTicketId();
    const ticket = tickets.find((ticket) => ticket.id === ticketId);

    if (!ticket) return null;
    const { author, assignee } = ticket;

    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={6} tablet={16}>
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  <BackButton />
                  {author.id === currentUser.id && ticket.state !== "open" ? (
                    <TicketEdit
                      entity={ticket}
                      trigger_props={{ floated: "right" }}
                    />
                  ) : (
                    <RoleRequired
                      staff={true}
                      permission={permissions.ticketEdit}
                    >
                      <TicketEdit entity={ticket} />
                    </RoleRequired>
                  )}
                  <Segment.Group>
                    <Segment>
                      <Header
                        as="h4"
                        content={assignee.name}
                        subheader={t("ticketUserTypes.assignee")}
                      />
                      <Header
                        as="h4"
                        content={author.name}
                        subheader={t("labels.author")}
                      />
                    </Segment>
                  </Segment.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column largeScreen={12} computer={10} tablet={16}>
            <Grid padded>
              <Grid.Row>
                <Grid.Column>{this.renderTicket(ticket)}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" dividing>
                    {t("labels.comments")}
                  </Header>
                  {this.renderComments()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderTicket(ticket) {
    return (
      <Fragment>
        <Header
          as="h2"
          subheader={t(`ticketTypes.${ticket.ticket_type}`)}
          content={ticket.title}
        />

        <Segment>
          <Markdown content={ticket.description} />
        </Segment>
        {ticket.files.map((file) => {
          return <FilePreview file={file} />;
        })}
      </Fragment>
    );
  }

  renderComments() {
    const {
      tickets,
      profiles,
      currentUser,
      ticketComments,
      ticketCommentsLoading,
      ticketCommentsError,
    } = this.props;

    const ticketId = this.getTicketId();
    const ticket = tickets.find((ticket) => ticket.id === ticketId);
    const { author } = ticket;

    if (!ticketComments) {
      return <Loader active />;
    }

    return (
      <React.Fragment>
        {ticketComments.length === 0 && (
          <Message content={t("ticket.no_comments")} />
        )}
        <CommentGroup>
          {ticketComments.map((comment) => {
            const author = comment.author;
            const actions = (
              <React.Fragment>
                <Comment.Action>
                  <TicketEditComment
                    entity={comment}
                    customTrigger={{ text: t("userActions.edit") }}
                  />
                </Comment.Action>
                <Comment.Action>
                  <ConfirmModalAction
                    header={t("ticket.delete_comment")}
                    content={
                      <React.Fragment>
                        {t("ticket.confirm_deletion")}:
                        <br />
                        {comment.content}
                      </React.Fragment>
                    }
                    error={this.props.ticketCommentsError}
                    loading={this.props.ticketCommentsLoading}
                    action={() => this.props.deleteTicketComment(comment)}
                    confirmLabel={t("userActions.delete")}
                    useTextTrigger={true}
                    textTriggerText={t("userActions.delete")}
                  />
                </Comment.Action>
              </React.Fragment>
            );
            return (
              <StyledComment key={comment.id}>
                <Avatar
                  as={Comment.Avatar}
                  emailHash={author.email_hash}
                  src={author.profile_picture}
                />
                <Comment.Content>
                  <Comment.Author as="a">{author.name}</Comment.Author>{" "}
                  {ticket.assignee === comment.author && (
                    <u>({t("ticketUserTypes.assignee").toLowerCase()})</u>
                  )}
                  {comment.deleted_at ? (
                    <Comment.Metadata>
                      {t("ticket.comment_deleted")}
                    </Comment.Metadata>
                  ) : (
                    <React.Fragment>
                      <Comment.Metadata>
                        {dayjs(comment.created_at).fromNow()}{" "}
                        <div>
                          {
                            // Don't show `updated` message if comment is edited
                            // within 1 minute of creation.
                            dayjs(comment.updated_at).diff(
                              dayjs(comment.created_at),
                              "minutes"
                            ) > 1 && <b>({t("labels.edited").toLowerCase()})</b>
                          }
                        </div>
                      </Comment.Metadata>
                      <Comment.Text>
                        <p>{comment.content}</p>
                        {comment.files.map((file) => {
                          return (
                            <div key={file.uuid}>
                              <FilePreview file={file} />
                            </div>
                          );
                        })}
                      </Comment.Text>
                      <Comment.Actions>
                        {author.id === currentUser.id ? (
                          actions
                        ) : (
                          <RoleRequired
                            staff={true}
                            permission={permissions.ticketEdit}
                          >
                            {actions}
                          </RoleRequired>
                        )}
                      </Comment.Actions>
                    </React.Fragment>
                  )}
                </Comment.Content>
              </StyledComment>
            );
          })}
        </CommentGroup>
        {author.id === currentUser.id ||
        currentUser.role === ROLE_STAFF ||
        currentUser.role === ROLE_ADMIN ? (
          <Form
            reply
            onSubmit={this.handleSubmitComment}
            loading={ticketCommentsLoading}
            error={ticketCommentsError.exists()}
          >
            {ticketCommentsError.exists() && (
              <Message
                error
                header={t("error.error_occurred")}
                content={ticketCommentsError.getGlobal()}
              />
            )}
            <Form.TextArea name="comment" />
            <Form.Field>
              <UserFile
                name="files"
                value={this.state.commentFiles}
                onChange={this.handleAttachCommentFiles}
              />
            </Form.Field>
            <Button
              content={t("userActions.respond")}
              labelPosition="left"
              icon="edit"
              primary
            />
          </Form>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected.id,
    profiles: state.profile.entities,
    currentUser: state.auth.user,
    estateCustomers: state.estatecustomers.entities,

    tickets: state.ticket.entities,
    ticketsLoading: state.ticket.pending,
    ticketsError: ErrorWrap.fromError(state.ticket.error),

    ticketComments: state.ticketComment.entities,
    ticketCommentsLoading: state.ticketComment.pending,
    ticketCommentsError: ErrorWrap.fromError(state.ticketComment.error),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDeps: (ticketId) => (dispatch) =>
        Promise.all([
          dispatch(getTicket(ticketId)),
          dispatch(fetchTicketComments(ticketId)),
        ]),
      deleteTicket,
      createTicketComment,
      updateTicketComment,
      deleteTicketComment,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TicketDetails)
);
