import React, { useEffect, useState } from "react";
import { Label, Loader, Menu } from "semantic-ui-react";
import { TicketEdit } from "./edit";
import { RoleRequired } from "../auth/role-required";
import { apirequest } from "../../helpers/api-client";
import { useQueryParams } from "use-query-params";
import qs from "qs";
import { FilterButton } from "../../components/common/filter-button";
import { ticketQueryFilterParams } from "./content-filter";
import { permissions } from "../../constant/permissions";
import {
  determinePage,
  PageNavigationSimple,
} from "../../components/common/page-navigation";
import { t } from "../../constant/translations";

const TicketStatisticsComponent = ({ block, meta }) => {
  const [stats, setStats] = useState({ loading: true });
  const [query, setQueryRaw] = useQueryParams(ticketQueryFilterParams);
  const setQuery = (q) => setQueryRaw(q, "replace");

  const hasQuery = Object.values(query).filter((e) => e).length > 0;
  const hasCustomFilter = query.q || query.created_after || query.ticket_type;

  useEffect(() => {
    const effect = async (): void => {
      const params = qs.stringify(
        { block: block && block.id },
        { addQueryPrefix: true }
      );
      const result = await apirequest(
        "get",
        "/api/reports/ticket-summary/" + params
      );
      setStats(result.body);
    };
    effect();
  }, []);

  if (stats.loading) {
    return <Loader active={true} inline={true} />;
  }

  return (
    <React.Fragment>
      <Menu.Item
        onClick={() => setQuery({ status: null, assignee: null })}
        active={!hasQuery}
      >
        {t("ticket.new")}
        <Label>{stats.open || 0}</Label>
      </Menu.Item>
      <RoleRequired staff={true} permission={permissions.ticketEdit}>
        <Menu.Item
          onClick={() => setQuery({ assignee: "me", status: null })}
          active={!hasCustomFilter && query.assignee === "me"}
        >
          {t("ticket.assigned_to_me")}
          <Label>{stats.assigned_to_me || 0}</Label>
        </Menu.Item>
      </RoleRequired>
      <Menu.Item
        onClick={() => setQuery({ status: "in_progress", assignee: null })}
        active={!hasCustomFilter && query.status === "in_progress"}
      >
        {t("ticket.in_progress")}
        <Label>{stats.in_progress || 0}</Label>
      </Menu.Item>
      <Menu.Item
        onClick={() => setQuery({ status: "closed", assignee: null })}
        active={!hasCustomFilter && query.status === "closed"}
      >
        {t("ticket.completed")}
        <Label>{stats.closed || 0}</Label>
      </Menu.Item>
      <Menu.Item
        name={t("ticketStates.all")}
        onClick={() => setQuery({ status: "all", assignee: null })}
        active={!hasCustomFilter && query.status === "all"}
      >
        {t("resources.null")}
        <Label>{stats.total || 0}</Label>
      </Menu.Item>
      {hasCustomFilter && (
        <Menu.Item active={true}>
          {t("ticket.filtered")}
          <Label as="a" onClick={() => setQuery({})}>
            X
          </Label>
        </Menu.Item>
      )}
    </React.Fragment>
  );
};

export const TicketTopMenu = ({ globalView, block, openFilter, meta }) => {
  const [next, previous] = determinePage(meta);
  return (
    <Menu stackable={true} size={"tiny"}>
      <Menu.Item>
        <PageNavigationSimple next={next} previous={previous} />
      </Menu.Item>
      {globalView ? (
        <TicketStatisticsComponent meta={meta} />
      ) : (
        <TicketStatisticsComponent block={block} meta={meta} />
      )}

      {!globalView && (
        <Menu.Item position={"right"}>
          <TicketEdit
            block={block}
            trigger_props={{
              content: t("block.signal"),
              color: "green",
              icon: false,
              size: "medium",
              basic: false,
              floated: "right",
            }}
          />
        </Menu.Item>
      )}
      {globalView && (
        <Menu.Item position={"right"}>
          <FilterButton onClick={openFilter} />
        </Menu.Item>
      )}
    </Menu>
  );
};
