import styled from "styled-components";
import React, { Fragment } from "react";
import { Checkbox, Form } from "semantic-ui-react";
import { NumberParam, StringParam, BooleanParam } from "use-query-params";
import { t } from "../../constant/translations";

export const estateListQueryFilterParams = {
  q: StringParam,
  min_debt: NumberParam,
  max_debt: NumberParam,
  min_unsettled: NumberParam,
  max_unsettled: NumberParam,
  has_debt: BooleanParam,
};

export const estateListQueryKeyMap = {
  has_debt: () => t("estate.has_debt"),
  min_debt: (value) => `${t("estate.unset_debt")} > ${value}`,
  max_debt: (value) => `${t("estate.unset_debt")} < ${value}`,
  min_unsettled: (value) => `${t("estate.count_debt")} > ${value}`,
  max_unsettled: (value) => `${t("estate.count_debt")} < ${value}`,
  q: (value) => value,
};

const FormLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const ContentFilterFormBody = ({ data, onChange }) => {
  return (
    <Fragment>
      <Form.Input
        icon="search"
        placeholder={t("estate.ap1")}
        label={t("charge.search_capital")}
        onChange={onChange("q")}
        value={data.q || ""}
        name={"search-estate"}
      />
      <Checkbox
        toggle
        onChange={onChange("has_debt")}
        checked={!!data["has_debt"]}
        name={"has_debt"}
        label={t("estate.has_debt")}
      />
      <FormLabel>{t("estate.unsettled_amount")}</FormLabel>
      <Form.Group>
        <Form.Input
          onChange={onChange("min_debt")}
          value={data["min_debt"]}
          name="min-debt"
          icon="money"
          type="number"
          placeholder={"1000"}
          width={10}
          label={t("estate.from")}
        />
        <Form.Input
          onChange={onChange("max_debt")}
          value={data["max_debt"]}
          name="max-debt"
          icon="money"
          type="number"
          placeholder={"50000"}
          width={10}
          label={t("estate.to")}
        />
      </Form.Group>
      <FormLabel>{t("estate.count_unpaid_debt")}</FormLabel>
      <Form.Group>
        <Form.Input
          onChange={onChange("min_unsettled")}
          value={data["min_unsettled"]}
          placeholder={"1"}
          type="number"
          width={10}
          label={t("estate.from")}
        />
        <Form.Input
          onChange={onChange("max_unsettled")}
          value={data["max_unsettled"]}
          placeholder={"20"}
          type="number"
          width={10}
          label={t("estate.to")}
        />
      </Form.Group>
    </Fragment>
  );
};
