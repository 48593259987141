import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";
import { Pagination } from "../../components/common/pagination";
import { permissions } from "../../constant/permissions";
import { sliceList } from "../../helpers/filter";
import {
  deleteBoardPost,
  fetchBoardPosts,
} from "../../redux/modules/board-post";
import { RoleRequired } from "../auth/role-required";
import { PostList } from "./list";
import { PostForm } from "./post-form";
import { t } from "../../constant/translations";

const StyledController = styled.div`
  display: flex;
  flex-shrink: 1;
  justify-content: space-between;
`;

const StyledNoMessage = styled.div`
  flex-grow: 1;
`;

export class Board extends React.Component {
  state = { currentPage: 1, pageSize: 1 };

  componentDidMount() {
    if (this.props.posts === null) {
      this.props.fetchBoardPosts(this.props.block.id);
    }
  }

  handlePaginate = (currentPage, pageSize) => {
    this.setState({ currentPage, pageSize });
  };

  render() {
    const { block, posts, deleteBoardPost } = this.props;
    const hasPosts = posts && posts.length !== 0;
    const page = sliceList(
      [...posts].sort((a, b) => a.created_at > b.created_at).reverse(),
      this.state.currentPage - 1,
      this.state.pageSize
    );
    return (
      <Segment>
        <StyledController>
          {hasPosts && posts.length > this.state.pageSize && (
            <Pagination
              current={this.state.currentPage}
              total={posts.length}
              onChange={this.handlePaginate}
              pageSize={this.state.pageSize}
            />
          )}
          {!hasPosts && (
            <StyledNoMessage>{t("board.no_new_msg")}</StyledNoMessage>
          )}
          <span />
          <div>
            <RoleRequired staff={true} permission={permissions.boardEditPost}>
              <PostForm
                initialData={{ block: block.id, files: [] }}
                header={t("board.new_message")}
              />
            </RoleRequired>
          </div>
        </StyledController>

        <PostList posts={page} deletePost={deleteBoardPost} />
      </Segment>
    );
  }
}

function mapState(state) {
  return { posts: state.boardpost.entities || [] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteBoardPost, fetchBoardPosts }, dispatch);
}

export default connect(mapState, mapDispatchToProps)(Board);
