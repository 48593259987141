import dayjs from "dayjs";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Message, Segment, Select } from "semantic-ui-react";
import { t } from "../../constant/translations";

import type { FormProps } from "../../components/common/edit-entity";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  createCharge,
  editCharge,
  deleteCharge,
} from "../../redux/modules/charge";

import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { withRefreshEstate } from "../../redux/modules/estates";
import { CalendarInput } from "../common/calendar-input";
import { Description } from "./charge-description";

function isSettled(entity) {
  return entity && entity.status === "settled";
}

export class ChargeEditComponent extends Component<FormProps> {
  state = { formData: null };

  render() {
    const { estate, error, entity } = this.props;
    const initialState = getInitialState({
      estate: estate.id,
      charge_date: dayjs().format("YYYY-MM-DD"),
      ...entity,
    });
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );

    const selectFundOptions = this.props.funds.map((p) => ({
      key: p.id,
      text: p.name,
      value: p.name,
    }));

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={
          !entity
            ? t("labels.new_obligation") + ` ${estate.name}`
            : t("labels.obligation_change") + ` ${estate.name}`
        }
        trigger_props={this.props.trigger_props}
      >
        {!isSettled(entity) ? (
          <>
            <Form.Field>
              <label>{t("titles.date_of_obligation")}</label>
              <CalendarInput {...manager.getAttributes("charge_date")} />
              {error.has("charge_date") && (
                <span>{error.get("charge_date")}</span>
              )}
            </Form.Field>
            <Form.Field>
              <label>{t("labels.direction")}</label>
              <Select
                placeholder={t("labels.select_direction")}
                options={selectFundOptions}
                {...manager.getAttributes("fund")}
              />
              {error.has("fund") && <span>{error.get("fund")}</span>}
            </Form.Field>
            <Form.Field>
              <label>{t("labels.amount")}</label>
              <Form.Input
                placeholder={t("labels.amount")}
                error={error.has("amount")}
                {...manager.getAttributes("amount")}
              />
              {error.has("amount") && <span>{error.get("amount")}</span>}
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                label={t("labels.description")}
                {...manager.getAttributes("description")}
              />
              {error.has("description") && (
                <span>{error.get("description")}</span>
              )}
            </Form.Field>
          </>
        ) : (
          <Message>{t("info.obligation_paid")}</Message>
        )}
        {entity && entity.details.length !== 0 ? (
          <Segment>
            {!isSettled(entity) ? (
              <Message>
                {t("info.obligation_generated1")}
                <br /> {t("info.obligation_generated2")}
              </Message>
            ) : null}
            <Description charge={entity} />
          </Segment>
        ) : null}
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    funds: state.blocks.selected.funds,
    charges: state.charge.entities,
    loading: state.charge.pending,
    error: ErrorWrap.fromError(state.charge.error),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      create: withRefreshEstate(createCharge, ownProps.estate.id),
      update: withRefreshEstate(editCharge, ownProps.estate.id),
      del: withRefreshEstate(deleteCharge, ownProps.estate.id),
      clearError,
    },
    dispatch
  );
}

export const ChargeEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargeEditComponent);
