import dayjs from "dayjs";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loader, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { TicketEdit } from "./edit";
import { RoleRequired } from "../auth/role-required";

import { t } from "../../constant/translations";
import type Ticket from "../../redux/modules/ticket";
import { fetchTickets } from "../../redux/modules/ticket";
import { reverse, routes } from "../../routes";
import { Badge } from "../../components/common/badge";
import { withSorting } from "../../helpers/sortby";
import { withRouter } from "react-router";
import { getUrlParams } from "../../components/common/urlparams";
import { permissions } from "../../constant/permissions";

type TicketListProps = {
  globalView?: boolean,
  tickets: ?Array<Ticket>,
  block: ?Object,
};

/**
 * This component implements conditional rendering for either
 * all tickets or tickets for the selected block.
 */
class TicketListComponent extends Component<TicketListProps> {
  componentDidMount(): void {
    this.loadDeps();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      this.loadDeps();
    }
  }

  loadDeps = () => {
    const { globalView, block, location } = this.props;
    const params = {
      ...getUrlParams(location),
    };
    if (Object.values(params).filter((e) => e).length === 0) {
      params.status = "open";
    }

    if (!globalView) {
      params.block = block.id;
    }
    this.props.fetchDependencies(params);
  };

  renderTickets = (tickets) => {
    return tickets.map((ticket, idx) => {
      const author = ticket.author;
      const assignee = ticket.assignee;
      return (
        <Table.Row key={`ticket-${idx}`}>
          <Table.Cell>
            {ticket.internal && (
              <Badge icon={"hide"} content={t("ticket.official")} />
            )}
            <Link
              to={reverse(routes.ticketDetails, {
                blockId: ticket.block,
                ticketId: ticket.id,
              })}
            >
              {ticket.title}
            </Link>
          </Table.Cell>
          {this.props.globalView && (
            <Table.Cell collapsing={true}>
              <Link
                to={reverse(routes.blockTickets, {
                  blockId: ticket.block,
                })}
              >
                {ticket.block_name}
              </Link>
            </Table.Cell>
          )}
          <Table.Cell collapsing={true}>{author.name}</Table.Cell>
          <Table.Cell collapsing={true}>
            {t(`ticketStates.${ticket.status}`)}
          </Table.Cell>
          <Table.Cell collapsing={true}>
            {dayjs(ticket.created_at).format("YYYY-MM-DD")}
          </Table.Cell>
          <Table.Cell collapsing={true}>{assignee.name} </Table.Cell>
          <RoleRequired staff={true} permission={permissions.ticketEdit}>
            <Table.Cell>
              <TicketEdit entity={ticket} block={this.props.block} />
            </Table.Cell>
          </RoleRequired>
        </Table.Row>
      );
    });
  };

  render() {
    const { tickets, sortColumn } = this.props;
    if (!tickets) {
      return <Loader active />;
    }
    return (
      <Table
        celled={true}
        striped={true}
        compact={true}
        unstackable={true}
        sortable={true}
        selectable={true}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell {...sortColumn("title")}>
              {t("labels.title")}
            </Table.HeaderCell>
            {this.props.globalView && (
              <Table.HeaderCell {...sortColumn("block__name")}>
                {t("labels.block")}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell {...sortColumn("author")}>
              {t("labels.author")}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortColumn("status")}>
              {t("labels.status")}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortColumn("created_at")}>
              {t("labels.date")}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortColumn("assignee")}>
              {t("ticketUserTypes.assignee")}
            </Table.HeaderCell>
            <RoleRequired staff={true} permission={permissions.ticketEdit}>
              <Table.HeaderCell collapsing={true} />
            </RoleRequired>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderTickets(tickets)}</Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected,
    tickets: state.ticket.entities,
    loading: state.ticket.pending,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      fetchDependencies: (params) => (dispatch) =>
        Promise.all([dispatch(fetchTickets(params))]),
    },
    dispatch
  );
};

const SortedListComponent = withRouter(withSorting(TicketListComponent));

export const TicketList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SortedListComponent);
