import { newApi } from "../restdux/index";

export const billingAttrManager = newApi(
  "billing-attribute",
  "/api/billing-attribute/"
);
export const reducer = billingAttrManager.reducer;

export function fetchBillingAttributes(estateId) {
  return billingAttrManager.actions.list({ estate: estateId });
}

export function fetchBillingAttributesForBlock({ blockId }) {
  return billingAttrManager.actions.list({
    block: blockId,
  });
}
