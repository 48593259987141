import React, { Component } from "react";
import styled from "styled-components";
import {
  estateBillDeps,
  loadEstateBillingDeps,
} from "../../../redux/modules/deps";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Preload } from "../../../redux/multideps/preload";
import { entityGet } from "../../../helpers/filter";
import { AttributeEdit } from "./edit";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import { amountToBGN } from "../../../helpers/currency";
import { t } from "../../../constant/translations";

const StyledEditContainer = styled.div`
  > button {
    margin-right: 0px !important;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  float: right;
`;

export class List extends Component {
  render() {
    const { attributes, forecast, estate } = this.props;
    return (
      <React.Fragment>
        <RoleRequired
          staff={true}
          permission={permissions.billingAttributeEdit}
        >
          <StyledEditContainer>
            <AttributeEdit initialData={{ estate: estate.id }} />
          </StyledEditContainer>
        </RoleRequired>
        <DataTable unstackable={true} data={attributes}>
          <DataTableColumn
            align="left"
            name={t("labels.name")}
            render={(el) => {
              return <span>{el.type}</span>;
            }}
          />
          <DataTableColumn
            align="right"
            name={t("billing.value")}
            render={(el) => {
              return <span>{el.value}</span>;
            }}
          />
          <DataTableColumn
            align="right"
            name={t("labels.payment")}
            render={(el) => {
              const attrForecast =
                (forecast && forecast.forecast[el.type]) || 0;
              return <span>{amountToBGN(attrForecast)}</span>;
            }}
          />

          <DataTableColumn
            align="right"
            name=""
            render={(el) => {
              return (
                <RoleRequired
                  staff={true}
                  permission={permissions.billingAttributeEdit}
                >
                  <AttributeEdit entity={el} />{" "}
                </RoleRequired>
              );
            }}
          />
        </DataTable>
      </React.Fragment>
    );
  }
}

function stateToProps(state, props) {
  return {
    models: state.billingModel.entities || [],
    attributes: state.billingAttr.entities || [],
    forecast: entityGet(
      state.billingForecast.entities || [],
      (entry) => entry.estate.id === props.estate.id
    ),
  };
}

function dispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const AttributeListContainer = connect(stateToProps, dispatchToProps)(List);

export default Preload(estateBillDeps, loadEstateBillingDeps, (props) => {
  return { block: props.block.id, estate: props.estate.id };
})(AttributeListContainer);
