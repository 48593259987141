import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";

import { UserFile } from "./user-file";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  updateTicketComment,
} from "../../redux/modules/ticketComment";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import type { FormProps } from "../../components/common/edit-entity";
import { t } from "../../constant/translations";

export class TicketEditCommentComponent extends Component<FormProps> {
  state = { formData: null };

  render() {
    const { error, entity, profiles, initialData } = this.props;

    if (!this.props.entity) {
      throw new Error("`entity` is required for editing ticket comment.");
    }

    const initialState = getInitialState(entity, initialData);
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={t("ticket.edit_comment")}
      >
        <Form.Field>
          <label>{t("labels.content")}</label>
          <Form.TextArea
            placeholder={t("labels.content")}
            {...manager.getAttributes("content")}
            rows={4}
          />
          {error.has("content") && <span>{error.get("content")}</span>}
        </Form.Field>
        <Form.Field>
          <UserFile name="files" {...manager.getAttributes("files")} />
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.ticketComment.error),
    loading: state.ticketComment.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { update: updateTicketComment, clearError },
    dispatch
  );
}

export const TicketEditComment = connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketEditCommentComponent);
