import React from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "semantic-ui-react";
import { connect } from "react-redux";
import { updateTransactionCategory } from "../../../redux/modules/fundTransaction";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";

const CategoryComponent = ({
  transaction,
  availableCategories,
  onCategoryChange,
  user,
}) => {
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  if (
    !availableCategories ||
    availableCategories.length === 0 ||
    !user.hasPermission(permissions.billingTransactionEdit) ||
    transaction.cancel_at !== undefined
  ) {
    return <span>{capitalize(transaction.category)}</span>;
  }

  return (
    <RoleRequired staff={true} permission={permissions.billingTransactionEdit}>
      <Dropdown text={capitalize(transaction.category)}>
        <DropdownMenu>
          {availableCategories.map((categoryName) => (
            <DropdownItem
              key={categoryName}
              text={capitalize(categoryName)}
              onClick={() => onCategoryChange(transaction.id, categoryName)}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </RoleRequired>
  );
};

const mapStateToProps = (state) => {
  return {
    availableCategories: state.appSettings.data
      ? state.appSettings.data.transaction_categories
      : [],
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCategoryChange: (transactionId, categoryName) => {
      dispatch(updateTransactionCategory(transactionId, categoryName));
    },
  };
};

export const Category = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryComponent);
