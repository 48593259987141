import { FormdataManager } from "../../../components/common/formdata";
import { EditEntity } from "../../../components/common/edit-entity";
import { Form } from "semantic-ui-react";
import React from "react";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { cancelTransaction } from "../../../redux/modules/fundTransaction";
import { bindActionCreators } from "redux";
import { ErrorWrap } from "../../../helpers/error";
import { t } from "../../../constant/translations";

export function CancelTransactionFormComponent({
  error,
  entity,
  loading,
  action,
}) {
  const manager = FormdataManager.useState({
    id: entity.id,
  });
  const header = t("settlement.reverse_transaction");
  return (
    <EditEntity
      entity={entity}
      manager={manager}
      header={header}
      error={error}
      loading={loading}
      create={action}
      update={action}
      trigger_props={{
        color: "red",
        icon: "delete",
        floated: "right",
      }}
    >
      <Form.Field>
        <label>{t("charge.cause")}</label>
        <Form.TextArea
          placeholder={t("settlement.reason_for_reverse")}
          {...manager.getAttributes("reason")}
          error={error.has("reason") && { content: error.get("reason") }}
        />
      </Form.Field>
    </EditEntity>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.fundTransaction.pending,
    error: ErrorWrap.fromError(state.fundTransaction.error),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      action: cancelTransaction,
    },
    dispatch
  );
}

export const CancelTransactionForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelTransactionFormComponent);
