import { newApi, request } from "../restdux/index";

type PendingBreakdown = {
  block_id: number,
  block_name: string,
  amount: number,
};

type Collection = {
  total_amount: number,
  pending_total_amount: number,
  pending_breakdown: Array<PendingBreakdown>,
};
export type CollectionModel = {
  id: number,
  email: string,
  collections: Collection,
};

export const collectionManager = newApi(
  "collection",
  "/api/collection/",
  false
);
export const reducer = collectionManager.reducer;

export function clearError() {
  return collectionManager.actions.clearError();
}

export function fetchCollections(params) {
  return collectionManager.actions.list(params);
}

export function reconcile(data) {
  return (dispatch, getState, client) => {
    const getPromise = () =>
      client.post(`/api/collection/${data.id}/reconcile/`);
    return request(
      collectionManager.actionTypes.UPDATE_PENDING,
      collectionManager.actionTypes.UPDATE_SUCCESS,
      collectionManager.actionTypes.UPDATE_ERROR
    )(getPromise, dispatch);
  };
}
