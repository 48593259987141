import styled from "styled-components";

import React, { Component } from "react";
import { connect } from "react-redux";
import { isLoaded, load } from "../../redux/modules/auth";
import { bindActionCreators } from "redux";
import { NavLink as Link, Route, Switch } from "react-router-dom";
import { Login } from "./login";
import { LoginNoPass } from "./login-nopass";
import { LoginSuccess } from "./login-success";
import { reverse, routes } from "../../routes";
import { PasswordReset } from "./password-reset";
import { PasswordResetConfirm } from "./password-reset-confirm";
import { Loader, Segment } from "semantic-ui-react";
import { imgSrc } from "../../helpers/static";
import { LicenseAgreement } from "./license";
import { StyledContainer, StyledItem, StyledLogo } from "./styled-components";
import { ViberModal } from "../../components/common/viber-modal";
import qs from "qs";
import { Register } from "./register";
import { t } from "../../constant/translations";
import { DropdownSwitchLanguage } from "../../components/common/switchLanguage";

const TopRightMenu = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 1em 0;
  margin-bottom: 2em;
`;

const StyledAgreement = styled.p`
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;

  > a {
    line-height: 1rem;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

const FlexMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 1em;
`;

const StyledLink = styled(Link)`
  display: block;
  color: black;
  font-size: 1.1em;
  height: 2.5em;
  line-height: 2.5em;

  &.active {
    font-weight: bold;
    border-bottom: 1px solid #9f9f9f;
  }
`;
const StyledSegment = styled(Segment)``;

const LoginContainer = () => {
  return (
    <>
      <FlexMenu>
        <StyledLink
          activeClassName="active"
          className="item"
          exact={true}
          to={reverse(routes.authLogin)}
        >
          {t("authwrap.letter")}
        </StyledLink>
        <StyledLink
          activeClassName="active"
          className="item"
          to={reverse(routes.authLoginPass)}
        >
          {t("authwrap.password")}
        </StyledLink>
        <StyledLink
          activeClassName="active"
          className="item"
          to={reverse(routes.authLoginRegister)}
        >
          {t("authwrap.demo")}
        </StyledLink>
      </FlexMenu>
      <StyledSegment>
        <Switch>
          <Route
            path={routes.authLogin}
            index={true}
            exact={true}
            component={LoginNoPass}
          />
          <Route path={routes.authLoginPass} component={Login} />
          <Route path={routes.authLoginRegister} component={Register} />
        </Switch>
      </StyledSegment>
    </>
  );
};

export default function AuthWrap(WrappedComponent) {
  class AuthWrapComponent extends Component {
    componentDidMount() {
      if (!isLoaded(this.props)) {
        this.props.loadUser();
      }
    }

    componentDidUpdate(oldProps) {
      const { location } = this.props;
      const inAuthNav = location.pathname.startsWith(routes.baseAuth);
      if (!inAuthNav && !this.props.auth.loading && !this.props.auth.user) {
        const isBase = location.pathname === routes.baseURL;
        let search = null;
        if (!isBase) {
          search = qs.stringify(
            { next: location.pathname },
            { addQueryPrefix: true }
          );
        }

        this.props.history.replace({
          pathname: routes.authLogin,
          search: search,
        });
      }

      if (inAuthNav && oldProps.auth.loading && this.props.auth.user) {
        const search = qs.parse(location.search, { ignoreQueryPrefix: true });
        let next = routes.baseURL;
        if (search.next && search.next.startsWith(routes.baseURL)) {
          next = search.next;
        }
        this.props.history.replace(next);
      }
    }

    render() {
      const { location } = this.props;
      const authRequested = location.pathname.startsWith(routes.baseAuth);
      if (this.props.auth.user && !authRequested) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.props.auth.loading) {
        return <Loader />;
      }

      return (
        <StyledContainer>
          <StyledItem>
            <TopRightMenu>
              <DropdownSwitchLanguage />
            </TopRightMenu>
            <StyledLogo>
              <a href={"https://www.umenvhod.com/"}>
                <img src={imgSrc("logo.png")} width={"330px"} />
              </a>
            </StyledLogo>
            <Route path={routes.authLogin} component={LoginContainer} />
            <Route path={routes.authLoginSuccess} component={LoginSuccess} />
            <Route
              path={routes.authSetPassword}
              component={PasswordResetConfirm}
            />
            <Route path={routes.authPasswordReset} component={PasswordReset} />
            <Route
              path={routes.authPasswordResetSuccess}
              component={LoginSuccess}
            />
            <StyledAgreement>
              {t("authwrap.agreement")} <LicenseAgreement />
            </StyledAgreement>
            <FlexBox>
              <ViberModal />
            </FlexBox>
          </StyledItem>
        </StyledContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return { auth: state.auth };
  };

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ loadUser: load }, dispatch);
  };

  return connect(mapStateToProps, mapDispatchToProps)(AuthWrapComponent);
}
