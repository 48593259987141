/* @flow */
import styled from "styled-components";
import React, { Component } from "react";
import { Button, Checkbox, Form, Message, Modal } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  create as settlementCreate,
  clearError,
} from "../../redux/modules/settlement";
import { getEstate } from "../../redux/modules/estates";
import { ChargeListSmall } from "../billing/charge-list-small";
import { CalendarInput } from "../../components/common/calendar-input";
import { FormdataManager } from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { withRouter } from "react-router";
import { PaymentMethod } from "../../config";
import { t } from "../../constant/translations";
import { amountToBGN } from "../../helpers/currency";
import dayjs from "dayjs";

const AmountField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  > :first-child {
    flex-grow: 1;
  }
`;

const QuickAmount = styled.div`
  margin: 1.55em 0 1em 1em;

  > button {
    font-size: 1.07em !important;
    margin-right: 0 !important;
  }
`;

type Props = {
  estate: Object,
  loading: boolean,
  error?: ErrorWrap,
  triggerText?: string,
};

export class PayAction extends Component<Props> {
  state = {
    formData: {},
    modalOpen: false,
  };

  resetFormData() {
    this.setState({
      formData: {
        estate: this.props.estate.id,
        settle_date: dayjs().format("YYYY-MM-DD"),
        payment_method: PaymentMethod.cash,
        deposit_amount: "",
        description: t("labels.payment"),
        auto_settle: true,
        charges: [],
      },
    });
  }

  componentDidMount() {
    this.resetFormData();
  }

  componentDidUpdate(oldProps) {
    // If estates don't match, user probably hyperlinked from another estate's details - reset the formData
    if (oldProps.estate !== this.props.estate) {
      this.resetFormData();
    }

    if (oldProps.loading && !this.props.loading && !this.props.error.exists()) {
      this.setState({ modalOpen: false });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.props.loading) {
      this.props.pay(this.state.formData);
    }
  };

  render() {
    const { estate, loading, error, media } = this.props;
    const form = new FormdataManager(this.state.formData, (formData) =>
      this.setState({ formData })
    );
    form.withError(error);
    const paymentOptions = [
      {
        text: t(`paymentMethods.${PaymentMethod.cash}`),
        value: PaymentMethod.cash,
      },
      // {
      //   text: t(`paymentMethods.${PaymentMethod.card}`),
      //   value: PaymentMethod.card,
      // },
      {
        text: t(`paymentMethods.${PaymentMethod.bank}`),
        value: PaymentMethod.bank,
      },
    ];
    return (
      <div>
        <Modal
          onOpen={() => this.setState({ modalOpen: true })}
          onClose={() => {
            if (!loading) {
              this.setState({ modalOpen: false }, this.props.clearError);
            }
          }}
          open={this.state.modalOpen}
          trigger={
            <Button
              positive
              basic
              size={"tiny"}
              icon={"payment"}
              content={this.props.triggerText}
              title={t("estate.pay")}
            />
          }
          closeIcon={true}
        >
          <Modal.Header>
            {t("estate.new_payment_to")} {estate.name}
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form onSubmit={this.handleSubmit} error={error.exists()}>
                <AmountField>
                  <Form.Field
                    control="input"
                    name="deposit_amount"
                    label={t("labels.amount")}
                    type="number"
                    step="0.01"
                    fluid={true}
                    autoFocus="autofocus"
                    error={error.has("deposit_amount")}
                    {...form.getAttributes("deposit_amount")}
                  />
                  <QuickAmount>
                    <Button
                      type={"button"}
                      size={"medium"}
                      color={"green"}
                      onClick={() =>
                        form.setValue("deposit_amount", estate.debt)
                      }
                      content={amountToBGN(estate.debt)}
                    />
                  </QuickAmount>
                </AmountField>

                <CalendarInput
                  label={t("labels.date")}
                  {...form.getAttributes("settle_date")}
                  toMonth={dayjs().toDate()}
                />
                <Form.Field
                  control="textarea"
                  label={t("labels.description")}
                  rows={3}
                  {...form.getAttributes("description")}
                />
                <Form.Select
                  label={t("settlement.payment_method")}
                  rows={3}
                  options={paymentOptions}
                  {...form.getAttributes("payment_method")}
                />
                <Form.Field>
                  <Message>
                    <Checkbox
                      toggle
                      label={t("estate.automatic_payment")}
                      checked={form.getValue("auto_settle")}
                      onChange={() =>
                        form.setValue(
                          "auto_settle",
                          !form.getValue("auto_settle")
                        )
                      }
                    />
                    <p>{t("estate.checkmark_debt_order")}</p>
                  </Message>
                </Form.Field>
                <ChargeListSmall
                  estate={estate}
                  selectableRows={!form.getValue("auto_settle")}
                  onSelect={(selected) =>
                    form.setValue("charges", [...selected])
                  }
                  amountField={"amount_left"}
                />
                <Message
                  error
                  content={
                    error.exists() ? error.getGlobal() : t("error.savng")
                  }
                />
                <Button
                  type="submit"
                  fluid
                  size="large"
                  positive
                  loading={loading}
                >
                  {t("estate.pay")}
                </Button>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.settlement.pending,
    error: ErrorWrap.fromError(state.settlement.error),
  };
}

const buildPayAction = ({ history }) => (formData) => {
  return (dispatch) => {
    // Internal user payment

    switch (formData.payment_method) {
      // Staff created settlements
      case PaymentMethod.cash:
      case PaymentMethod.bank:
        return dispatch(settlementCreate(formData)).then(
          (resp) => !resp.error && dispatch(getEstate(formData.estate))
        );
      // case PaymentMethod.card:
      // Card present flow
      // return;
      default:
        // Card not present flow (default)
        return;
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const pay = buildPayAction(ownProps);
  return bindActionCreators({ pay, clearError }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayAction)
);
