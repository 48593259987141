export const resourceTypes = [
  "audit",
  "billing-attribute",
  "billing-forecast",
  "billing-model",
  "billing-monthly-report",
  "billing-summary",
  "block",
  "board-post",
  "charge",
  "charge_to",
  "collection",
  "collection-stats",
  "estate",
  "estate_capital",
  "estate-customers",
  "file",
  "file-dir",
  "note",
  "profile",
  "reports",
  "settlement",
  "ticket",
  "ticket-comment",
  "transaction",
  "user-file",
  "user",
  "fund",
  "user-role",
  "billing-attribute-bulk-upsert",
  "fund-transaction",
  "checkout",
];

export const paymentMethods = {
  bank: "bank",
  card: "card",
  cash: "cash",
  epay: "epay",
  easypay: "easypay",
};

export const estateTypes = [
  "apartment",
  "garage",
  "office",
  "store",
  "parking_space",
  "studio",
  "shop",
  "atelier",
  "restaurant",
  "gym",
  "laboratory",
  "other",
];
