import React from "react";
import { Link } from "react-router-dom";

import { t } from "../../../constant/translations";
import { routes, reverse } from "../../../routes";
import { amountToBGN } from "../../../helpers/currency";

export const MAP_PARAMS_TO_VALUE_LABEL_PAIRS = {
  id: (v) => ({
    label: t("labels.id"),
    value: v,
  }),
  block: (v) => ({
    label: t("labels.block"),
    value: v,
  }),
  author: (v) => ({
    label: t("labels.author"),
    value: v.name || "--",
  }),
  assignee: (v) => ({
    label: t("labels.assignee"),
    value: v.name || "--",
  }),
  name: (v) => ({
    label: t("labels.name"),
    value: v,
  }),
  title: (v) => ({
    label: t("labels.title"),
    value: v,
  }),
  content: (v) => ({
    label: t("labels.content"),
    value: v,
  }),
  description: (v) => ({
    label: t("labels.description"),
    value: v,
  }),
};

export const logEntryLink = (logEntry) => {
  const { response, resource_id } = logEntry;
  const resourceName = t(`resources.${logEntry.resource}`, logEntry.resource);
  switch (logEntry.resource) {
    case "ticket": {
      return (
        <Link
          to={reverse(routes.ticketDetails, {
            blockId: response.block,
            ticketId: resource_id,
          })}
        >
          {resourceName}
        </Link>
      );
    }
    case "user": {
      return (
        <Link
          to={reverse(routes.teamDetails, {
            userId: resource_id,
          })}
        >
          {resourceName}
        </Link>
      );
    }
    case "estate": {
      return (
        <Link
          to={reverse(routes.estateDetails, {
            blockId: response.block,
            estateId: resource_id,
          })}
        >
          {resourceName}
        </Link>
      );
    }
    case "estate-customers": {
      return (
        <React.Fragment>
          {resourceName}
          {t("management.to")}
          <Link
            to={reverse(routes.blockDetails, {
              blockId: response.block,
            })}
          >
            {t(`resources.block`)}
          </Link>
        </React.Fragment>
      );
    }
    case "ticket-comment": {
      return (
        <React.Fragment>
          {t("labels.from")} {response.amount}
          <Link
            to={reverse(routes.ticketDetails, {
              blockId: response.block,
              ticketId: response.ticket,
            })}
          >
            {resourceName}
          </Link>
        </React.Fragment>
      );
    }
    case "settlement": {
      return (
        <React.Fragment>
          {resourceName} {t("labels.from")}{" "}
          {amountToBGN(response.deposit_amount)} {t("settlement.to")}
          <Link
            to={reverse(routes.estateDetails, {
              blockId: response.block,
              estateId: response.estate,
            })}
          >
            {" "}
            {response.block_details.name} - {response.estate_details.name}
          </Link>
        </React.Fragment>
      );
    }

    default: {
      return resourceName;
    }
  }
};

export const logEntryTitle = (logEntry) => (
  <React.Fragment>
    {t(`logActions.${logEntry.action}`, logEntry.action)}{" "}
    {logEntryLink(logEntry)} {logEntryDetails(logEntry)}
  </React.Fragment>
);

export function logEntryDetails(logEntry) {
  const { response } = logEntry;
  const { name, title, first_name, email, reconciled_amount } = response || {};

  if (name) return ` ${t("management.with_name")} "${name}"`;
  if (title) return ` ${t("management.with_title")} "${title}"`;
  if (first_name) return ` ${t("management.with_first_name")} "${first_name}"`;
  if (email && reconciled_amount)
    return ` ${t("management.with")} email "${email}" ${t(
      "management.for_amount_of"
    )} ${amountToBGN(reconciled_amount)}`;

  return "";
}
