import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { FinanceGlobalStats } from "./globalstats";

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

class FinancePageComponent extends Component {
  state = {
    loading: false,
    schema: null,
    fullScreen: false,
  };

  render() {
    return (
      <div>
        <FinanceGlobalStats />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export const FinancePage = connect(mapStateToProps)(FinancePageComponent);
