import { t } from "../constant/translations";

export function amountToBGN(amount: String) {
  if (!amount) {
    amount = 0;
  }
  return `${parseFloat(amount).toFixed(2).toLocaleString()} ${t(
    "resources.bgn_no_dot"
  )}`;
}

export function amountToBGNOrEmpty(amount: String) {
  if (!amount) {
    return null;
  }
  return amountToBGN(amount);
}

export function prettyNumber(amountStr: String) {
  let stringLeft = amountStr.toString();
  let slices = [];
  let separatorIdx = 0;

  while (separatorIdx < stringLeft.length) {
    const char = stringLeft[separatorIdx];
    if (char === " " || char === ".") {
      break;
    }
    separatorIdx++;
  }

  while (separatorIdx > 2) {
    const nextSep = separatorIdx - 3;
    slices.unshift(stringLeft.slice(nextSep));
    stringLeft = stringLeft.slice(0, nextSep);
    separatorIdx = nextSep;
  }

  if (stringLeft) {
    slices.unshift(stringLeft);
  }
  return slices.join(" ");
}
export function amountToBGNPretty(amount: String) {
  const amountStr = amountToBGN(amount);
  return prettyNumber(amountStr);
}
