import React, { Fragment } from "react";
import { Form, Checkbox } from "semantic-ui-react";

import { t } from "../../../constant/translations";
import { StringParam, BooleanParam, DateParam } from "use-query-params";
import DayPickerInput from "react-day-picker/DayPickerInput";

export const settlementQueryFilterParams = {
  estate_vendor_id: StringParam,
  payment_method: StringParam,
  created_before: DateParam,
  created_after: DateParam,
};

export const settlementQueryKeyMap = {
  estate_vendor_id: t("charge.search_capital"),
  payment_method: (value) =>
    t("settlement.payment_method") +
    `: ${t(`paymentMethods.${value}`).toLowerCase()}`,
};

export const ContentFilterFormBody = ({ data, onChange }) => {
  return (
    <Fragment>
      <Form.Input
        icon="search"
        placeholder={t("settlement.client_number")}
        onChange={onChange("estate_vendor_id")}
        value={data.estate_vendor_id || ""}
        name={"search-settlements"}
      />
      <h5>{t("settlement.payment_method")}</h5>
      <Form.Select
        value={data.payment_method || null}
        options={[
          null,
          "bank",
          "cash",
          "card",
          "epay",
          "easypay",
          "balance",
        ].map((e) => {
          return { text: t(`paymentMethods.${e}`), value: e };
        })}
        onChange={onChange("payment_method")}
      />
      <h5>{t("charge.period")}</h5>
      <div>
        <DayPickerInput
          placeholder={t("titles.from_date")}
          value={data.created_after}
          onDayChange={(dateValue) =>
            onChange("created_after")(null, { value: dateValue })
          }
        />
        <DayPickerInput
          placeholder={t("titles.to_date")}
          value={data.created_before}
          onDayChange={(dateValue) =>
            onChange("created_before")(null, { value: dateValue })
          }
        />
      </div>
    </Fragment>
  );
};
