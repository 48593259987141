import React, { Component } from "react";
import { SettlementList } from "./settlement/list";

export default class BlockSettlementPage extends Component {
  render() {
    return (
      <SettlementList
        location={this.props.location}
        defaultFilters={{ block: this.props.blockId }}
      />
    );
  }
}
