import styled from "styled-components";

export const StyledContainer = styled.div`
  min-height: 100% !important;
  margin: 0;
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLogo = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

export const StyledLinks = styled.div`
  width: 100%;
  text-align: right;
  margin: 0 0 18px 0;
`;

export const StyledItem = styled.div`
  width: 440px;
`;

export const StyledErrorSpan = styled.div`
  color: red;
`;
