import styled from "styled-components";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Loader, Segment } from "semantic-ui-react";
import { apirequest } from "../../../helpers/api-client";
import { t } from "../../../constant/translations";
import { numberToPercent } from "../../../helpers/format";
import { amountToBGNPretty, prettyNumber } from "../../../helpers/currency";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import dayjs from "dayjs";
import { IconNoData } from "../../../components/common/icon-nodata";

const LoadingContainer = styled.div`
  min-height: 400px;
`;
const ChartSegment = styled(Segment)`
  height: 280px !important;
  width: 100%;
`;

const IconNoDataStyled = styled(IconNoData)`
  margin-top: 54px !important;
`;

const CenterDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const GlobalUsageReport = (props) => {
  const usage = props.current;
  return (
    <Grid columns="equal">
      <Grid.Row columns={4}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Segment>
            <Header sub>{t("stat.estates")}</Header>
            <span>{prettyNumber(usage.estates)}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Segment>
            <Header sub>{t("stat.active_blocks")}</Header>
            <span>{usage.active_blocks}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Segment>
            <Header sub>{t("stat.estates_growth")}</Header>
            <span>{`${numberToPercent(usage.estates_growth)} %`}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Segment>
            <Header sub>{t("stat.active_blocks_growth")}</Header>
            <span>{`${numberToPercent(usage.active_blocks_growth)} %`}</span>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const PaymentMonthlyDepositReport = (props) => {
  const defaults = [];
  for (let i = 0; i < 5; i++) {
    defaults.unshift({
      settle_month: dayjs().subtract(i, "months").format("YYYY-DD-MM"),
    });
  }
  return (
    <ChartSegment>
      <h5>{t("stat.deposits_per_month")}</h5>
      <ResponsiveBar
        margin={{
          bottom: 50,
        }}
        colorBy="id"
        data={
          props.usage.deposits_per_month.length > 0
            ? props.usage.deposits_per_month.reverse()
            : defaults
        }
        indexBy={"settle_month"}
        keys={["epay", "easypay", "cash", "bank", "card"]}
        enableLabel={false}
        tooltipFormat={amountToBGNPretty}
        axisLeft={false}
      />
    </ChartSegment>
  );
};

const DepositMethodPercentReport = (props) => {
  return (
    <ChartSegment>
      <h5>{t("stat.deposit_method_percent")}</h5>
      {props.usage.deposits_payment_method_percent.length > 0 ? (
        <ResponsivePie
          margin={{
            bottom: 50,
          }}
          colorBy="value"
          data={props.usage.deposits_payment_method_percent}
          id={"payment_method"}
          value={"deposit_amount"}
          enableLabel={false}
          formattedValue={(entry) => {
            `${entry * 100}%`;
          }}
          axisLeft={false}
        />
      ) : (
        <IconNoDataStyled
          text={t("stat.no_payments_data")}
          size={"tiny"}
          center={true}
        />
      )}
    </ChartSegment>
  );
};
const PaymentMonthlyDepositReportTable = (props) => {
  return (
    <>
      <h5>{t("stat.deposits_per_month")}</h5>
      <DataTable data={props.usage.deposits_per_month} unstackable={true}>
        <DataTableColumn
          name={t("labels.month")}
          render={(entry) => {
            return entry.settle_month;
          }}
        />
        <DataTableColumn
          name={t("paymentMethods.cash")}
          render={(entry) => {
            return amountToBGNPretty(entry.cash);
          }}
        />
        <DataTableColumn
          name="ePay"
          name={t("paymentMethods.epay")}
          render={(entry) => {
            return amountToBGNPretty(entry.epay);
          }}
        />
        <DataTableColumn
          name={t("paymentMethods.easypay")}
          render={(entry) => {
            return amountToBGNPretty(entry.easypay);
          }}
        />
        <DataTableColumn
          name={t("paymentMethods.bank")}
          render={(entry) => {
            return amountToBGNPretty(entry.bank);
          }}
        />
        <DataTableColumn
          name={t("paymentMethods.card")}
          render={(entry) => {
            return amountToBGNPretty(entry.card);
          }}
        />
        <DataTableColumn
          name={t("labels.total")}
          render={(entry) => {
            return amountToBGNPretty(entry.total);
          }}
        />
      </DataTable>
    </>
  );
};

const FinanceGlobalStatsComponent = (props) => {
  const [stats, setStats] = useState("stats");
  const [loading, setLoading] = useState("loading", true);

  useEffect(() => {
    const effect = async () => {
      const result = await apirequest(
        "get",
        `/api/reports/global-finance-report/`
      );
      setStats(result.body);
      setLoading(false);
    };
    effect();
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <Loader active={true} />
      </LoadingContainer>
    );
  }
  return (
    <Grid padded fluid={true}>
      <Grid.Row>
        <Grid.Column mobile={16}>
          <GlobalUsageReport current={stats.global}></GlobalUsageReport>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={12}>
          <PaymentMonthlyDepositReport usage={stats} />
        </Grid.Column>
        <Grid.Column mobile={4}>
          <DepositMethodPercentReport usage={stats} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16}>
          <PaymentMonthlyDepositReportTable usage={stats} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const FinanceGlobalStats = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceGlobalStatsComponent);
