import "react-day-picker/lib/style.css";

import React, { Component } from "react";
import dayjs from "dayjs";
import DayPicker from "react-day-picker";
import { Form, Icon, Popup } from "semantic-ui-react";

type Props = {
  name: string,
  value: string,
  onChange: Function,
  label?: string,
  fromMonth?: string,
  toMonth?: string,
};

export class CalendarInput extends Component<Props> {
  state = { value: null, isOpen: false };

  constructor(props) {
    super(props);
    this.triggerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({ value: this.props.value });
    }
    if (this.triggerRef.current) {
      const input = this.triggerRef.current.getElementsByTagName("input")[0];
      input.autocomplete = "off";
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onCalendarClick = (date) => {
    const value = date ? dayjs(date).format("YYYY-MM-DD") : "";
    this.setState({
      value,
      isOpen: false,
    });
    this.props.onChange({ target: { name: this.props.name, value } });
  };

  render() {
    const selectedDate = this.state.value
      ? dayjs(this.state.value).toDate()
      : dayjs().toDate();

    const { fromMonth, toMonth } = this.props;
    const triggerButton = (
      <div ref={this.triggerRef}>
        <Form.Input
          label={this.props.label}
          value={this.state.value || ""}
          name={this.props.name}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          icon={
            <Icon
              name={this.state.value ? "close" : ""}
              link={true}
              onClick={() => {
                this.onCalendarClick(null);
              }}
            />
          }
        />
      </div>
    );
    return (
      <Popup
        trigger={triggerButton}
        open={this.state.isOpen}
        onClose={() => this.setState({ popClosed: true })}
        position="bottom left"
      >
        <Popup.Content>
          <DayPicker
            onDayClick={this.onCalendarClick}
            month={selectedDate}
            selectedDays={selectedDate}
            fromMonth={fromMonth}
            toMonth={toMonth}
          />
        </Popup.Content>
      </Popup>
    );
  }
}
