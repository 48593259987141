import styled from "styled-components";
import React from "react";
import { List } from "semantic-ui-react";
import { amountToBGN } from "../../helpers/currency";
import { t } from "../../constant/translations";

const StyledChargeHeader = styled.div`
  display: flex;
  min-width: 100%;
`;

const StyledItemContent = styled.div`
  font-style: italic;
`;

const StyledFlexGrow = styled.span`
  flex-grow: 1;
`;

const StyledDescContainer = styled.div`
  min-width: 400px;
`;

const StyledChargeSection = styled.div`
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.border};

  &:last-child {
    border-bottom: 0 solid ${(props) => props.theme.border};
  }
`;

export const Description = ({ charge }) => {
  const rawDetails = charge.details || [];
  const details = rawDetails.map((detail, idx) => {
    const modelPrice = Math.max(detail.model_min_price, detail.model_price);
    return (
      <List.Item key={idx}>
        <List.Header>
          <StyledChargeHeader>
            <StyledFlexGrow>{detail.model}</StyledFlexGrow>
            <span>{amountToBGN(detail.amount)}</span>
          </StyledChargeHeader>
        </List.Header>
        <List.Content>
          <StyledItemContent>
            {detail.value} {t("labels.multiply")} {amountToBGN(modelPrice)}
          </StyledItemContent>
        </List.Content>
      </List.Item>
    );
  });
  return (
    <StyledDescContainer>
      <StyledChargeSection>
        <div>
          {details.length ? <List>{details}</List> : charge.description}
        </div>
      </StyledChargeSection>
      <StyledChargeSection>
        <StyledChargeHeader>
          <StyledFlexGrow>{t("labels.total_amount")}</StyledFlexGrow>
          <span>{amountToBGN(charge.amount)}</span>
        </StyledChargeHeader>
        <StyledChargeHeader>
          <StyledFlexGrow>{t("labels.charge_amount_left")}</StyledFlexGrow>
          <span>{amountToBGN(charge.amount_left)}</span>
        </StyledChargeHeader>
      </StyledChargeSection>
    </StyledDescContainer>
  );
};
