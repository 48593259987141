import { newApi } from "../restdux/index";

export type StatEntry = {
  creator: ?string,
  creator_email: string,
  block: number,
  block_name: string,
  total_amount: string,
  date: string,
};

export const collectionStatsManager = newApi(
  "collectionStats",
  "/api/collection-stats/",
  false
);
export const reducer = collectionStatsManager.reducer;

export function clearError() {
  return collectionStatsManager.actions.clearError();
}

export function fetchCollectionStats(params) {
  return collectionStatsManager.actions.list(params);
}
