import styled from "styled-components";
import * as React from "react";
import { Header, Icon } from "semantic-ui-react";

const StyledHeaderWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export const IconNoData = ({ text, size, className }) => {
  return (
    <StyledHeaderWrap className={className}>
      <Header textAlign={"center"} icon size={size}>
        <Icon name={"file alternate outline"} size={size} />
        <Header.Subheader>{text}</Header.Subheader>
      </Header>
    </StyledHeaderWrap>
  );
};
