import React from "react";
import { Item } from "semantic-ui-react";
import FileLabel from "./file-label";
import dayjs from "dayjs";
import { PostForm } from "./post-form";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { t } from "../../constant/translations";
import { Editor, convertFromRaw, EditorState } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";

export const PostList = ({ posts, deletePost }) => (
  <Item.Group divided>
    {posts.map((post) => (
      <Item key={post.id}>
        <Item.Content>
          <RoleRequired staff={true} permission={permissions.boardEditPost}>
            <PostForm entity={post} header={t("board.change_message")} />
          </RoleRequired>
          <Item.Header style={{ maxWidth: "80%" }}>{post.title}</Item.Header>
          <Item.Meta>{dayjs(post.created_at).fromNow()}</Item.Meta>
          <Item.Description>
            <Editor
              editorState={EditorState.createWithContent(
                convertFromRaw(markdownToDraft(post.content))
              )}
              readOnly={true}
            />
          </Item.Description>
          <Item.Extra>
            {post.files.map((fileId) => (
              <FileLabel key={`file-${fileId}`} fileId={fileId} />
            ))}
          </Item.Extra>
        </Item.Content>
      </Item>
    ))}
  </Item.Group>
);
