import React, { Component } from "react";

type Props = {
  contactInfo: string,
};

export class ContactInfo extends Component<Props> {
  infoWithActiveNumbers(text: string): Array {
    if (!text) {
      return null;
    }

    const matches = text.match(/([\d]+[ /-]?)+/g) || [];
    const resultArray = [];
    let tail = text;
    matches.forEach((match) => {
      if (match.length < 6) {
        return;
      }

      const [head, newTail] = tail.split(match);
      resultArray.push(head);
      resultArray.push(
        <a href={`tel:${match}`} key={match}>
          {match}
        </a>
      );
      tail = newTail || "";
    });
    resultArray.push(tail);
    return resultArray;
  }

  render() {
    const { contactInfo } = this.props;
    const results = this.infoWithActiveNumbers(contactInfo);
    return <span>{results}</span>;
  }
}
