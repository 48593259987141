import { filter as fuzzySearch } from "fuzzaldrin";

export const defaultPageSize = 25;

export function paginate(entities, query) {
  const page = query.page ? parseInt(query.page) - 1 : 0;
  const pageSize = query.pageSize ? parseInt(query.pageSize) : defaultPageSize;
  return entities.slice(page * pageSize, (page + 1) * pageSize);
}

export function sliceList(entities, page, pageSize) {
  return entities.slice(page * pageSize, (page + 1) * pageSize);
}

export function entityGet(entities, matchFunc) {
  for (let entity of entities) {
    if (matchFunc(entity)) {
      return entity;
    }
  }
  return null;
}

export function entityGetByID(entities, id) {
  const matchFunc = (entity) => entity.id === id;
  return entityGet(entities, matchFunc);
}

export function filter(entities, query, fields) {
  if (!query) {
    return entities;
  }
  const foundIds = new Set();
  const results = [];

  for (const field of fields) {
    for (const result of fuzzySearch(entities, query, { key: field })) {
      if (!foundIds.has(result.id)) {
        foundIds.add(result.id);
        results.push(result);
      }
    }
  }

  return results;
}
