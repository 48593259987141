import styled from "styled-components";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loader, Segment, Table } from "semantic-ui-react";
import { BillingModelForm } from "./model/form";
import {
  createBillingModel,
  fetchBillingModels,
} from "../../redux/modules/billing-models";
import { noZero } from "../../helpers/billing";
import { Loading } from "../../components/common/loading";
import { RoleRequired } from "../auth/role-required";
import { amountToBGN } from "../../helpers/currency";
import { permissions } from "../../constant/permissions";
import { t } from "../../constant/translations";

const StyledTable = styled(Table)({
  margin: "0 0",
});

const StyledModelSegment = styled(Segment)({
  "@media screen and (max-width: 500px)": {
    "font-size": "11px !important",
  },
});

class BillingModelList extends React.Component {
  render() {
    const { block, billModels } = this.props;
    if (!billModels) {
      return <Loader active={true} />;
    }

    const showPrice = (entity) => {
      const price = noZero(entity.price);
      const minimum = noZero(entity.minimum);

      if (price && minimum) {
        return `${amountToBGN(price)} (${t("labels.minimum")} ${amountToBGN(
          minimum
        )})`;
      }
      return (
        amountToBGN(price) || `${t("labels.minimum")} ${amountToBGN(minimum)}`
      );
    };

    const modelList = billModels.map((entity) => {
      return (
        <Table.Row key={entity.id}>
          <Table.Cell>{entity.type}</Table.Cell>
          <Table.Cell>{entity.fund}</Table.Cell>
          <Table.Cell>{entity.description}</Table.Cell>
          <Table.Cell>{showPrice(entity)}</Table.Cell>
          <Table.Cell>
            <RoleRequired
              admin={true}
              permission={permissions.billingModelEdit}
            >
              <BillingModelForm block={block} entity={entity} />
            </RoleRequired>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <StyledModelSegment>
        <StyledTable unstackable={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("model.attribute")}</Table.HeaderCell>
              <Table.HeaderCell>{t("labels.direction")}</Table.HeaderCell>
              <Table.HeaderCell>{t("labels.description")}</Table.HeaderCell>
              <Table.HeaderCell>{t("model.price")}</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>
                <RoleRequired
                  admin={true}
                  permission={permissions.billingModelAdd}
                >
                  <BillingModelForm block={block} />
                </RoleRequired>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{modelList}</Table.Body>
        </StyledTable>
      </StyledModelSegment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    billModels: state.billingModel.entities,
    block: state.blocks.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createBillingModel, fetchBillingModels },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading(BillingModelList));
