import styled from "styled-components";
import React from "react";
import { List } from "semantic-ui-react";
import { reverse, routes } from "../../routes";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const StyledDescription = styled(List.Description)`
  font-style: italic;
  font-size: 0.9em;
  line-height: normal;
`;

const StyledTicketContent = styled.div`
  margin: 10px 0;
`;

export const DashboardTicketEntity = ({ entity, user }) => {
  return (
    <List.Item key={`ticket-${entity.id}`}>
      <List.Content>
        <StyledDescription title={entity.created_at}>
          {dayjs(entity.created_at).format("YYYY-MM-DD")} - {entity.author.name}
        </StyledDescription>
        <StyledTicketContent>
          <Link
            to={reverse(routes.ticketDetails, {
              blockId: entity.block,
              ticketId: entity.id,
            })}
          >
            {entity.title}
          </Link>
        </StyledTicketContent>
      </List.Content>
    </List.Item>
  );
};
