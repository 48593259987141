import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Header,
  List,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";

import Notes from "../note/notes";
import PayAction from "./pay";
import PayOutAction from "./payout";
import ChargeList from "../billing/charge-list";
import AttributeList from "../billing/attribute/list";

import { ErrorWrap } from "../../helpers/error";
import { BackButton } from "../common/back-button";
import { ProfileCard } from "../../components/estate/profile-card";
import { RoleRequired } from "../auth/role-required";
import { DetailsHeader } from "./header";
import { AddCustomerModal } from "./add-customer-modal.js";
import { EstateEditModal } from "./estate-edit-modal.js";
import { SettlementListEstate } from "../billing/settlement/list-estate";
import { ChargeEdit } from "../../components/billing/charge-edit";
import { DisplayCreatedSettlement } from "./settlement-created";

import { getEstate } from "../../redux/modules/estates";
import { filterByProp, getById } from "../../redux/restdux/utils";
import { deleteEstateCustomer } from "../../redux/modules/estate-customers";
import { t } from "../../constant/translations";

import { Switch } from "react-router";
import { reverse, routes } from "../../routes";
import { Route } from "react-router-dom";
import { SettlementModal } from "./settlement-modal";
import styled from "styled-components";
import { permissions } from "../../constant/permissions";

const MultiButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-evenly;
  padding: 0.5rem;
  //text-wrap: avoid;
`;

const AddProfileButtonWrapper = styled.div`
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  margin: 5px;
`;

const InfoWrapper = styled.div`
  margin: 10px 0 0 10px !important;
`;

const StyledHeader = styled(Header)({
  margin: "5px 10px!important",
});

const StyledHeaderAttr = styled(StyledHeader)`
  display: inline;
`;

const StyledNotes = styled(Notes)({
  margin: "0 0 10px 0!important",
});

const StyledColumn = styled(Grid.Column)`
  margin-bottom: 1rem !important;
`;

const FlexRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

  > button {
    margin: 0 0 !important;
  }
`;

class EstateDetails extends Component {
  componentDidMount() {
    const { estate, error, estateId } = this.props;
    if (!estate && !error.exists()) {
      this.props.getEstate(estateId);
    }
  }

  componentDidUpdate(prevProps) {
    // If estateIds don't match, user probably hyperlinked from another estate's details.
    // Reload the old estate in case it was updated from it's details.
    const { estate, error, estateId } = this.props;
    const newNavigation = prevProps.estateId !== this.props.estateId;
    const alreadyPassed = estate && estate.id === estateId;
    if (newNavigation && !alreadyPassed) {
      this.props.getEstate(this.props.estateId);
    }
  }

  render() {
    const { block, estate, profiles, estateCustomers, error } = this.props;
    if (error.exists()) {
      return <div>{error.getGlobal()}</div>;
    }

    if (!estate) {
      return <Loader active={true} />;
    }

    const customers = filterByProp(estateCustomers, { estate: estate.id });
    const profileCards = [];
    customers.forEach((customer) => {
      const profile = getById(customer.customer, profiles);
      if (profile) {
        profileCards.push(
          <ProfileCard
            key={profile.id}
            type={customer.type}
            profile={profile}
            showDelete={true}
            deleteError={this.props.estateCustomersError}
            deleteAction={this.props.deleteEstateCustomer.bind(this, {
              id: customer.id,
            })}
            estate={estate}
          />
        );
      }
    });

    return (
      <React.Fragment>
        <Switch>
          <Route
            path={routes.estateSettlementDetails}
            render={(props) => {
              const returnURL = reverse(routes.estateDetails, {
                blockId: block.id,
                estateId: estate.id,
              });
              return <SettlementModal returnURL={returnURL} {...props} />;
            }}
          />
        </Switch>
        <Grid padded>
          <Grid.Row>
            <StyledColumn largeScreen={4} computer={6} tablet={16}>
              <FlexRowSpaceBetween>
                <BackButton />
                <RoleRequired staff={true} permission={permissions.estateEdit}>
                  <EstateEditModal entity={estate} />
                </RoleRequired>
              </FlexRowSpaceBetween>
              <Segment.Group>
                <Segment>
                  <StyledHeader
                    forwardedAs="h4"
                    content={`${estate.name}`}
                    subheader={`${t("estate.client_number")} ${
                      estate.vendor_id
                    }`}
                  />
                  <InfoWrapper>
                    <List bulleted>
                      <List.Item>
                        {t(`estateTypes.${[estate.etype]}`)}
                      </List.Item>
                      {estate.floor && (
                        <List.Item>
                          {t("estate.floor")} {estate.floor}
                        </List.Item>
                      )}
                      {estate.preferred_payment_method && (
                        <List.Item>
                          {t("settlement.payment_method")}:{" "}
                          {t(
                            `paymentMethods.${[
                              estate.preferred_payment_method,
                            ]}`
                          ) || estate.preferred_payment_method}
                        </List.Item>
                      )}
                    </List>
                  </InfoWrapper>
                </Segment>
                <MultiButtonsWrapper>
                  <RoleRequired
                    staff={true}
                    permission={permissions.estateSettleAmount}
                  >
                    <ButtonWrapper>
                      <DisplayCreatedSettlement />
                      <PayAction
                        estate={estate}
                        triggerText={t("estate.pay")}
                      />
                    </ButtonWrapper>
                    <ButtonWrapper>
                      <PayOutAction
                        estate={estate}
                        triggerText={t("estate.return_deposit")}
                      />
                    </ButtonWrapper>
                  </RoleRequired>
                  <RoleRequired
                    staff={true}
                    permission={permissions.customerEdit}
                  >
                    <ButtonWrapper>
                      <ChargeEdit
                        estate={estate}
                        trigger_props={{
                          color: "blue",
                          icon: "plus",
                          content: t("estate.add_obligation"),
                          basic: true,
                        }}
                      />
                    </ButtonWrapper>
                  </RoleRequired>
                </MultiButtonsWrapper>
              </Segment.Group>
              <RoleRequired
                staff={true}
                permission={permissions.estateCustomerAdd}
              >
                <Segment.Group>
                  {profileCards}
                  <Segment>
                    <AddProfileButtonWrapper>
                      <AddCustomerModal estate={estate} />
                    </AddProfileButtonWrapper>
                  </Segment>
                </Segment.Group>
              </RoleRequired>
              <RoleRequired staff={true} permission={permissions.noteEdit}>
                <StyledHeader forwardedAs="h3">{t("block.notes")}</StyledHeader>
                <StyledNotes entity={estate} entityType="estate" />
              </RoleRequired>
            </StyledColumn>
            <StyledColumn largeScreen={12} computer={10} tablet={16}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    {!estate.charging_enabled && (
                      <Message
                        warning
                        icon={"history"}
                        header={t("estate.auto_bill_off")}
                        content={t("estate.no_periodic_bill")}
                      />
                    )}
                    <DetailsHeader estate={estate} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <StyledHeaderAttr forwardedAs="h3">
                      {t("billing.attributes")}
                    </StyledHeaderAttr>
                    <AttributeList block={block} estate={estate} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <StyledHeader forwardedAs="h3">
                      {t("billing.obligations")}
                    </StyledHeader>
                    <ChargeList estate={estate} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <StyledHeader forwardedAs="h3">
                      {t("labels.payments")}
                    </StyledHeader>
                    <SettlementListEstate estate={estate} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </StyledColumn>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const estateId = parseInt(ownProps.match.params.estateId);
  return {
    estateId,
    block: state.blocks.selected,
    estate: getById(estateId, state.estates.entities),
    profiles: state.profile.entities,
    loading: state.estates.pending,
    error: new ErrorWrap(state.estates.error),
    settlementLoading: state.settlement.pending,
    estateCustomers: state.estatecustomers.entities,
    estateCustomersError: ErrorWrap.fromError(state.estatecustomers.error),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators({ getEstate, deleteEstateCustomer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EstateDetails);
