import { combineReducers } from "redux";
import { LOGOUT_SUCCESS, reducer as authReducer } from "./auth";
import { reducer as blocksReducer } from "./blocks";
import { reducer as estatesReducer } from "./estates";
import { reducer as estateCustomersReducer } from "./estate-customers";
import { reducer as headerReducer } from "./header";
import { reducer as noteReducer } from "./note";
import { reducer as boardPostReducer } from "./board-post";
import { reducer as fileReducer } from "./file";
import { reducer as fileDirReducer } from "./file-dir";
import { reducer as userReducer } from "./user";
import { reducer as auditLogReducer } from "./audit-log";
import { reducer as ticketReducer } from "./ticket";
import { reducer as ticketCommentReducer } from "./ticketComment";
import { reducer as profileReducer } from "./profile";
import { reducer as billingModelReducer } from "./billing-models";
import { reducer as billingAttrReducer } from "./billing-attributes";
import { reducer as billingForecastReducer } from "./billing-forecast";
import { reducer as billingSummaryReducer } from "./billing-summary";
import { reducer as billingMonthlyReportReducer } from "./billing-monthly-report";
import { reducer as chargeReducer } from "./charge";
import { reducer as depsReducer } from "./deps";
import { reducer as transactionReducer } from "./transaction";
import { reducer as fundTransactionReducer } from "./fundTransaction";
import { reducer as settlementReducer } from "./settlement";
import { reducer as collectionReducer } from "./collection";
import { reducer as collectionStatsReducer } from "./collection-stats";
import { reducer as fundReducer } from "./fund";
import { reducer as userRoleReducer } from "./user-role";
import { reducer as appSettingsReducer } from "./app-settings";

const appReducer = combineReducers({
  auth: authReducer,
  appSettings: appSettingsReducer,
  blocks: blocksReducer,
  estates: estatesReducer,
  estatecustomers: estateCustomersReducer,
  header: headerReducer,
  note: noteReducer,
  boardpost: boardPostReducer,
  file: fileReducer,
  fileDir: fileDirReducer,
  user: userReducer,
  userRole: userRoleReducer,
  auditLog: auditLogReducer,
  ticket: ticketReducer,
  ticketComment: ticketCommentReducer,
  profile: profileReducer,
  billingModel: billingModelReducer,
  billingAttr: billingAttrReducer,
  billingForecast: billingForecastReducer,
  billingSummary: billingSummaryReducer,
  billingMonthlyReport: billingMonthlyReportReducer,
  deps: depsReducer,
  charge: chargeReducer,
  transaction: transactionReducer,
  fundTransaction: fundTransactionReducer,
  settlement: settlementReducer,
  collection: collectionReducer,
  collectionStats: collectionStatsReducer,
  fund: fundReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
