import React from "react";

import {
  Button,
  Form,
  FormField,
  Input,
  Message,
  Segment,
} from "semantic-ui-react";
import { reset, resetPasswordConfirm } from "../../redux/modules/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { ErrorWrap } from "../../helpers/error";
import { routes } from "../../routes";
import { StyledLinks, StyledErrorSpan } from "./styled-components";
import { t } from "../../constant/translations";

type Props = {
  error?: Object,
  reset?: Function,
  setField?: Function,
  resetPasswordConfirm: Function,
  loading: Boolean,
};

class PasswordResetConfirmComponent extends React.Component<Props> {
  state = {
    data: {},
  };

  componentDidMount() {
    this.props.reset();
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.resetPasswordConfirm(
      this.state.data.password1,
      this.state.data.password2,
      this.props.match.params.uid,
      this.props.match.params.token
    );
  }

  setField(name, value) {
    const data = this.state.data || {};
    if (value) {
      data[name] = value;
    } else {
      delete data[name];
    }
    this.setState({ data: data });
  }

  render() {
    const error = new ErrorWrap(this.props.error);
    return (
      <Form
        action=""
        onSubmit={this.onSubmit.bind(this)}
        error={error.exists()}
      >
        <Segment>
          <FormField>
            <Input
              name="password1"
              icon="key"
              iconPosition="left"
              placeholder={t("login.password")}
              type="password"
              error={error.has("new_password1")}
              onChange={(event) =>
                this.setField("password1", event.target.value)
              }
            />
            {error.has("new_password1") && (
              <StyledErrorSpan>{error.get("new_password1")}</StyledErrorSpan>
            )}
          </FormField>
          <FormField>
            <Input
              name="password2"
              icon="key"
              iconPosition="left"
              placeholder={t("login.repeat_password")}
              type="password"
              error={error.has("new_password2")}
              onChange={(event) =>
                this.setField("password2", event.target.value)
              }
            />
            {error.has("new_password2") && (
              <StyledErrorSpan>{error.get("new_password2")}</StyledErrorSpan>
            )}
          </FormField>
          <StyledLinks>
            <Link to={routes.authLogin}>{t("login.tologin")}</Link>
          </StyledLinks>

          <Message error content={error.getGlobal()} />
          <Button
            type="submit"
            fluid
            size="large"
            loading={this.props.loading}
            disabled={!(this.state.data.password1 && this.state.data.password2)}
          >
            {t("labels.save")}
          </Button>
        </Segment>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    userKey: state.auth.userKey,
    loading: state.auth.loggingIn,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const resetConfirmAndRedirect = (...data) => {
    return (dispatch) => {
      return dispatch(resetPasswordConfirm(...data)).then(() =>
        dispatch(ownProps.history.push(routes.authLogin))
      );
    };
  };

  return bindActionCreators(
    { resetPasswordConfirm: resetConfirmAndRedirect, reset },
    dispatch
  );
};

export const PasswordResetConfirm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetConfirmComponent);
