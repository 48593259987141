import React from "react";
import { ConfirmModalAction } from "../../../components/common/confirm-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteSettlement } from "../../../redux/modules/settlement";
import { getEstate } from "../../../redux/modules/estates";
import { ErrorWrap } from "../../../helpers/error";
import { t } from "../../../constant/translations";

function DeleteSettlementComponent({ error, loading, action, settlement }) {
  return (
    <ConfirmModalAction
      header={
        t("settlement.delete_payment") + ` ${settlement.estate_details.name}`
      }
      content={
        t("settlement.delete_confirmation") +
        ` ${settlement.deposit_amount} ` +
        t("resources.bgn") +
        ` ` +
        t("settlement.to") +
        ` ${settlement.estate_details.name} ?`
      }
      trigger_props={{ color: "red", icon: "delete", size: "tiny" }}
      error={error}
      loading={loading}
      action={() => action(settlement)}
      confirmLabel={t("userActions.delete")}
    />
  );
}

function mapStateToProps(state) {
  return {
    loading: state.settlement.pending,
    error: ErrorWrap.fromError(state.settlement.error),
  };
}

function mapDispatchToProps(dispatch) {
  const deleteAction = (data) => (dispatch) => {
    return dispatch(deleteSettlement(data)).then(
      (response) => !response.error && dispatch(getEstate(data.estate))
    );
  };

  return bindActionCreators({ action: deleteAction }, dispatch);
}

export const DeleteSettlementAction = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSettlementComponent);
