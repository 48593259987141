import styled from "styled-components";

import * as React from "react";
import { Dropdown, List } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { deleteFile, uploadFiles } from "../../redux/modules/file";
import { connect } from "react-redux";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { t } from "../../constant/translations";

const StyledListItem = styled(List.Item)`
  margin: 10px 0;
  > i {
    padding-left: 20px;
  }
`;

const StyledListContent = styled(List.Content)`
  width: 100%;
`;

const StyledDropdown = styled(Dropdown)`
  float: right;
`;

export class FileListComponent extends React.Component {
  render() {
    const files = this.props.files;
    const listItems = files.map((file) => {
      return (
        <StyledListItem key={file.id}>
          <List.Icon name={"file"} />
          <StyledListContent>
            <a href={file.content} download={true} target={"_blank"}>
              {file.filename}
            </a>

            <RoleRequired staff={true} permission={permissions.boardEdit}>
              <StyledDropdown icon={"ellipsis vertical"} direction={"left"}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={t("userActions.edit")}
                    onClick={(event) => {}}
                  />
                  <Dropdown.Item
                    text={t("userActions.delete")}
                    onClick={() => {
                      this.props.deleteFile(file);
                    }}
                  />
                </Dropdown.Menu>
              </StyledDropdown>
            </RoleRequired>
          </StyledListContent>
        </StyledListItem>
      );
    });
    return (
      <List divided selection>
        {listItems}
      </List>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ uploadFiles, deleteFile }, dispatch);
}

export const FileList = connect(
  undefined,
  mapDispatchToProps
)(FileListComponent);
