import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ErrorWrap } from "../../helpers/error";
import { FormField, Input } from "semantic-ui-react";
import React from "react";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import type { FormProps } from "../../components/common/edit-entity";
import { EditEntity } from "../../components/common/edit-entity";
import { updateFileDir } from "../../redux/modules/file-dir";
import { t } from "../../constant/translations";

export class FolderEditFormComponent extends React.Component<FormProps> {
  state = {
    formData: null,
  };

  render() {
    const { error } = this.props;
    const initialData = getInitialState(
      this.props.entity,
      this.props.initialData
    );
    const manager = new FormdataManager(
      this.state.formData || initialData,
      (formData) => this.setState({ formData }),
      initialData
    );
    return (
      <EditEntity
        manager={manager}
        header={t("file.change_directory")}
        useTextTrigger={true}
        {...this.props}
      >
        <FormField>
          <label>{t("labels.name")}</label>
          <Input
            placeholder={t("labels.name")}
            {...manager.getAttributes("name")}
          />
        </FormField>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.fileDir.error),
    loading: state.fileDir.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ update: updateFileDir }, dispatch);
}

export const FolderEditForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderEditFormComponent);
