import React from "react";
import md5 from "md5";
import querystring from "query-string";

const BASE_URL = `https://www.gravatar.com/avatar/`;

// TODO: Change Gravatar link on hover.

type Props = {
  email: string,
  size?: number,
  defaultImage?: string,
  src: ?string,
};

export const getGravatarSrc = (
  email,
  emailHash,
  size = 160,
  defaultImage = "mp"
) => {
  const query = querystring.stringify({
    s: size,
    d: defaultImage,
  });

  const hash = emailHash || md5(email, { encoding: "binary" });
  return `${BASE_URL}${hash}?${query}`;
};

export const Avatar = (props: Props) => {
  const {
    email,
    emailHash,
    as = "img",
    size = 160,
    defaultImage = "mp",
    src = null,
    ...rest
  } = props;

  return React.createElement(as, {
    src: src || getGravatarSrc(email, emailHash, size, defaultImage),
    width: size,
    height: size,
    alt: `Gravatar`,
    ...rest,
  });
};
