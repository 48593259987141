import dayjs from "dayjs";
import styled from "styled-components";

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Button,
  Container,
  Feed,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Segment,
  Table,
  Divider,
} from "semantic-ui-react";

import { UserEdit } from "./edit";
import { ErrorWrap } from "../../../helpers/error";
import { BackButton } from "../../common/back-button";
import { RoleRequired } from "../../auth/role-required";
import { Pagination } from "../../../components/common/pagination";
import { sliceList } from "../../../helpers/filter";

import { t } from "../../../constant/translations";
import type User from "../../../redux/modules/user";
import { fetchUsers } from "../../../redux/modules/user";
import type { AuditLogEntry } from "../../../redux/modules/audit-log";
import { fetchAuditLog } from "../../../redux/modules/audit-log";
import { logEntryTitle, MAP_PARAMS_TO_VALUE_LABEL_PAIRS } from "./helpers";
import { getGravatarSrc } from "../../../components/common/avatar";
import { permissions } from "../../../constant/permissions";

type Props = {
  users: ?Array<User>,
  log: ?Array<AuditLogEntry>,
  error: Object,
  loading: boolean,
  fetchDeps: Function,
};

const MAP_ACTION_TO_ICON = {
  update: "cut",
  create: "plus",
  destroy: "trash",
  reconcile: "euro",
};

const MAP_ACTION_TO_COLOR = {
  update: "yellow",
  create: "green",
  destroy: "red",
  reconcile: "blue",
};

const FeedSummary = styled(Feed.Summary)`
  & > a {
    vertical-align: baseline;
  }
`;

const StyledFirstName = styled.span`
  color: ${(props) => props.theme.primary};
`;

class TeamDetailsContainer extends Component<Props> {
  state = {
    expanded: {},
    currentPage: 1,
    pageSize: 25,
  };

  handleToggleExpanded = (id) =>
    this.setState({
      expanded: {
        ...this.state.expanded,
        [id]: !this.state.expanded[id],
      },
    });

  getUserId() {
    return parseInt(this.props.match.params.userId);
  }

  componentDidMount() {
    this.props.fetchDeps(this.getUserId());
  }

  renderParamsTable(logEntry) {
    const responseValueLabelPairs = Object.entries(
      MAP_PARAMS_TO_VALUE_LABEL_PAIRS
    )
      .filter(([p]) => logEntry.response[p])
      .map(([p, f]) => f(logEntry.response[p]));

    let body;
    body = (
      <Table.Row>
        {responseValueLabelPairs.map(({ label, value }) => (
          <Table.Cell key={label} collapsing={true}>
            {value}
          </Table.Cell>
        ))}
      </Table.Row>
    );

    return (
      <Table celled striped fixed singleLine>
        <Table.Header>
          <Table.Row>
            {responseValueLabelPairs.map(({ label }) => (
              <Table.HeaderCell key={label}>{label}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{body}</Table.Body>
      </Table>
    );
  }

  render() {
    const { users, log, error, loading } = this.props;

    if (error.exists()) {
      return <div>{error.getGlobal()}</div>;
    }

    if (!users || !log) {
      return <Loader active />;
    }

    if (loading) {
      return <Loader active />;
    }

    const page = sliceList(
      log,
      this.state.currentPage - 1,
      this.state.pageSize
    );

    const userId = this.getUserId();
    const user = users.find((u) => u.id === userId);

    const renderFeeds = page.map((rec) => {
      return (
        <Feed.Event key={rec.id}>
          <Feed.Label>
            <Icon
              name={MAP_ACTION_TO_ICON[rec.action]}
              color={MAP_ACTION_TO_COLOR[rec.action]}
            />
          </Feed.Label>
          <Feed.Content>
            <Feed.Date>
              {dayjs(rec.created_at).format("YYYY-MM-DD HH:mm")}
            </Feed.Date>
            <FeedSummary>
              <StyledFirstName>{user.first_name}</StyledFirstName>{" "}
              {logEntryTitle(rec)}
              <Button
                basic
                floated={"right"}
                size={"tiny"}
                onClick={(_) => this.handleToggleExpanded(rec.id)}
              >
                {t("management.more")}{" "}
                <Icon
                  name={`caret ${this.state.expanded[rec.id] ? "up" : "down"}`}
                />
              </Button>
            </FeedSummary>
            {this.state.expanded[rec.id] && this.renderParamsTable(rec)}
          </Feed.Content>
        </Feed.Event>
      );
    });

    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column widescreen={3} computer={6} tablet={16}>
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  <BackButton />
                  <RoleRequired admin={true} permission={permissions.teamEdit}>
                    <UserEdit entity={user} />
                  </RoleRequired>
                  <Segment.Group>
                    <Segment>
                      <Image
                        size="medium"
                        rounded={true}
                        src={
                          user.profile_picture
                            ? user.profile_picture.url
                            : getGravatarSrc(user.email, null, 300)
                        }
                      />
                      <Header
                        as="h4"
                        content={`${user.first_name} ${user.last_name}`}
                        subheader={`${user.email} (${t(`roles.${user.role}`)})`}
                      />
                    </Segment>
                  </Segment.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column widescreen={13} computer={10} tablet={16}>
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  {loading ? (
                    <Loader active />
                  ) : (
                    <Container>
                      <Header as="h2">{t("management.activity_report")}</Header>
                      <Pagination
                        current={this.state.currentPage}
                        total={log.length}
                        onChange={(currentPage, pageSize) => {
                          this.setState({ currentPage, pageSize });
                        }}
                        pageSize={this.state.pageSize}
                      />
                      <Feed>{renderFeeds}</Feed>
                    </Container>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDeps: (userId) => (dispatch) =>
        Promise.all([
          dispatch(fetchUsers(userId)),
          dispatch(fetchAuditLog(userId)),
        ]),
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    log: state.auditLog.entities,
    users: state.user.entities,
    error: ErrorWrap.fromError(state.user.error || state.auditLog.error),
    loading: state.user.pending || state.auditLog.pending,
  };
};

export const TeamMemberDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamDetailsContainer)
);
