import React from "react";
import { Badge } from "../common/badge";
import { t } from "../../constant/translations";

export const BlockConfigDash = ({ block }) => {
  const config = block.config;
  return (
    <div>
      {config.board && config.board.enabled && (
        <Badge
          content={t("blockdash.virt_dash")}
          icon={"columns"}
          color={"green"}
        />
      )}
      {config.billing && config.billing.enabled && (
        <Badge
          content={t("blockdash.auto_gen")}
          icon={"history"}
          color={"black"}
        />
      )}
      {config.billing && config.billing.settlement_enabled && (
        <Badge
          content={t("blockdash.online_payments")}
          icon={"payment"}
          color={"blue"}
        />
      )}
      {config.billing && config.billing.charge_emails_enabled && (
        <Badge
          content={t("blockdash.notifications")}
          icon={"bell"}
          color={"pink"}
        />
      )}
    </div>
  );
};
