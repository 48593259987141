import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";

import ProfileList from "../profile/list";
import { TicketPage } from "../ticket/page";
import BlockDetails from "./block-details";
import TicketDetails from "../ticket/details";
import EstateDetails from "../estate/details";
import { BlockBilling as BlockBillingPage } from "../billing/block-billing";

import { routes } from "../../routes";
import { clearSelectedBlock, selectBlock } from "../../redux/modules/blocks";
import { BlockEstateList } from "../estate/list";
import { Loader } from "semantic-ui-react";
import { fetchEstateCustomers } from "../../redux/modules/estate-customers";
import { fetchBoardPosts } from "../../redux/modules/board-post";
import { fetchProfiles } from "../../redux/modules/profile";
import { fetchFiles } from "../../redux/modules/file";
import { fetchBillingSummary } from "../../redux/modules/billing-summary";
import { listMonthlyReports } from "../../redux/modules/billing-monthly-report";
import { getById } from "../../redux/restdux/utils";

class Container extends Component {
  shouldReload = () => {
    if (!this.props.blockExists) {
      return false;
    }

    return !this.props.block || this.props.block.id !== this.props.blockId;
  };

  componentDidMount() {
    if (this.shouldReload()) {
      this.props.selectBlock(this.props.blockId);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (prevProps.blockId !== this.props.blockId) {
      this.props.selectBlock(this.props.blockId);
    }
  }

  render() {
    if (this.shouldReload()) {
      return <Loader active={true} />;
    }

    if (!this.props.blockExists) {
      return <div></div>;
    }

    return (
      <Switch>
        <Route
          exact={true}
          path={routes.blockDetails}
          component={BlockDetails}
        />
        <Route path={routes.blockProfiles} component={ProfileList} />
        <Route path={routes.blockEstateList} component={BlockEstateList} />
        <Route path={routes.estateDetails} component={EstateDetails} />
        <Route path={routes.blockBilling} component={BlockBillingPage} />
        <Route path={routes.blockTickets} component={TicketPage} />
        <Route path={routes.ticketDetails} component={TicketDetails} />
      </Switch>
    );
  }
}

const ConnectedContainer = withRouter(
  connect(
    (state, passedProps) => {
      const blockId = parseInt(passedProps.match.params.blockId);
      const blockList = state.blocks.entities || [];
      return {
        blockId: blockId,
        block: state.blocks.selected,
        blockExists: getById(blockId, blockList) !== undefined,
      };
    },
    (dispatch) => {
      const selectBlockWithDeps = (blockId) => (dispatch) => {
        return Promise.all(
          [
            clearSelectedBlock(blockId),
            fetchEstateCustomers(blockId),
            fetchBoardPosts(blockId),
            fetchProfiles(blockId),
            fetchFiles(blockId),
            fetchBillingSummary(blockId),
            listMonthlyReports(blockId),
          ].map(dispatch)
        ).then(() => dispatch(selectBlock(blockId)));
      };

      return bindActionCreators({ selectBlock: selectBlockWithDeps }, dispatch);
    }
  )(Container)
);

export default ConnectedContainer;
