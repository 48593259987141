import * as React from "react";
import { CancelTransactionFormComponent } from "../transaction/cancel-transaction-form";
import { ErrorWrap } from "../../../helpers/error";
import { cancelTransaction } from "../../../redux/modules/transaction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSettlement } from "../../../redux/modules/settlement";

export const SettlementCancelTransactionFormComponent = ({
  entity,
  loading,
  action,
  error,
}) => {
  return (
    <CancelTransactionFormComponent
      entity={entity}
      loading={loading}
      action={action}
      error={error}
    />
  );
};

function mapStateToProps(state) {
  return {
    loading: state.transaction.pending,
    error: ErrorWrap.fromError(state.transaction.error),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const cancelTransactionAndRefreshSettlement = (data) => {
    dispatch(cancelTransaction(data)).then((resp) => {
      if (!resp.error) {
        dispatch(getSettlement({ id: ownProps.settlementId }, true));
      }
    });
  };

  return bindActionCreators(
    {
      action: cancelTransactionAndRefreshSettlement,
    },
    dispatch
  );
}

export const SettlementCancelTransactionForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementCancelTransactionFormComponent);
