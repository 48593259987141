import styled from "styled-components";
import React from "react";
import { Button, Segment } from "semantic-ui-react";
import { routes } from "../../routes";
import { Link } from "react-router-dom";
import { t } from "../../constant/translations";

const StyledTextBlock = styled.div`
  font-size: 1.2rem;
`;

const Text = styled.p`
  text-align: center;
`;

const StyledLink = styled(Link)`
  margin: 1rem 0;
  display: block;
`;

export const LoginSuccess = () => {
  return (
    <Segment textAlign={"center"}>
      <StyledTextBlock>
        <Text>{t("login.instruction_mail")}</Text>
        <Text>{t("login.nomail_info")}</Text>
        <StyledLink to={routes.authPasswordReset}>
          {t("login.pass_manage")}
        </StyledLink>
        <Button
          href={routes.authLogin}
          icon={"check"}
          primary={true}
          content={t("login.understood")}
        />
      </StyledTextBlock>
    </Segment>
  );
};
