import React from "react";
import { Input } from "semantic-ui-react";
import { useQueryParam } from "use-query-params";
import { t } from "../../constant/translations";

export const SearchInput = ({ replace, placeholder, className }) => {
  const defaultSetFunc = replace ? "replace" : "pushIn";

  const [query, setQuery] = useQueryParam("q");
  const iconProps = { name: "search", onClick: () => {} };
  if (query) {
    iconProps.name = "close";
    iconProps.link = true;
    iconProps.onClick = () => setQuery("", defaultSetFunc);
  }
  return (
    <Input
      value={query || ""}
      icon={iconProps}
      placeholder={placeholder || t("labels.search")}
      onChange={(event) => setQuery(event.target.value, defaultSetFunc)}
      className={className}
    />
  );
};

export const getSearchQ = () => {
  const [query, setQuery] = useQueryParam("q");
  return query;
};
