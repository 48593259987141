import { addEntity, removeEntity } from "./utils";

type Action = {
  type: string,
  result: ?Object,
  error: ?Object,
  entity: ?Object,
  pushToFront: ?boolean,
  inBackground: ?boolean,
};

export function getReducer(actionTypes, initialState, paginated = false) {
  const reducer = (state = initialState, action: Action) => {
    switch (action.type) {
      case actionTypes.CLEAR_ERROR:
        return { ...state, error: {} };
      case actionTypes.LIST_PENDING:
        return {
          ...state,
          entities: null,
          pending: !action.inBackground,
          error: {},
        };
      case actionTypes.FETCH_PENDING:
      case actionTypes.CREATE_PENDING:
      case actionTypes.UPDATE_PENDING:
      case actionTypes.DELETE_PENDING:
        return {
          ...state,
          pending: !action.inBackground,
          error: {},
          lastModified: null,
        };

      case actionTypes.LIST_SUCCESS:
        const content = {};
        if (paginated) {
          content.entities = action.result.results;
          content.meta = {
            count: action.result.count,
            next: action.result.next,
            prev: action.result.previous,
          };
        } else {
          content.entities = action.result;
          content.meta = {
            count: action.result.length,
            next: null,
            prev: null,
          };
        }

        return { ...state, pending: false, error: {}, ...content };

      case actionTypes.FETCH_SUCCESS:
        return {
          ...state,
          pending: false,
          entities: addEntity(
            action.result,
            state.entities,
            action.pushToFront
          ),
        };
      case actionTypes.CREATE_SUCCESS:
      case actionTypes.UPDATE_SUCCESS:
        return {
          ...state,
          pending: false,
          entities: addEntity(
            action.result,
            state.entities,
            action.pushToFront
          ),
          lastModified: action.result,
        };
      case actionTypes.LIST_ERROR:
      case actionTypes.FETCH_ERROR:
      case actionTypes.CREATE_ERROR:
      case actionTypes.UPDATE_ERROR:
      case actionTypes.DELETE_ERROR:
        return {
          ...state,
          pending: false,
          error: { "@type": action.type, ...action.error },
        };
      case actionTypes.DELETE_SUCCESS:
        if (action.result) {
          return {
            ...state,
            pending: false,
            entities: addEntity(
              action.result,
              state.entities,
              action.pushToFront
            ),
            lastModified: action.result,
          };
        }
        return {
          ...state,
          pending: false,
          error: {},
          entities: removeEntity(action.entity, state.entities),
          lastModified: action.entity,
        };
      default:
        return state;
    }
  };

  return (state = initialState, action) => {
    const result = reducer(state, action);

    if (
      action.namespace &&
      Object.values(actionTypes).indexOf(action.type) !== -1
    ) {
      return { ...state, [action.namespace]: result };
    }
    return result;
  };
}
