import React, { useEffect, useState } from "react";
import { Segment, Message, Divider, List } from "semantic-ui-react";
import { connect } from "react-redux";
import { fetchTickets } from "../../../redux/modules/ticket";
import { bindActionCreators } from "redux";
import { DashboardTicketEntity } from "../../ticket/ticket-dashboard-item";
import { usePagination } from "../../../components/common/pagination";
import { t } from "../../../constant/translations";

const ActiveSignalsList = ({
  user,
  block,
  fetchDependencies,
  tickets = [],
}) => {
  const [votes, setVotes] = useState([]);
  const itemsPerPage = 5;

  useEffect(() => {
    fetchDependencies({
      block: block.id,
      status: "open",
    });
  }, [block.id, fetchDependencies]);

  useEffect(() => {
    setVotes(
      tickets.map((t) => ({
        id: t.id,
        upvotes_count: t.upvotes_count,
        voted: false,
      }))
    );
  }, [tickets]);

  const handleVote = (ticket_id) => {
    setVotes((prevVotes) => {
      const updatedVotes = prevVotes.map((vote) =>
        vote.id === ticket_id
          ? {
              ...vote,
              upvotes_count: vote.upvotes_count + (vote.voted ? -1 : 1),
              voted: !vote.voted,
            }
          : vote
      );
      return updatedVotes;
    });
  };

  const sortedTickets = [...tickets]
    .map((ticket) => ({
      ...ticket,
      upvotes_count:
        votes.find((vote) => vote.id === ticket.id)?.upvotes_count ||
        ticket.upvotes_count,
    }))
    .sort((a, b) => b.upvotes_count - a.upvotes_count);

  const [page, paginationComponent] = usePagination(sortedTickets, 5);

  return (
    <Segment>
      {tickets.length > 0 ? (
        <>
          {tickets.length > itemsPerPage && paginationComponent}
          <List divided relaxed>
            {page.map((ticket) => {
              const ticketVote = votes.find(
                (vote) => vote.id === ticket.id
              ) || {
                upvotes_count: 0,
                voted: false,
              };

              return (
                <DashboardTicketEntity
                  entity={ticket}
                  user={user}
                  handleVote={handleVote}
                  vote={ticketVote}
                  key={ticket.id}
                />
              );
            })}
          </List>
          <Divider />
        </>
      ) : (
        <p style={{ fontStyle: "italic" }}>
          {t("dashboard.no.signals.paragraph")}
        </p>
      )}
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    tickets: state.ticket.entities || [],
    entity: {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDependencies: (params) => (dispatch) =>
        Promise.all([dispatch(fetchTickets(params))]),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSignalsList);
