import React, { useState } from "react";
import { Button, Divider, Form, Icon, Menu, Sidebar } from "semantic-ui-react";
import styled from "styled-components";
import { useQueryParams } from "use-query-params";
import { t } from "../../constant/translations";

const StyledSideForm = styled.div`
  margin: 15px 15px;
  text-align: left;
  max-width: 100%;
`;

const StyledLabel = styled.span`
  margin-left: 0.8rem;
  font-size: 0.8rem;
  line-height: normal;
  font-weight: bolder;
  background-color: ${(props) => props.theme.primary};
  border-radius: 1000rem;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
  color: white;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
function getActiveQueries(query) {
  return Object.values(query).filter((el) => el).length;
}

export const ContentFilterTrigger = ({
  onClick,
  media,
  queryParamConfig,
  style,
}) => {
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const filterNum = getActiveQueries(query);
  return (
    <Button size="tiny" basic={true} onClick={onClick} style={style}>
      <Icon name={"filter"} />
      {media.handheld ? <span>{t("labels.filters")}</span> : null}
      {filterNum !== 0 && <StyledLabel>{filterNum}</StyledLabel>}
    </Button>
  );
};

export const ContentFilterForm = ({ onApply, queryParamConfig, FormBody }) => {
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const [state, setState] = useState(query);
  const onChange = (fieldName) => (event, data) => {
    const newState = { ...state };
    if (!data.value && !data.checked) {
      delete newState[fieldName];
    } else {
      newState[fieldName] = data.value || data.checked.toString();
    }
    setState(newState);
  };

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        setQuery(state, "replace");
        onApply && onApply();
      }}
    >
      <FormBody data={state} onChange={onChange} />
      <Divider />
      <FlexSpaceBetween>
        <Button type="submit">{t("labels.apply")}</Button>
        {!!getActiveQueries(query) && (
          <Button
            onClick={(event) => {
              event.preventDefault();
              setState({});
              setQuery({}, "replace");
              onApply && onApply();
            }}
            negative={true}
          >
            {t("labels.remove")}
          </Button>
        )}
      </FlexSpaceBetween>
    </Form>
  );
};

export const ContentFilterPanel = ({
  open,
  onApply,
  queryParamConfig,
  FormBody,
  children,
  ...props
}) => {
  return (
    <Sidebar.Pushable {...props}>
      <Sidebar
        as={Menu}
        animation="overlay"
        direction="right"
        visible={open}
        icon="labeled"
        size="huge"
        vertical
      >
        <StyledSideForm>
          <h5>{t("labels.filters")}</h5>
          <ContentFilterForm
            onApply={onApply}
            queryParamConfig={queryParamConfig}
            FormBody={FormBody}
          />
        </StyledSideForm>
      </Sidebar>
      <Sidebar.Pusher>{children}</Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export const isFiltered = (queryParamConfig) => {
  const [query, setQuery] = useQueryParams(queryParamConfig);
  return Object.values(query).filter((entry) => !!entry).length > 0;
};
