//@flow
import React from "react";
import { Button, Modal } from "semantic-ui-react";
import Notes from "./notes";
import { t } from "../../constant/translations";

type Props = {
  entity: any,
};

export const NotesModal = (props: Props) => {
  return (
    <Modal
      closeIcon={true}
      trigger={
        <Button
          basic
          color={props.entity.notes_num ? "blue" : null}
          size={"tiny"}
          icon={props.entity.notes_num ? "sticky note" : "sticky note outline"}
          title={t("block.notes")}
        />
      }
    >
      <Modal.Header>{t("block.notes")}</Modal.Header>
      <Modal.Content>
        <Notes entity={props.entity} entityType={"estate"} />
      </Modal.Content>
    </Modal>
  );
};
