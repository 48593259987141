import { newApi } from "../restdux/index";

export const billingForecastManager = newApi(
  "billing-forecast",
  "/api/billing-forecast/"
);
export const reducer = billingForecastManager.reducer;

export function listBillingForecast(blockId) {
  return billingForecastManager.actions.list({ block: blockId });
}

export function getBillingForecast(estateId) {
  return billingForecastManager.actions.get({ id: estateId });
}
