// @flow
import * as React from "react";
import { getUrlParams } from "../components/common/urlparams";
import qs from "qs";

type SortingParams = {
  location: Object,
  history: Object,
};

export const withSorting = (WrappedComponent: React.Node) => {
  return (params: SortingParams) => {
    const sortBy = buildSorter(params);
    const sortColumn = buildSortColumn({ sortBy, ...params });
    return (
      <WrappedComponent {...params} sortBy={sortBy} sortColumn={sortColumn} />
    );
  };
};

export const buildSorter = ({ location, history }: SortingParams): Function => {
  return (key: string): void => {
    const params = getUrlParams(location);
    const currentOrder = params.ordering;
    let newOrder = key;
    if (currentOrder === key) {
      newOrder = `-${key}`;
    }
    params.ordering = newOrder;

    if (currentOrder === `-${key}`) {
      delete params.ordering;
    }

    history.push({
      pathname: location.pathname,
      search: qs.stringify(params, { addQueryPrefix: true }),
    });
  };
};

export const buildSortColumn = ({ sortBy, location }: Object): Function => {
  return (key: string): Object => {
    const sortColumn = getUrlParams(location).ordering;
    const direction =
      sortColumn && sortColumn[0] === "-" ? "descending" : "ascending";
    const columnKey = sortColumn && sortColumn.replace(/^-/g, "");

    return {
      onClick: () => sortBy(key),
      sorted: columnKey === key ? direction : null,
    };
  };
};
