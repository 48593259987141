import { useState } from "react";

export function getByPath(obj, path) {
  let currentPosition = obj;
  for (const element of path.split(".")) {
    if (currentPosition[element]) {
      currentPosition = currentPosition[element];
    } else {
      currentPosition = null;
      break;
    }
  }
  return currentPosition;
}

export function setByPath(obj, path, value) {
  let currentPosition = obj;
  const elements = path.split(".");
  elements.forEach((key, idx) => {
    if (idx + 1 === elements.length) {
      currentPosition[key] = value;
    } else {
      currentPosition[key] = currentPosition[key] || {};
      currentPosition = currentPosition[key];
    }
  });
}

export const getInitialState = (entity, initialData = {}) => {
  if (entity) {
    return { ...initialData, ...entity };
  }
  return initialData;
};

export class FormdataManager {
  constructor(formData, setAction, initialState = {}, onDataChange) {
    this.formData = formData;
    this.setAction = setAction;
    this.initialState = initialState;
    this.onDataChange = onDataChange;
    this.error = null;
  }

  withError(error) {
    this.error = error;
    return this;
  }

  static useState(initialState, onDataChange) {
    const [formData, setFormData] = useState(initialState);
    return new FormdataManager(
      { ...formData },
      setFormData,
      initialState,
      onDataChange
    );
  }

  setValue(name, value) {
    setByPath(this.formData, name, value);
    this.setAction(this.formData);
    if (this.onDataChange) {
      this.onDataChange();
    }
  }

  setFormData(formData) {
    this.formData = formData;
    this.setAction(this.formData);
  }

  getValue(name, defaultValue = "") {
    const value = getByPath(this.formData, name);
    if (value === null || typeof value === "undefined") {
      return defaultValue;
    }
    return value;
  }

  getAttributes(name, other = {}) {
    const err = this.error && this.error.exists();
    return {
      name: name,
      onChange: (event, data) => {
        let value = event && event.target && event.target.value;
        if (data && data.value !== undefined) {
          value = data.value;
        }
        this.setValue(name, value);
      },
      value: this.getValue(name),
      error: err ? this.error.get(name) : undefined,
      ...other,
    };
  }

  clear() {
    this.formData = { ...this.initialState };
    this.setAction(this.formData);
  }
}
