import * as React from "react";
import { Button } from "semantic-ui-react";
import { apirequest } from "./api-client";
import qs from "qs";

export class DownloadLink extends React.Component {
  state = {
    loading: false,
    request: null,
  };

  urlWithoutPageParams(url) {
    const [base, paramStr] = url.split(/\?(.*)/);
    const params = qs.parse(paramStr);
    delete params.page;
    delete params.pageSize;
    return `${base}?${qs.stringify(params)}`;
  }

  onClick = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const req = apirequest("get", this.urlWithoutPageParams(this.props.href))
      .responseType("blob")
      .end((err, res) => {
        this.setState({ loading: false, request: null });
        const _URL = window.URL || window.webkitURL;
        const url = _URL.createObjectURL(new Blob([res.body]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.download);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    this.setState({ request: req });
  };

  componentWillUnmount() {
    if (this.state.request) {
      this.state.request.abort();
    }
  }

  render() {
    let { children, component, ...props } = this.props;
    return (
      <Button
        {...props}
        onClick={this.onClick}
        disabled={this.state.loading || props.disabled}
        loading={this.state.loading}
      >
        {children}
      </Button>
    );
  }
}
