import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { t } from "../../../constant/translations";

const FundsPicker = ({ funds, active, setSelectedFunds }) => {
  const fundsOptions = () => {
    return funds.map((fund) => ({
      key: fund.id,
      text: fund.name,
      value: fund.id,
    }));
  };

  const handleDropdownSelect = (event, data) => {
    setSelectedFunds(data.value);
  };

  if (active)
    return (
      <Form.Input label={t("labels.chosen_funds")} fluid>
        <Dropdown
          additionLabel={t("labels.chosen_funds")}
          labeled
          placeholder={t("placeholders.all_funds")}
          fluid
          multiple
          search
          selection
          onChange={handleDropdownSelect}
          options={fundsOptions()}
        />
      </Form.Input>
    );
  else return null;
};

export default FundsPicker;
