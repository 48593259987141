import { defaultInitialState, newApi } from "../restdux/index";
import { getById, getSpecificError } from "../restdux/utils";

export const SELECT = "mecasa/blocks/SELECT";
export const UNSELECT = "mecasa/blocks/UNSELECT";

const initialState = {
  ...defaultInitialState,
  selected: null,
};

export const blockManager = newApi("block", "/api/block/");

export function reducer(oldState = initialState, action = {}) {
  let state = blockManager.reducer(oldState, action);
  switch (action.type) {
    case blockManager.actionTypes.UPDATE_SUCCESS:
      let currentSelected = state.selected;
      if (currentSelected) {
        currentSelected = getById(currentSelected.id, state.entities || []);
      }
      return { ...state, selected: currentSelected };
    case SELECT:
      return {
        ...state,
        status: SELECT,
        selected:
          action.blockID !== null &&
          getById(action.blockID, state.entities || []),
      };
    case UNSELECT:
      return {
        ...state,
        selected: null,
      };
    default:
      return state;
  }
}

export function selectBlock(blockID) {
  return { type: SELECT, blockID: blockID !== null && parseInt(blockID) };
}

export function clearSelectedBlock() {
  return { type: UNSELECT };
}

export function getBlock(blockID, inBackground = false) {
  return blockManager.actions.get({ id: blockID }, { inBackground });
}

export function getListError(blockState) {
  return getSpecificError(blockManager.actionTypes.LIST_ERROR, blockState);
}

export function updateBlock(data) {
  return blockManager.actions.update(data);
}

export function deactivateBlock(data) {
  return blockManager.actions.update({ ...data, active: false });
}

export function deleteBlock(data) {
  return blockManager.actions.del({ ...data });
}

export const clearError = blockManager.actions.clearError;
