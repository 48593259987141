import * as Sentry from "@sentry/browser";
import GoogleAnalytics from "react-ga4";

const SET_FIELD = "app/auth/setField";
const RESET = "app/auth/reset";
const LOAD = "app/auth/LOAD";
const LOAD_SUCCESS = "app/auth/LOAD_SUCCESS";
const LOAD_FAIL = "app/auth/LOAD_FAIL";
const LOGIN = "app/auth/LOGIN";
const LOGIN_SUCCESS = "app/auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "app/auth/LOGIN_FAIL";
const LOGOUT = "app/auth/LOGOUT";
export const LOGOUT_SUCCESS = "app/auth/LOGOUT_SUCCESS";
const LOGOUT_FAIL = "app/auth/LOGOUT_FAIL";
const CHANGE_PASSWORD = "app/auth/CHANGE_PASSWORD";
const CHANGE_PASSWORD_FAIL = "app/auth/CHANGE_PASSWORD_FAIL";
const CHANGE_PASSWORD_SUCCESS = "app/auth/CHANGE_PASSWORD_SUCCESS";

const initialState = {
  loaded: false,
  error: null,
  loginComplete: false,
};

function wrapUser(user) {
  user.hasPermission = (permission) => {
    return user.permissions.length > 0 && user.permissions.includes(permission);
  };
  return user;
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET:
      return { ...initialState };
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      const userData = action.result;

      Sentry.setUser(userData);
      GoogleAnalytics.set({ userId: userData.id, userEmail: userData.email });
      return {
        ...state,
        loading: false,
        loaded: true,
        user: wrapUser(userData),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };

    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        error: null,
        loginComplete: false,
      };
    case LOGIN_SUCCESS:
      return {
        loggingIn: false,
        userKey: action.result.key,
        error: null,
        loginComplete: true,
      };
    case LOGIN_FAIL:
      return {
        loggingIn: false,
        user: null,
        userKey: null,
        error: action.error,
        loginComplete: false,
      };
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        userKey: null,
        loggingOut: false,
        user: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        error: action.error,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function setField(fieldName, value) {
  return {
    type: SET_FIELD,
    fieldName: fieldName,
    value: value,
  };
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get("/api/auth/user/"),
  };
}

export function login(email, password) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("/api/auth/login/", {
        data: {
          email,
          password,
          csrfmiddlewaretoken: window.csrftoken,
        },
      }),
  };
}

export function loginNoPWD(email, password) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("/api/auth/email-login/", {
        data: {
          email,
          password,
          csrfmiddlewaretoken: window.csrftoken,
        },
      }),
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: (client) => client.post("/api/auth/logout/"),
  };
}

export function register(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("/api/auth/registration/", {
        data: {
          ...data,
          csrfmiddlewaretoken: window.csrftoken,
        },
      }),
  };
}

export function reset() {
  return { type: RESET };
}

export function resetPassword(email, password1, password2) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("/api/auth/password/reset/", {
        data: {
          email,
          username: email,
          csrfmiddlewaretoken: window.csrftoken,
        },
      }),
  };
}

export function resetPasswordConfirm(password1, password2, uid64, token) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("/api/auth/password/reset/confirm/", {
        data: {
          new_password1: password1,
          new_password2: password2,
          uid: uid64,
          token: token,
          csrfmiddlewaretoken: window.csrftoken,
        },
      }),
  };
}

export function changePassword(formData) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: (client) =>
      client.post("/api/auth/password/change/", {
        data: {
          ...formData,
          csrfmiddlewaretoken: window.csrftoken,
        },
      }),
  };
}
