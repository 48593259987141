import styled from "styled-components";
import React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { FolderEditForm } from "./folder-edit";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";

const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  cursor: default !important;
  padding: 0 0 !important;
`;

const StyledInteractiveBar = styled.span`
  padding: 0.75em 1em;
  flex-grow: 1;
  cursor: pointer !important;
`;

const StyledControls = styled.div`
  float: right;
  padding: 0.75em 1em;
`;

export class AccordionHeadComponent extends React.Component {
  getFolderIcon = () => {
    return this.props.active ? "folder open" : "folder";
  };

  render() {
    const { section } = this.props;
    const title = section.name;
    return (
      <StyledAccordionTitle active={this.props.active} index={title}>
        <StyledInteractiveBar
          onClick={() => {
            this.props.activate(title);
          }}
        >
          <Icon name={`${this.getFolderIcon()}`} />
          {title}
        </StyledInteractiveBar>
        <RoleRequired staff={true} permission={permissions.boardEdit}>
          <StyledControls hidden={!this.props.active || !section.id}>
            <FolderEditForm entity={{ id: section.id, name: section.name }} />
          </StyledControls>
        </RoleRequired>
      </StyledAccordionTitle>
    );
  }
}

export const AccordionHead = AccordionHeadComponent;
