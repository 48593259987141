// @flow
import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Loader } from "semantic-ui-react";

import { ErrorWrap } from "../../../helpers/error";
import { FilterButton } from "../../../components/common/filter-button";
import { ContentFilterPanel } from "../../../components/common/content-filter";

import { getUrlParams } from "../../../components/common/urlparams";
import {
  chargeQueryFilterParams,
  chargeQueryKeyMap,
  ContentFilterFormBody,
} from "./list-content-filter";
import { PaginationWithQuery } from "../../../components/common/pagination";
import FilterDisplay from "../../../components/common/filter-display";
import {
  createCharge,
  deleteCharge,
  fetchCharges,
} from "../../../redux/modules/charge";
import { ChargeTable } from "../../../components/billing/charge-table";
import { BulkDeleteCharges } from "./bulk-delete";
import { DownloadLink } from "../../../helpers/download-link";
import { t } from "../../../constant/translations";
import qs from "qs";

const StyledContentFilterPanel = styled(ContentFilterPanel)`
  min-height: 550px;
`;

const Inline = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: top;
`;

const UnpaddedRow = styled(Grid.Row)`
  padding: 0 0 !important;
`;

class BlockChargesListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false,
      selected: new Set(),
    };
  }

  componentDidMount() {
    this.getCharges(this.props);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      this.getCharges(this.props);
    }
  }

  getFilters = (props) => {
    const argFilters = getUrlParams(props.location);
    if (props.block) {
      argFilters["block"] = props.block.id;
    }
    return argFilters;
  };

  handleSetFilterOpen = (filterOpen) => this.setState({ filterOpen });

  getCharges(props) {
    props.fetchCharges(this.getFilters(props));
  }

  getSelectedCharges = () => {
    const charges = this.props.charges;
    let selectedCharges = [];
    if (charges && this.state.selected.size > 0) {
      selectedCharges = charges.filter((ch) => this.state.selected.has(ch.id));
    }
    return selectedCharges;
  };

  getBackendRequestParams = (props) => {
    const argFilters = this.getFilters(props);
    argFilters["format"] = "csv";
    return qs.stringify(argFilters, { ignoreQueryPrefix: true });
  };

  render() {
    const { meta, charges, loading, error, deleteCharge } = this.props;
    const entries = meta ? meta.count : 0;

    return (
      <Grid padded columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Inline>
              <div>
                <PaginationWithQuery entries={entries} />
              </div>
              <FilterDisplay keyMap={chargeQueryKeyMap} />
              <div>
                {this.getSelectedCharges().length > 0 && (
                  <BulkDeleteCharges
                    charges={this.getSelectedCharges()}
                    deleteCharge={deleteCharge}
                    loading={loading}
                    error={error}
                  />
                )}
                <DownloadLink
                  size="tiny"
                  basic={true}
                  href={`/api/charge?${this.getBackendRequestParams(
                    this.props
                  )}`}
                  target={"_blank"}
                  download={
                    `${this.props.block.name}-` +
                    t("charge.obligations") +
                    `.csv`
                  }
                  icon={"download"}
                />
                <FilterButton
                  onClick={() =>
                    this.handleSetFilterOpen(!this.state.filterOpen)
                  }
                />
              </div>
            </Inline>
          </Grid.Column>
        </Grid.Row>
        <UnpaddedRow>
          <Grid.Column>
            <StyledContentFilterPanel
              open={this.state.filterOpen}
              onApply={() => this.handleSetFilterOpen(false)}
              queryParamConfig={chargeQueryFilterParams}
              FormBody={ContentFilterFormBody}
            >
              {charges ? (
                <ChargeTable
                  charges={charges}
                  selected={this.state.selected}
                  setSelected={(value) => this.setState({ selected: value })}
                />
              ) : (
                <Loader active />
              )}
            </StyledContentFilterPanel>
          </Grid.Column>
        </UnpaddedRow>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected,
    error: ErrorWrap.fromError(state.charge.error),
    loading: state.charge.pending,
    charges: state.charge.entities,
    meta: state.charge.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchCharges, deleteCharge, createCharge },
    dispatch
  );
};

export const BlockChargesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockChargesListComponent);
