// @flow
import styled from "styled-components";
import React, { Component, Children } from "react";
import { Table } from "semantic-ui-react";

type Props = {
  data: Array<any>,
  children: Children,
  unstackable?: boolean,
  celled?: boolean,
  sortable?: boolean,
  selectable?: boolean,
  className?: string,
};

const StyledTableHeaderCell = styled(Table.HeaderCell)`
  "min-width": "80px";
`;

export class DataTable extends Component<Props> {
  render() {
    const {
      data,
      children,
      unstackable,
      celled,
      sortable,
      selectable,
    } = this.props;

    const columns = Children.toArray(children).filter(
      (child) => !child.props.hidden
    );

    return (
      <Table
        unstackable={unstackable}
        celled={celled}
        sortable={sortable}
        selectable={selectable}
        className={this.props.className}
      >
        <Table.Header>
          <Table.Row>
            {columns.map((col, cx) => (
              <StyledTableHeaderCell
                key={cx}
                sorted={col.props.sorted}
                onClick={col.props.onClick}
                collapsing={col.props.collapsing}
                textAlign={col.props.align}
                className={col.props.className}
              >
                {col.props.name}
              </StyledTableHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body role={"table-body"}>
          {data.map((entry, dx) => (
            <Table.Row key={dx}>
              {columns.map((col, ix) => (
                <Table.Cell
                  key={ix}
                  textAlign={col.props.align}
                  className={col.props.className}
                >
                  {col.props.render(entry)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

type Direction = "descending" | "ascending";
type Alignment = "left" | "right" | "center";

type ColumnProps = {
  render: (entry) => Element<any>,
  collapsing?: boolean,
  hidden?: boolean,
  align?: Alignment,
  onClick?: () => any,
  sorted?: Direction,
  className?: string,
};

export class DataTableColumn extends Component<ColumnProps> {
  render() {
    throw "DataTableColumn is not intended to be used outside a DataTable. It should never be rendered directly";
  }
}
