import styled from "styled-components";
import React, { Component } from "react";
import { Button, Container, Image, Message, Modal } from "semantic-ui-react";
import { imgSrc } from "../../helpers/static";
import { t } from "../../constant/translations";

const ContentWrap = styled.div`
  line-height: 1.1em;
`;

const ViberButtonWrap = styled.div`
  cursor: pointer;
  text-align: center;

  > img {
    margin: auto auto;
  }
`;

const ViberButton = (props) => {
  return (
    <ViberButtonWrap {...props}>
      {t("viber.quickacess_with")}
      <Image height="20px" src={imgSrc("rakuten-viber.png")} />
    </ViberButtonWrap>
  );
};

export class ViberModal extends Component {
  state = { isOpen: false };

  handleClose = () => this.setState({ isOpen: false });
  handleOpen = () => this.setState({ isOpen: true });

  render() {
    let trigger = <ViberButton onClick={this.handleOpen} />;

    return (
      <Modal
        open={this.state.isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        closeIcon={"close"}
        trigger={trigger}
      >
        <Modal.Header>{t("viber.chatbot")}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Container text wrapped textAlign="justified">
              <p>{t("viber.chatbot_info")}</p>
              <p>{t("viber.scan_code")}</p>
            </Container>
          </Modal.Description>
          <Image src={imgSrc("viber-qr.png")} wrapped />
        </Modal.Content>
        <Message attached="bottom">
          <ContentWrap>
            <a href="viber://pa?chatURI=umenvhod" target="_blank">
              {t("viber.clickhere")}
            </a>
            {t("viber.click_if_app")}
          </ContentWrap>
        </Message>
      </Modal>
    );
  }
}
