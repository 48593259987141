import { newApi } from "../restdux/index";

export const ticketManager = newApi("ticket", "/api/ticket/", true);
export const reducer = ticketManager.reducer;

export type Ticket = {
  id: number,
  block: number,
  title: string,
  description: string,
  ticket_type: string,
  status: string,
  author: Object,
  assignee: Object,
  internal: boolean,
  created_at: string,
  updated_at: string,
  deleted_at: ?string,
};

export function fetchTickets(params) {
  return ticketManager.actions.list(params);
}

export function getTicket(ticketId) {
  return ticketManager.actions.get({ id: ticketId });
}

export function createTicket(data) {
  return ticketManager.actions.create(data, { pushToFront: true });
}

export function updateTicket(data) {
  return ticketManager.actions.update(data);
}

export function deleteTicket(data) {
  return ticketManager.actions.del(data);
}

export const clearError = ticketManager.actions.clearError;
