import { defaultInitialState, newApi } from "../restdux/index";
import { getSpecificError } from "../restdux/utils";

export const noteManager = newApi("note", "/api/note/");

export function reducer(state = defaultInitialState, action) {
  const newState = noteManager.reducer(state, action);
  if (newState.entities && newState.entities !== state.entities) {
    return {
      ...newState,
      [action.entityType]: sortByCreatedAt(newState.entities),
    };
  }
  return newState;
}

function sortByCreatedAt(entities) {
  return entities.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
}

export function fetchNotes(entityId, entityType) {
  return noteManager.actions.list({ [entityType]: entityId }, { entityType });
}

export function addNote(entityId, entityType, content) {
  return noteManager.actions.create(
    { [entityType]: entityId, content },
    { entityType }
  );
}

export function deleteNote(note) {
  return noteManager.actions.del(note, {
    entityType: note.block ? "block" : "estate",
  });
}

export function toggleView(note) {
  let tag = "general";
  if (note.tag === "general") {
    tag = "payment";
  }
  return noteManager.actions.update(
    { ...note, tag: tag },
    { entityType: note.block ? "block" : "estate" }
  );
}

export function getCreateError(state) {
  return getSpecificError(noteManager.actionTypes.CREATE_ERROR, state.note);
}
