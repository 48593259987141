import { Grid } from "semantic-ui-react";
import { TicketTopMenu } from "./top-menu";
import React, { useState } from "react";
import { TicketList } from "./list";
import { connect } from "react-redux";
import { ContentFilterPanel } from "../../components/common/content-filter";
import { ContentFilterForm, ticketQueryFilterParams } from "./content-filter";

export const TicketPageComponent = ({ globalView, block, users, meta }) => {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <Grid centered padded style={{ height: "100%" }}>
      <Grid.Row>
        <Grid.Column mobile={16} textAlign={"left"}>
          <TicketTopMenu
            globalView={globalView}
            block={block}
            openFilter={() => setFilterOpen(!filterOpen)}
            meta={meta}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ height: "inherit" }}>
        <Grid.Column mobile={16} textAlign={"left"}>
          <ContentFilterPanel
            open={filterOpen}
            onApply={() => setFilterOpen(false)}
            queryParamConfig={ticketQueryFilterParams}
            FormBody={ContentFilterForm}
          >
            <TicketList globalView={globalView} />
          </ContentFilterPanel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const TicketPage = connect((state) => {
  return {
    block: state.blocks.selected,
    meta: state.ticket.meta || {},
  };
})(TicketPageComponent);
