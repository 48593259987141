import React, { Component } from "react";
import { Button, Message, Modal } from "semantic-ui-react";
import { ChargeListSmallComponent } from "../charge-list-small";
import { t } from "../../../constant/translations";

export class BulkDeleteCharges extends Component {
  handleDelete = () => {
    this.props.charges.map((ch) => {
      this.props.deleteCharge(ch);
    });
  };

  render() {
    const { charges } = this.props;

    return (
      <Modal
        closeIcon={true}
        trigger={
          <Button size={"tiny"} basic={true} negative={true}>
            {t("charge.delete_selected")} ({charges.length})
          </Button>
        }
      >
        <Modal.Header>
          {t("charge.delete_due")} ({charges.length})
        </Modal.Header>
        <Modal.Content>
          <div>{t("charge.selected_for_deletion")}</div>
          <ChargeListSmallComponent charges={charges} />
          {this.props.error.exists() && (
            <Message
              negative={true}
              content={this.props.error.getGlobalErrorMsg()}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="remove"
            onClick={this.handleDelete}
            content={t("userActions.delete")}
            negative={true}
            loading={this.props.loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
