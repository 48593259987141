const FETCH = "app/settings/FETCH";
const FETCH_SUCCESS = "app/settings/FETCH_SUCCESS";
const FETCH_ERROR = "app/settings/FETCH_ERROR";

const UPDATE = "app/settings/UPDATE";
const UPDATE_SUCCESS = "app/settings/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/settings/UPDATE_ERROR";

const initialState = {
  pending: false,
  error: null,
  data: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE:
    case FETCH:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case UPDATE_SUCCESS:
    case FETCH_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.result,
      };
    case UPDATE_ERROR:
    case FETCH_ERROR:
      return {
        ...state,
        pending: false,
        error: { "@type": action.type, ...action.error },
      };
    default:
      return state;
  }
}

export function fetchSettings() {
  return {
    types: [FETCH, FETCH_SUCCESS, FETCH_ERROR],
    promise: (client) => client.get("/api/app-settings/"),
  };
}

export function updateSettings(data) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_ERROR],
    promise: (client) => client.post("/api/app-settings/", { data }),
  };
}
