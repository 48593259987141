import { EditEntity } from "../../components/common/edit-entity";

import React, { useEffect, useRef } from "react";
import { Checkbox, Dropdown, Form, Message } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ErrorWrap } from "../../helpers/error";
import {
  boardPostManager,
  createBoardPost,
  updateBoardPost,
} from "../../redux/modules/board-post";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { uploadFiles } from "../../redux/modules/file";
import { t } from "../../constant/translations";
import { MarkdownEdit } from "../../components/common/markdownedit";

const PostFormComponent = ({
  entity,
  header,
  files,
  initialData,
  ...props
}) => {
  const prevFiles = useRef(null);
  const fileInputRef = useRef(null);

  const manager = FormdataManager.useState(
    getInitialState(entity, initialData)
  );
  const getFileOptions = (files) => {
    const uploadFileOption = [
      { key: -1, text: t("board.upload_new_file"), value: -1, icon: "upload" },
    ];
    return uploadFileOption.concat(
      files.map((file) => {
        return { key: file.id, text: file.filename, value: file.id };
      })
    );
  };

  useEffect(() => {
    if (
      !props.filesLoading &&
      prevFiles.current &&
      prevFiles.current.length < files.length
    ) {
      // Get all new files uploaded
      const differenceArray = files.filter(
        (file) => !prevFiles.current.includes(file)
      );

      // Add the recently uploaded files to the file selection dropdown
      const newFiles = Array.from(manager.getValue("files"));
      differenceArray.forEach((file) => {
        newFiles.push(file.id);
      });
      manager.setValue("files", newFiles);
    }
    prevFiles.current = files;
  }, [files]);

  const handleDropdownChange = (e, { value }) => {
    if (!value.includes(-1)) {
      manager.setValue("files", value);
    } else {
      // "Upload New File" option selected(-1)
      // Display the file picker dialog by simulating clicking on a file input component
      fileInputRef.current.click();
    }
  };

  return (
    <EditEntity
      entity={entity}
      header={header}
      manager={manager}
      {...props}
      trigger_props={{
        floated: "right",
      }}
    >
      <Form.Input
        label={t("labels.title")}
        {...manager.getAttributes("title")}
      />
      <Form.Field>
        <MarkdownEdit {...manager.getAttributes("content")}></MarkdownEdit>
      </Form.Field>
      <Form.Field hidden>
        {/* Hidden file input used to display file picker dialog*/}
        <input
          hidden
          type={"file"}
          multiple
          ref={fileInputRef}
          onChange={(event) => {
            props.uploadFiles(Array.from(event.target.files));
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("board.attached_documents")}</label>
        <Dropdown
          options={getFileOptions(files)}
          fluid
          multiple
          search
          selection
          onChange={handleDropdownChange}
          disabled={props.filesLoading}
          loading={props.filesLoading}
          text={props.filesLoading ? t("board.uploading") : undefined}
          value={manager.getValue("files")}
        ></Dropdown>
      </Form.Field>
      <Message>
        <Checkbox
          toggle
          label={t("board.notif_to_inhabitant")}
          checked={!manager.getValue("skip_notifications")}
          onClick={() => {
            manager.setValue(
              "skip_notifications",
              !manager.getValue("skip_notifications")
            );
          }}
        />
        <p>{t("board.notifs_mail_viber")}</p>
      </Message>
    </EditEntity>
  );
};

function mapStateToProps(state) {
  return {
    files: state.file.entities,
    filesLoading: state.file.pending,
    error: ErrorWrap.fromError(state.boardpost.error),
    loading: state.boardpost.pending,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      uploadFiles: (files) => {
        const block = ownProps.initialData?.block ?? ownProps.entity.block;
        return uploadFiles({ block }, files);
      },
      create: createBoardPost,
      update: updateBoardPost,
      del: boardPostManager.actions.del,
      clearError: boardPostManager.actions.clearError,
    },
    dispatch
  );
}

export const PostForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostFormComponent);
