import * as React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { t } from "../../../constant/translations";

const popupDisabledFlag = "--sandbox-notify-popup-disabled";

const StyledButton = styled(Button)`
  margin: 0 10px !important;
`;
const SandboxNoticeComponent = (props) => {
  const { user } = props;
  const autoPopupDisabled = window.localStorage.getItem(popupDisabledFlag);
  const [open, setOpen] = React.useState(!autoPopupDisabled);

  if (user.is_sandbox_account) {
    if (open && !autoPopupDisabled) {
      window.localStorage.setItem(popupDisabledFlag, true);
    }

    return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        closeIcon={true}
        dimmer={"blurring"}
        closeOnDimmerClick={false}
        trigger={
          <StyledButton basic={true} size="tiny" color={"orange"}>
            {t("sandbox.demo_version")}
          </StyledButton>
        }
      >
        <Modal.Header>{t("sandbox.welcome")}</Modal.Header>
        <Modal.Content>
          <p>🔍 {t("sandbox.intro")}</p>
          <p>🔧 {t("sandbox.demo_obj")}</p>
          <p>
            ❓️ {t("sandbox.feedback")}{" "}
            <a
              style={{ verticalAlign: "bottom" }}
              href={"mailto:info@umenvhod.com"}
            >
              info@umenvhod.com
            </a>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("labels.close")}
            labelPosition="left"
            icon="checkmark"
            onClick={() => setOpen(false)}
            positive
          />
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

export const SandboxNotice = connect((state) => {
  return { user: state.auth.user };
})(SandboxNoticeComponent);
