import React, { Component } from "react";
import qs from "qs";
import dayjs from "dayjs";
import { withRouter } from "react-router";

import { Button, Modal, Form, Checkbox, Message } from "semantic-ui-react";

import { FormProps } from "../../components/common/edit-entity";
import { CalendarInput } from "../../components/common/calendar-input";
import { DownloadLink } from "../../helpers/download-link";
import { t } from "../../constant/translations";

export const ReportDownloadForm = withRouter(({ location, history }) => {
  const [exportTotalOnly, setExportTotal] = React.useState(false);

  const dateFormat = "YYYY-MM-DD";
  const [fromDate, setFromDate] = React.useState(
    dayjs().subtract(3, "months").format(dateFormat)
  );
  const [toDate, setToDate] = React.useState(dayjs().format(dateFormat));

  const baseTitle = t("block.location_balance");
  const downloadFileName = exportTotalOnly
    ? `${baseTitle} - ${dayjs().format(dateFormat)}.csv`
    : `${baseTitle} - ${fromDate}_${toDate}.csv`;
  let queryParams;

  queryParams = qs.stringify(
    {
      ...location.query,
      ...(exportTotalOnly ? {} : { from: fromDate, to: toDate }),
      format: "csv",
    },
    { addQueryPrefix: false }
  );

  const downloadUrl = `/api/reports/blocks-balance/?${queryParams}`;

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field disabled={exportTotalOnly}>
          <CalendarInput
            label={t("titles.from_date")}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </Form.Field>
        <Form.Field disabled={exportTotalOnly}>
          <CalendarInput
            label={t("titles.to_date")}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Message>
          <Checkbox
            toggle
            checked={exportTotalOnly}
            onChange={(event, props) => {
              setExportTotal(props.checked);
            }}
            label={t("block.current_balances")}
          />
          <p>{t("block.reference_monthly_rep")}</p>
        </Message>
      </Form.Field>
      <DownloadLink
        href={downloadUrl}
        target={"_blank"}
        download={downloadFileName}
        size="medium"
        type="submit"
        positive
        fluid
      >
        {t("block.download")}
      </DownloadLink>
    </Form>
  );
});

export class BlocksDownloadModal extends Component<FormProps> {
  state = {
    formData: null,
  };

  render() {
    let trigger = (
      <Button
        size={"tiny"}
        onClick={this.handleOpenModal}
        basic={true}
        icon={"download"}
      />
    );

    return (
      <Modal
        open={this.state.modalOpen}
        onOpen={this.handleOpenModal}
        onClose={this.handleCloseModal}
        closeIcon={"close"}
        trigger={trigger}
      >
        <Modal.Header>{t("block.reference_per_loc")}</Modal.Header>
        <Modal.Content>
          <ReportDownloadForm />
        </Modal.Content>
      </Modal>
    );
  }
}
