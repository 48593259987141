// @flow
import React from "react";
import { Form } from "semantic-ui-react";
import type { FormProps } from "../../../components/common/edit-entity";
import { EditEntity } from "../../../components/common/edit-entity";
import {
  FormdataManager,
  getInitialState,
} from "../../../components/common/formdata";
import { ErrorWrap } from "../../../helpers/error";
import { bindActionCreators } from "redux";
import {
  createBillingModel,
  deleteBillingModel,
  updateBillingModel,
} from "../../../redux/modules/billing-models";
import { connect } from "react-redux";
import { listBillingForecast } from "../../../redux/modules/billing-forecast";
import { t } from "../../../constant/translations";

type Props = FormProps & {
  block: Object,
  entity: Object,
  initialData: ?Object,
};

export const BillingModelFormComponent = (props: Props) => {
  const { block, entity, initialData, error } = props;
  const fundOptions = block.funds.map((fund) => {
    return { text: fund.name, value: fund.name };
  });

  const manager = FormdataManager.useState(
    getInitialState(entity, initialData)
  ).withError(error);
  const header = entity ? t("model.change") : t("model.new");
  return (
    <EditEntity manager={manager} header={header} {...props}>
      <Form.Group widths="equal">
        <Form.Input
          label={t("model.attribute")}
          placeholder={t("model.ic")}
          {...manager.getAttributes("type")}
        />
        <Form.Select
          label={t("model.to_checkout")}
          options={fundOptions}
          {...manager.getAttributes("fund")}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          label={t("model.min_price")}
          placeholder="4.20"
          type="number"
          step="0.01"
          {...manager.getAttributes("minimum")}
        />
        <Form.Input
          label={t("model.price")}
          placeholder="5.00"
          type="number"
          step="0.01"
          {...manager.getAttributes("price")}
        />
      </Form.Group>
      <Form.TextArea
        label={t("labels.description")}
        placeholder={t("model.foreach_perc")}
        {...manager.getAttributes("description")}
      />
    </EditEntity>
  );
};

function mapStateToProps(state, ownProps) {
  const defaultFund =
    ownProps.block.funds.length !== 0 ? ownProps.block.funds[0].name : null;
  return {
    error: ErrorWrap.fromError(state.billingModel.error),
    loading: state.billingModel.pending,
    initialData: { fund: defaultFund },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const create = (formData) => {
    return createBillingModel(ownProps.block.id, formData);
  };

  const reloadForecastThunk = (method) => {
    return (...args) => {
      return (dispatch) => {
        return dispatch(method(...args)).then(() => {
          dispatch(listBillingForecast(ownProps.block.id));
        });
      };
    };
  };

  return bindActionCreators(
    {
      create,
      update: reloadForecastThunk(updateBillingModel),
      del: reloadForecastThunk(deleteBillingModel),
    },
    dispatch
  );
}

export const BillingModelForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingModelFormComponent);
