import { newApi } from "../restdux/index";

export const ticketCommentManager = newApi(
  "ticketComment",
  "/api/ticket-comment/"
);
export const reducer = ticketCommentManager.reducer;

export type TicketComment = {
  id: number,
  ticket: number,
  author: Object,
  content: string,
  created_at: string,
  updated_at: string,
  deleted_at: ?string,
};

export function fetchTicketComments(ticketId) {
  return ticketCommentManager.actions.list({ ticket: ticketId });
}

export function createTicketComment(data) {
  return ticketCommentManager.actions.create(data);
}

export function updateTicketComment(data) {
  return ticketCommentManager.actions.update(data);
}

export function deleteTicketComment(data) {
  return ticketCommentManager.actions.del(data);
}

export const clearError = ticketCommentManager.actions.clearError;
