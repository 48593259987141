import { t } from "../constant/translations";

export class ErrorWrap {
  static fromError(err) {
    return new ErrorWrap(err);
  }

  constructor(baseError) {
    this.baseError = baseError || {};
  }

  exists() {
    return Object.keys(this.baseError).length !== 0;
  }

  has(fieldName) {
    return this.baseError[fieldName] !== undefined;
  }

  get(fieldName) {
    if (this.has(fieldName)) {
      return this.baseError[fieldName][0];
    }
  }

  getGlobalErrorMsg() {
    if (this.baseError.non_field_errors) {
      return this.baseError.non_field_errors;
    }
    if (this.baseError.detail) {
      return this.baseError.detail;
    }
    if (this.baseError[0]) {
      return this.baseError[0];
    }
    if (this.baseError.error) {
      return this.baseError.error;
    }
    if (this.baseError.status === 400) {
      return t("helpers.error_check_data");
    }
    return t("helpers.try_again");
  }

  getGlobal() {
    let message = this.getGlobalErrorMsg();
    return `${t("labels.error")}: ${message}`;
  }

  getCode() {
    return this.baseError.status || 500;
  }
}
