// @flow
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Divider, Grid, Menu, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserRoles } from "../../../../redux/modules/user-role";
import { RoleEditSection } from "./edit";
import { getById } from "../../../../redux/restdux/utils";
import { device } from "../../../../config";
import { t } from "../../../../constant/translations";

const SectionSegment = styled(Segment)`
  margin: 1em 1em 0 0 !important;
  max-width: 600px;
  @media ${device.handheld} {
    margin: 1em 0 0 0 !important;
    max-width: 1020px;
  }
`;

export const RoleListComponent = ({ roles, fetchUserRoles, lastModified }) => {
  const [selected, selectRole] = useState(-1);
  useEffect(() => {
    fetchUserRoles();
  }, [null]);

  useEffect(() => {
    lastModified && selectRole(lastModified.id);
  }, [lastModified]);

  const role = getById(selected, roles) || {};
  return (
    <Grid padded>
      <Grid.Column tablet={16} computer={5} textAlign={"left"}>
        <SectionSegment>
          <Button onClick={() => selectRole(-1)}>
            {t("management.new_role")}
          </Button>
          <Divider />
          <Menu secondary vertical stackable>
            {roles.map((role, roleIdx) => {
              return (
                <Menu.Item
                  key={roleIdx}
                  active={selected && role.id === selected}
                  onClick={() => {
                    selectRole(role.id);
                  }}
                >
                  {role.name}
                </Menu.Item>
              );
            })}
          </Menu>
        </SectionSegment>
      </Grid.Column>
      <Grid.Column tablet={16} computer={11} textAlign={"left"}>
        {selected && (
          <SectionSegment>
            <RoleEditSection role={role} />
          </SectionSegment>
        )}
      </Grid.Column>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    roles: state.userRole.entities || [],
    lastModified: state.userRole.lastModified || null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUserRoles }, dispatch);
}

export const RoleList = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleListComponent);
