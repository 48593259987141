import React, { useState } from "react";
import { Pagination as SemanticPagination } from "semantic-ui-react";
import { defaultPageSize, sliceList } from "../../helpers/filter";
import { NumberParam, useQueryParams } from "use-query-params";

export const Pagination = ({
  current,
  total,
  onChange,
  pageSize,
  disabled,
}) => {
  if (total <= pageSize) {
    return null;
  }
  current = current || 1;
  const totalPages = Math.ceil(total / pageSize);
  return (
    <SemanticPagination
      activePage={current}
      size="mini"
      prevItem={null}
      nextItem={null}
      firstItem={null}
      lastItem={null}
      totalPages={totalPages}
      disabled={disabled}
      onPageChange={(e, { activePage }) => onChange(activePage, pageSize)}
    />
  );
};

export const PaginationWithQuery = ({ entries }) => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
  });

  const pageSize = query.pageSize ? query.pageSize : defaultPageSize;
  if (entries <= pageSize) {
    return <div />;
  }

  return (
    <Pagination
      current={query.page ? query.page : 1}
      total={entries}
      onChange={(page, pageSize) => setQuery({ page, pageSize }, "pushIn")}
      pageSize={pageSize}
    />
  );
};

export const getCurrentPage = () => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
  });
  return query;
};

export const usePagination = (data, pageSize = 25) => {
  const entries = data || [];
  const [currentPage, setCurrentPage] = useState(1);

  const page = sliceList(entries, currentPage - 1, pageSize);
  const handlePaginate = (currentPage, pageSize) => {
    setCurrentPage(currentPage);
  };

  return [
    page,
    <Pagination
      current={currentPage}
      total={entries.length}
      onChange={handlePaginate}
      pageSize={pageSize}
    />,
  ];
};
