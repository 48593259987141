import React from "react";
import { SettlementBreakdown } from "../billing/settlement/breakdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearCreated } from "../../redux/modules/settlement";

class SettlementCreatedComponent extends React.Component {
  state = { settlement: null };

  componentDidUpdate(prevProps) {
    if (this.props.settlement !== prevProps.settlement) {
      this.setState({ settlement: this.props.settlement });
    }
  }

  render() {
    const { onClose } = this.props;
    if (!this.state.settlement) {
      return null;
    }
    return (
      <SettlementBreakdown
        settlement={this.state.settlement}
        onClose={onClose}
      />
    );
  }
}

function mapStateToProps(state) {
  return { settlement: state.settlement.lastModified };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onClose: clearCreated }, dispatch);
}

export const DisplayCreatedSettlement = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementCreatedComponent);
