import Cookies from "js-cookie";
import Superagent from "superagent";
import React, { Component } from "react";

import { Input, Form, Button, Icon, Message, Label } from "semantic-ui-react";
import { withMedia } from "react-media-query-hoc";
import { t } from "../../constant/translations";

export function getParentFromSubmit(elm) {
  let result = elm;
  while (
    result &&
    result.nodeName !== "FORM" &&
    // Usually semantic-ui doesn't create actual froms.
    !result.classList.contains("form")
  ) {
    result = result.parentElement;
  }

  return result.querySelector("[type=submit]");
}

type Props = {
  name: string,
  onChange: Function,
  value: ?Array,
  oneFile: ?boolean,
};

class UserFileComponent extends Component<Props> {
  state = {
    loading: false,
    uploaded: false,
    attachedFiles: [],
    uploadedFiles: [],
    err: null,
  };
  valueBuilder = (entries) => {
    if (this.props.oneFile) {
      return entries.length > 0 ? entries[0] : null;
    }
    return entries;
  };

  handleUpload = (event) => {
    if (this.state.attachedFiles.length === 0) return;

    const list = this.state.attachedFiles.map((file) =>
      Superagent.post("/api/user-file/")
        .set("X-CSRFToken", Cookies.get("csrftoken"))
        .attach("content", file)
    );

    const submit = getParentFromSubmit(event.target);
    if (submit) submit.disabled = true;

    Promise.all(list)
      .then((values) => {
        this.setState(
          {
            loading: false,
            uploaded: true,
          },
          () => {
            if (submit) submit.disabled = false;
          }
        );

        const entries = values.map((res) => ({
          uuid: res.body.uuid,
          url: res.body.content,
          filename: res.body.filename,
        }));

        this.props.onChange(null, {
          value: this.valueBuilder(entries),
        });
      })
      .catch(() =>
        this.setState(
          {
            loading: false,
            uploaded: false,
            err: t("error.error_try_later"),
          },
          () => {
            if (submit) submit.disabled = false;
          }
        )
      );
  };

  render() {
    const { name, value, media, oneFile, accept } = this.props;
    const { loading, uploaded, err } = this.state;
    const content = media.handheld && t("ticket.upload");

    const entries = Array.isArray(value) || !value ? value : [value];
    let label = (
      <Button
        icon={"upload"}
        labelPosition={content && "right"}
        color="green"
        onClick={this.handleUpload}
        content={content}
      />
    );

    if (loading) {
      label = (
        <Button basic loading>
          Loading
        </Button>
      );
    } else if (uploaded) {
      label = (
        <Button icon color="green">
          <Icon name="check" />
        </Button>
      );
    }

    return (
      <React.Fragment>
        <Input
          accept={accept || "*"}
          multiple={!oneFile}
          error={!!err}
          disabled={loading}
          type={"file"}
          label={label}
          name=""
          labelPosition={"right"}
          onChange={(event) => {
            // Event object access is restricted to the current
            // event-loop cycle.
            const e = { ...event };

            this.setState(
              {
                err: null,
                loading: false,
                uploaded: false,
                attachedFiles: Array.from(event.target.files),
              },
              () => this.handleUpload(e)
            );
          }}
        />
        {err && <Message negative content={err} />}
        {entries &&
          entries.length > 0 &&
          entries.map((file) => {
            return (
              <Label
                key={file.url}
                icon="cancel"
                content={file.filename}
                as="a"
                onClick={() =>
                  this.props.onChange(
                    // Hack - setting null by default if entries becomes an empty array
                    { target: { value: null } },
                    {
                      value: this.valueBuilder(
                        entries.filter((v) => v !== file)
                      ),
                    }
                  )
                }
              />
            );
          })}
      </React.Fragment>
    );
  }
}

export const UserFile = withMedia(UserFileComponent);
