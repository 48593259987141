import React, { useEffect, useState } from "react";
import {
  Divider,
  Header,
  Label,
  List,
  ListItem,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { RoleRequired } from "../../containers/auth/role-required";
import { ProfileEdit } from "../../containers/profile/edit";
import { ConfirmModalAction } from "../common/confirm-modal";

import styled from "styled-components";
import { permissions } from "../../constant/permissions";
import { formatPhoneNumber } from "../../helpers/phone-number";
import { ContactInfo } from "../profile/contact-info";

import { reverse, routes } from "../../routes";
import { t } from "../../constant/translations";
import { apirequest } from "../../helpers/api-client";

const StyledCardHeader = styled(Header)`
  margin: auto auto !important;
`;

const StyledEmailWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(100% - 25px);
`;

export const ProfileCard = ({
  profile,
  type,
  showDelete,
  deleteError,
  deleteAction,
  estate,
}) => {
  estate = estate || {};
  const [allEstates, setAllEstates] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await apirequest(
        "get",
        `/api/estate/?profile_id=${profile.id}`
      );
      if (response.ok) {
        setAllEstates(response.body.results);
      }
    };
    fetchData();
  }, [profile]);

  const otherEstatesComps = allEstates
    .map((currEstate) => {
      if (currEstate.id === estate.id) return;

      return (
        <Label
          circular
          as={Link}
          to={reverse(routes.estateDetails, {
            blockId: currEstate.block.id,
            estateId: currEstate.id,
          })}
          style={{
            color: "#4183c4",
          }}
        >
          {currEstate.name}
        </Label>
      );
    })
    .filter((item) => item !== undefined);

  return (
    <Segment>
      <RoleRequired staff={true} permission={permissions.estateCustomerDelete}>
        {showDelete && (
          <div>
            <ConfirmModalAction
              header={
                t("profile.remove_link") +
                ` ${profile.name} ` +
                t("labels.and") +
                ` ${estate.name}`
              }
              content={
                t("profile.remove_user_link") +
                ` ${profile.name} ` +
                t("profile.and_property") +
                ` ${estate.name} ?`
              }
              trigger_props={{
                color: "red",
                icon: "delete",
                size: "tiny",
                floated: "right",
              }}
              error={deleteError}
              loading={false}
              action={deleteAction}
              confirmLabel={t("labels.yes")}
            />
            <ProfileEdit entity={profile} />
          </div>
        )}
      </RoleRequired>
      <StyledCardHeader forwardedAs="h4">
        {profile.name}
        <Header.Subheader>
          {type === "owner"
            ? t("estateCustomerTypes.owner")
            : t("estateCustomerTypes.tenant")}
        </Header.Subheader>
      </StyledCardHeader>
      <List>
        <List.Item>
          <List.Icon name="mail" />
          <List.Content as={"a"} href={`mailto:${profile.email}`}>
            <StyledEmailWrapper>{profile.email}</StyledEmailWrapper>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="text telephone" />
          <List.Content>
            {profile.mobile_phone_number && (
              <React.Fragment>
                +{formatPhoneNumber(profile.mobile_phone_number)}
              </React.Fragment>
            )}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="info circle" />
          <List.Content>
            <ContactInfo contactInfo={profile.contact_info} />
          </List.Content>
        </List.Item>
        {otherEstatesComps.length > 0 && (
          <Divider horizontal>{t("profile.other_properties")}</Divider>
        )}
        <ListItem>
          <List.Content>{otherEstatesComps}</List.Content>
        </ListItem>
      </List>
    </Segment>
  );
};
