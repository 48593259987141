import React from "react";
import styled from "styled-components";
import { Button, Popup } from "semantic-ui-react";
import { device } from "../../config";
import { withMedia } from "react-media-query-hoc";

const ResponsiveButton = styled(Button)`
  font-size: 0.85em !important;
  @media ${device.handheld} {
    font-size: 1em !important;
  }
`;

export const BadgeComponent = ({ content, icon, color, media }) => {
  const popsition = media.handheld ? "center" : "left";
  return (
    <Popup
      content={content}
      position={`bottom ${popsition}`}
      trigger={
        <span>
          <ResponsiveButton circular icon={icon} color={color} basic />
        </span>
      }
    />
  );
};

export const Badge = withMedia(BadgeComponent);
