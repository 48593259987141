import { newApi } from "../restdux/index";

export const auditLogManager = newApi("audit-log", "/api/audit/", true);
export const reducer = auditLogManager.reducer;

export type AuditLogEntry = {
  id: number | string,
  method: "PUT" | "POST" | "DELETE",
  action: "create" | "edit" | "destroy",

  path: string,
  user: Object,

  // estate-customers, tickets, etc...
  resource: string,
  resource_id: string,

  // API request response
  response: Object,

  created_at: string,
};
export function fetchFullAuditLog(params) {
  return auditLogManager.actions.list(params);
}
export function fetchAuditLog(userId, pageSize = 100) {
  return auditLogManager.actions.list({ user: userId, page_size: pageSize });
}

export function fetchReconcileLog(userId) {
  return auditLogManager.actions.list({
    resource_id: userId,
    resource: "collection",
  });
}

export const clearError = auditLogManager.actions.clearError;
