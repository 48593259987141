import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Form } from "semantic-ui-react";
import type { FormProps } from "../../components/common/edit-entity";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  createTransaction,
} from "../../redux/modules/fundTransaction";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { MarkdownEdit } from "../../components/common/markdownedit";
import { CalendarInput } from "../../components/common/calendar-input";
import { t } from "../../constant/translations";

export class TransactionCreateComponent extends Component<FormProps> {
  state = { formData: null };

  render() {
    const {
      error,
      initialData,
      restrictMonthDate,
      trigger_props,
      transaction_categories: transaction_categories,
    } = this.props;
    const initialState = getInitialState(initialData);
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );
    const header = t("billing.new_order");

    const selectOrderTypeOptions = [
      { key: "deposit", value: "deposit", text: t("labels.payment") },
      { key: "withdrawal", value: "withdrawal", text: t("billing.withdraw") },
    ];

    const selectTransactionCategory = transaction_categories.map((entry) => {
      return { key: entry, value: entry, text: entry };
    });

    const selectPaymentOptions = [
      { key: "cash", value: "cash", text: t("billing.cash") },
      { key: "bank", value: "bank", text: t("billing.bank") },
      { key: "easypay", value: "easypay", text: t("billing.easypay") },
      { key: "epay", value: "epay", text: t("billing.epay") },
    ];

    const selectFundOptions = this.props.funds.map((p) => ({
      key: p.id,
      text: p.name,
      value: p.name,
    }));

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={header}
        trigger_props={trigger_props}
      >
        <Form.Field>
          <label>{t("labels.amount")}</label>
          <Form.Input
            placeholder={t("labels.amount")}
            error={error.has("amount")}
            {...manager.getAttributes("amount")}
          />
          {error.has("amount") && <span>{error.get("amount")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("labels.direction")}</label>
          <Form.Select
            placeholder={t("labels.direction")}
            options={selectFundOptions}
            {...manager.getAttributes("fund")}
          />
          {error.has("fund") && <span>{error.get("fund")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("labels.action")}</label>
          <Form.Select
            placeholder={t("labels.action")}
            options={selectOrderTypeOptions}
            {...manager.getAttributes("order_type")}
          />
        </Form.Field>
        {selectTransactionCategory.length > 0 && (
          <Form.Field>
            <label>{t("labels.category")}</label>
            <Form.Select
              placeholder={t("labels.category")}
              options={selectTransactionCategory}
              {...manager.getAttributes("category")}
            />
          </Form.Field>
        )}
        <Form.Field>
          <label>{t("labels.description")}</label>
          <MarkdownEdit {...manager.getAttributes("description")} rows={4} />
          {error.has("description") && <span>{error.get("description")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("billing.transaction_date")}</label>
          <CalendarInput
            placeholder={t("labels.date")}
            error={error.has("date")}
            {...manager.getAttributes("date")}
            fromMonth={restrictMonthDate}
            toMonth={restrictMonthDate}
          />
          {error.has("date") && <span>{error.get("date")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("settlement.payment_method")}</label>
          <Form.Select
            plaholder={t("settlement.payment_method") /*TODO: not in the API?*/}
            options={selectPaymentOptions}
            {...manager.getAttributes("payment_method")}
          />
          {error.has("payment_method") && (
            <span>{error.get("payment_method")}</span>
          )}
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  const appSettings = state.appSettings.data || {};
  return {
    transaction_categories: appSettings.transaction_categories || [],
    error: ErrorWrap.fromError(state.fundTransaction.error),
    funds: state.blocks.selected.funds,
    loading: state.fundTransaction.pending,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const create = (data) => (dispatch) => {
    dispatch(createTransaction({ block: ownProps.block.id, ...data }));
  };
  return bindActionCreators({ create, clearError }, dispatch);
}

export const TransactionCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionCreateComponent)
);
