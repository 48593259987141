import { newApi } from "../restdux/index";

export const profileManager = newApi("profile", "/api/profile/");
export const reducer = profileManager.reducer;

export type Profile = {
  id: number,
  created_at: string,
  updated_at: string,
  deleted_at: ?string,
  name: ?string,
  email: ?string,
  contact_info: ?string,
  role: ?string,
  board_member: Boolean,
  block: number,
  user: number,
  iban: ?string,
  company: ?Boolean,
  company_code: ?string,
  company_manager: ?string,
};

export function fetchProfiles(blockId) {
  return profileManager.actions.list({ block: blockId });
}

export function createProfile(data) {
  return profileManager.actions.create(data);
}

export function updateProfile(data) {
  return profileManager.actions.update(data);
}

export function deleteProfile(data) {
  return profileManager.actions.del(data);
}

export const clearError = profileManager.actions.clearError;
