// @flow
import styled from "styled-components";
import React from "react";
import dayjs from "dayjs";
import { Checkbox, Popup, Table } from "semantic-ui-react";
import { Description } from "./charge-description";
import { amountToBGN } from "../../helpers/currency";
import type { Charge } from "../../redux/modules/charge";
import { reverse, routes } from "../../routes";
import { Link } from "react-router-dom";
import { ChargeEdit } from "./charge-edit";
import { RoleRequired } from "../../containers/auth/role-required";
import { permissions } from "../../constant/permissions";
import { formatDate } from "../../helpers/date";
import { t, tp } from "../../constant/translations";
import ReturnCharge from "../../containers/billing/charge/return-charge";

type ChargeTableProps = {
  charges: Array<Charge>,
  estate?: any,
  selected?: Set<number>,
  setSelected?: (Set<number>) => void,
};

const PointingCell = styled(Table.Cell)`
  cursor: pointer;
`;
const PointingHeaderCell = styled(Table.HeaderCell)`
  cursor: pointer !important;
`;

function isSuperset(set, subset) {
  for (let elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export const ChargeTable = ({
  charges,
  estate,
  selected = new Set(),
  setSelected,
}: ChargeTableProps) => {
  const chargeIds = new Set(charges.map((ch) => ch.id));

  const toggleChecked = (chargeId) => {
    selected.has(chargeId) ? selected.delete(chargeId) : selected.add(chargeId);
    setSelected(new Set(selected));
  };

  return (
    <div>
      <Table unstackable={true}>
        <Table.Header>
          <Table.Row selected={true}>
            {setSelected && (
              <RoleRequired permission={permissions.chargeAdd}>
                <PointingHeaderCell
                  collapsing={true}
                  onClick={() => {
                    isSuperset(selected, chargeIds)
                      ? setSelected(new Set())
                      : setSelected(chargeIds);
                  }}
                >
                  <Checkbox
                    checked={
                      selected.size > 0 && isSuperset(selected, chargeIds)
                    }
                  />
                </PointingHeaderCell>
              </RoleRequired>
            )}
            {!estate && (
              <Table.HeaderCell>
                {t("resources.estate_capital")}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>{t("labels.month")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.direction")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.amount")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.status")}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {charges.map((charge: Charge) => {
            const chargeDate = dayjs(charge.charge_date).format("M/YYYY");
            return (
              <Table.Row key={charge.id}>
                {setSelected && (
                  <RoleRequired permission={permissions.chargeAdd}>
                    <PointingCell onClick={() => toggleChecked(charge.id)}>
                      <Checkbox checked={selected.has(charge.id)} />
                    </PointingCell>
                  </RoleRequired>
                )}
                {!estate && (
                  <Table.Cell singleLine>
                    <Link
                      to={reverse(routes.estateDetails, {
                        blockId: charge.block,
                        estateId: charge.estate_details.id,
                      })}
                    >
                      {charge.estate_details.name}
                    </Link>
                  </Table.Cell>
                )}
                <Table.Cell singleLine>{chargeDate}</Table.Cell>
                <Table.Cell singleLine>{charge.fund}</Table.Cell>
                <Table.Cell singleLine>
                  <Popup
                    position={"left center"}
                    hideOnScroll={true}
                    hoverable
                    flowing
                    header={tp("component.charge-table.description_header", {
                      fund: charge.fund,
                      date: chargeDate,
                    })}
                    content={<Description charge={charge} />}
                    trigger={<a href={"#"}>{amountToBGN(charge.amount)}</a>}
                  />
                </Table.Cell>
                <Table.Cell singleLine>
                  <div>{charge.verbose_status}</div>
                  <div>
                    {charge.settlement_date &&
                      `(${formatDate(charge.settlement_date)})`}
                  </div>
                </Table.Cell>
                <Table.Cell singleLine textAlign="right">
                  <RoleRequired permission={permissions.chargeAdd}>
                    <ReturnCharge
                      estate={charge ? charge.estate_details : estate}
                      charge={charge}
                    />
                    <ChargeEdit
                      entity={charge}
                      estate={charge ? charge.estate_details : estate}
                    />
                  </RoleRequired>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
