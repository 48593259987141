import { newApi } from "../restdux/index";

export const fundAPI = newApi("fund", "/api/fund/");

export const reducer = fundAPI.reducer;

export function fetchFunds(blockId) {
  return fundAPI.actions.list({ block: blockId });
}

export function createFund(data) {
  return fundAPI.actions.create(data);
}

export function updateFund(data) {
  return fundAPI.actions.update(data);
}

export const clearError = fundAPI.actions.clearError;

export function deleteFund(data) {
  return fundAPI.actions.del(data);
}
