/* @flow */
import * as React from "react";
import { connect } from "react-redux";
import { ROLE_USER } from "../../config";

export type Props = {
  user?: Object,
  userOnly?: boolean,
  superuserOnly?: boolean,
  permission?: string,
  children?: React.Node,
};

class RoleRequiredComponent extends React.Component<Props> {
  render() {
    const {
      user,
      userOnly,
      superuserOnly,
      managerOnly,
      children,
      permission,
    } = this.props;

    if (!user) {
      return null;
    }

    if (userOnly && user.role === ROLE_USER) {
      return children;
    }

    if (superuserOnly && user.is_superuser) {
      return children;
    }
    if (managerOnly && user.is_manager) {
      return children;
    }

    if (permission && user.hasPermission(permission)) {
      return children;
    }

    return null;
  }
}

export const RoleRequired = connect((state) => {
  return { user: state.auth.user };
})(RoleRequiredComponent);
