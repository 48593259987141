export const INIT = "mecasa/header/INIT";
export const SET = "mecasa/header/SET";
export const RESET = "mecasa/header/RESET";

const initialState = {
  props: {
    title: "",
  },
  children: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT:
      return state;
    case SET:
      return {
        ...state,
        props: { title: action.title },
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export function setHeader(title, actions = {}) {
  return { type: SET, title, actions };
}

export function resetHeader() {
  return { type: RESET };
}
