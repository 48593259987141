// @flow
import * as React from "react";
import { ConfirmModalAction } from "../../components/common/confirm-modal";
import { apirequest } from "../../helpers/api-client";
import { t } from "../../constant/translations";
import {
  Message,
  Button,
  Table,
  Grid,
  Header,
  Checkbox,
} from "semantic-ui-react";
import { amountToBGN } from "../../helpers/currency";

type Props = {
  estate: any,
  disabled: ?boolean,
};

export class PayByCardAction extends React.Component<Props> {
  state = {
    loading: false,
    billingForecast: null,
    prepayEnabled: false,
    additionalAmount: 0,
  };

  componentDidMount() {
    this.fetchBillingForecast();
  }

  fetchBillingForecast = async () => {
    try {
      const result = await apirequest(
        "get",
        `/api/billing-forecast/${this.props.estate.id}/`
      );
      this.setState({ billingForecast: result.body });
    } catch (error) {
      console.error("Failed to fetch billing forecast:", error);
    }
  };

  handlePrepaySelect = (months) => {
    const { billingForecast } = this.state;
    if (months === this.state.selectedMonths) {
      this.setState({ selectedMonths: undefined, additionalAmount: 0 });
    }

    if (billingForecast && this.state.selectedMonths !== months) {
      const additionalAmount = billingForecast.total * months;
      this.setState({ additionalAmount, selectedMonths: months });
    }
  };

  onAction = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const payload = {
      ...this.props.estate,
      additional_amount: this.state.additionalAmount,
    };

    const result = await apirequest("post", `/api/checkout/init/`, payload);
    if (result.body.url) {
      window.location.replace(result.body.url);
    }
  };

  calculateFee = (amount) => {
    return Math.max(amount * 0.02, 2.0);
  };

  calculateGrandTotal = () => {
    const { estate } = this.props;
    const { additionalAmount } = this.state;
    const subtotal = parseFloat(estate.debt) + additionalAmount;
    const fee = this.calculateFee(subtotal);
    return subtotal + fee;
  };

  render() {
    const { estate } = this.props;
    const { billingForecast, prepayEnabled, additionalAmount } = this.state;
    const grandTotal = this.calculateGrandTotal();

    const content = (
      <>
        <Grid columns={1} divided>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">{t("estate.payment_details")}</Header>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{t("estate.current_debt")}</Table.Cell>
                    <Table.Cell width={4}>
                      {amountToBGN(estate.debt)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("estate.prepay_amount")}</Table.Cell>
                    <Table.Cell width={4}>
                      {amountToBGN(additionalAmount)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t("estate.transaction_fee")}</Table.Cell>
                    <Table.Cell width={4}>
                      {amountToBGN(
                        this.calculateFee(
                          parseFloat(estate.debt) + (additionalAmount || 0)
                        )
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row positive>
                    <Table.Cell>
                      <strong>{t("estate.total_amount")}</strong>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <strong>{amountToBGN(grandTotal)}</strong>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Checkbox
                toggle
                label={t("estate.enable_prepay")}
                checked={prepayEnabled}
                onChange={(e, data) =>
                  this.setState({
                    prepayEnabled: data.checked,
                    additionalAmount: 0,
                    selectedMonths: 0,
                  })
                }
              />
              {prepayEnabled && (
                <>
                  <Header as="h4">{t("estate.choose_prepay_period")}</Header>
                  {t("estate.typical_monthly_payment")}:&nbsp;
                  {billingForecast ? (
                    <strong>{amountToBGN(billingForecast.total)}</strong>
                  ) : (
                    <em>{t("estate.loading_forecast")}</em>
                  )}
                  <Button.Group fluid style={{ marginTop: "10px" }}>
                    {[1, 3, 6, 12].map((months) => (
                      <Button
                        key={months}
                        toggle={true}
                        onClick={() => this.handlePrepaySelect(months)}
                        color="grey"
                        active={this.state.selectedMonths === months}
                      >
                        {t(`estate.prepay_${months}_months`)}
                      </Button>
                    ))}
                  </Button.Group>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message
          warning
          icon="warning"
          content={t("component.pay-by-card.transaction_fee_info")}
        />
      </>
    );

    return (
      <ConfirmModalAction
        header={`${t("estate.payment_to")} ${estate.name}`}
        content={content}
        trigger_props={{
          size: "tiny",
          positive: true,
          content: t("estate.pay"),
          disabled: this.props.disabled,
        }}
        error={null}
        loading={this.state.loading}
        action={this.onAction}
        confirmLabel={`${t("estate.pay")} ${amountToBGN(grandTotal)}`}
      />
    );
  }
}
