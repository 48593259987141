import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import { NoteList } from "./list";
import NoteForm from "./form";
import { deleteNote, fetchNotes, toggleView } from "../../redux/modules/note";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";

export class NotesComponent extends React.Component {
  componentDidMount() {
    this.props.fetchNotes(this.props.entity.id, this.props.entityType);
  }

  render() {
    const {
      entity,
      entityType,
      notes,
      deleteNote,
      toggleView,
      loading,
    } = this.props;

    if (loading) {
      return (
        <Segment padded={true}>
          <Dimmer active inverted>
            <Loader active inline="centered" />
          </Dimmer>
        </Segment>
      );
    }

    return (
      <Segment>
        {notes && notes.length > 0 && (
          <NoteList
            notes={notes || []}
            deleteNote={deleteNote}
            toggleView={toggleView}
          />
        )}
        {notes && notes.length > 0 && <Divider />}
        <RoleRequired staff={true} permission={permissions.boardEdit}>
          <NoteForm
            key={`form-${notes ? notes.length : 0}`}
            entity={entity}
            entityType={entityType}
          />
        </RoleRequired>
      </Segment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    notes: state.note[props.entityType] || null,
    loading: state.note.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteNote, fetchNotes, toggleView }, dispatch);
}

export const Notes = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesComponent);
export default Notes;
