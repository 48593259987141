import { newApi, request } from "../restdux/index";
import { addEntity, getById } from "../restdux/utils";
import { noteManager } from "./note";
import { estatesApi, SELECT } from "./estates";

export const chargeAttrManager = newApi("charge", "/api/charge/", true);

export function reducer(state, action) {
  if (action.type === chargeAttrManager.actionTypes.UPDATE_SUCCESS) {
    // Since charge update does "delete" and "re-create" to update (reverts transactions in the process)
    // we change the id of the original entity, so vanilla reducer will figure it out
    // and update what it needs to
    const editedCharge = getById(action.data.id, state.entities);
    editedCharge.id = action.result.id;
  }
  return chargeAttrManager.reducer(state, action);
}

export type ChargeDetails = {
  model: string,
  value: string,
  amount: string,
  model_price: string,
  model_min_price: string,
};

type EstateDetails = {
  id: number,
  name: string,
  vendor_id: string,
};

export type Charge = {
  amount: string,
  amount_left: string,
  charge_date: string,
  description: string,
  details: Array<ChargeDetails>,
  estate: number,
  estate_details: EstateDetails,
  fund: string,
  id: number,
  settlement_date: ?string,
  status: string,
  verbose_description: ?string,
  verbose_status: string,
};

export function fetchEstateCharges(estateId, query = {}, namespace) {
  return chargeAttrManager.actions.list(
    { estate: estateId, ...query },
    { namespace }
  );
}

export function fetchCharges(query = {}) {
  return chargeAttrManager.actions.list({ ...query });
}

export function createCharge(data) {
  return chargeAttrManager.actions.create(data, { pushToFront: true });
}

export function editCharge(data) {
  return chargeAttrManager.actions.update(data, { data });
}

export function deleteCharge(data) {
  return chargeAttrManager.actions.del(data);
}

export function createPaymentReturn(data) {
  return (dispatch, getState, client) => {
    const getPromise = () =>
      client.post(`/api/charge/${data.charge_id}/cancel_payments/`, { data });
    return request(
      chargeAttrManager.actionTypes.CREATE_PENDING,
      chargeAttrManager.actionTypes.CREATE_SUCCESS,
      chargeAttrManager.actionTypes.CREATE_ERROR
    )(getPromise, dispatch);
  };
}

export const clearError = chargeAttrManager.clearError;
