// @flow
import styled from "styled-components";
import React, { Fragment, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import { connect } from "react-redux";
import {
  listMonthlyReports,
  regeneratePeriod,
} from "../../../redux/modules/billing-monthly-report";
import { bindActionCreators } from "redux";
import { Modal, Loader, Button } from "semantic-ui-react";
import { usePagination } from "../../../components/common/pagination";
import dayjs from "dayjs";
import { amountToBGN } from "../../../helpers/currency";
import { device } from "../../../config";
import { reverse, routes } from "../../../routes";
import { Link } from "react-router-dom";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";
import { ConfirmModalAction } from "../../../components/common/confirm-modal";
import { ErrorWrap } from "../../../helpers/error";
import { t } from "../../../constant/translations";

type Props = {};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :last-child {
    flex-shrink: 1;
  }

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ReloadChargesModal = (props) => {
  return (
    <RoleRequired permission={permissions.billingTransactionEdit}>
      <ConfirmModalAction
        header={`${t("charge.regen_auto_debt")} ${props.periodStart} - ${
          props.periodEnd
        }`}
        content={
          <>
            <div>{t("charge.generate_for_period")}</div>
            <div>{t("charge.needed_if_change")}</div>
            <div>{t("charge.payment_restored")}</div>
          </>
        }
        error={props.error}
        action={props.action}
        loading={props.loading}
        confirmLabel={t("charge.regenerate")}
        triggerTooltipText={t("charge.recalc_auto_debt")}
        trigger_props={{
          basic: true,
          size: "mini",
          icon: "undo",
        }}
      />
    </RoleRequired>
  );
};

export const ByPeriodTableComponent = (props: Props) => {
  const {
    block,
    listMonthlyReports,
    regeneratePeriod,
    entities,
    loading,
  } = props;
  useEffect(() => {
    listMonthlyReports(block.id);
  }, [block.id]);
  const [page, paginationComponent] = usePagination(entities, 6);

  if (!page || loading) {
    return <Loader active={true} />;
  }

  return (
    <Fragment>
      <h5>{t("charge.for_period")}</h5>
      {paginationComponent}
      <DataTable stackable={true} data={page}>
        <DataTableColumn
          align="left"
          name={t("charge.period")}
          render={(el) => {
            const link = reverse(routes.blockChargeList, { blockId: block.id });
            return (
              <Link to={`${link}?from=${el.period_start}&to=${el.period_end}`}>
                {el.period_start}
              </Link>
            );
          }}
        />
        <DataTableColumn
          align="right"
          name={t("charge.automatically_billed")}
          render={(el) => {
            return (
              <>
                <span>
                  {el.auto_charges_num || 0} {t("charge.unit")}.
                </span>{" "}
                / <span>{amountToBGN(el.auto_charges_amount)}</span>
              </>
            );
          }}
        />
        <DataTableColumn
          align="right"
          name=""
          render={(el) => {
            return (
              <ReloadChargesModal
                periodStart={el.period_start}
                periodEnd={el.period_end}
                error={props.regenerateError}
                loading={props.regeneratePending}
                action={() => {
                  regeneratePeriod(el);
                }}
              />
            );
          }}
        />
      </DataTable>
      <StyledFooter>
        <Link to={reverse(routes.blockChargeList, { blockId: block.id })}>
          {t("charge.all_debt")}
        </Link>
      </StyledFooter>
    </Fragment>
  );
};

function mapStateToProps(state) {
  var entities = state.billingMonthlyReport.entities || [];
  return {
    block: state.blocks.selected,
    entities:
      entities.length > 0 ? entities.filter((e) => !e.is_legacy_period) : [],
    loading: state.billingMonthlyReport.pending,
    error: state.billingMonthlyReport.error,
    regenerateError: ErrorWrap.fromError(
      state.billingMonthlyReport.regenerateError
    ),
    regeneratePending: state.billingMonthlyReport.regeneratePending,
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      listMonthlyReports,
      regeneratePeriod,
    },
    dispatch
  );
}

export const ByPeriodTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ByPeriodTableComponent);
