import styled from "styled-components";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Checkbox, Dropdown } from "semantic-ui-react";
import { withRouter } from "react-router";
import { routes, reverse } from "../../routes";
import { UserFile } from "./user-file";
import { RoleRequired } from "../auth/role-required";
import { t } from "../../constant/translations";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  createTicket,
  deleteTicket,
  updateTicket,
} from "../../redux/modules/ticket";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import type { FormProps } from "../../components/common/edit-entity";
import { ROLE_USER } from "../../config";
import { MarkdownEdit } from "../../components/common/markdownedit";
import { filterByProp } from "../../redux/restdux/utils";
import { permissions } from "../../constant/permissions";

const Error = styled.span`
  color: red;
`;

export class TicketEditComponent extends Component<FormProps> {
  state = { formData: null };

  render() {
    const { error, users, entity, authUser } = this.props;
    const initialState = getInitialState(entity, {
      internal: entity ? entity.internal : authUser.role !== ROLE_USER,
    });
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );
    const header = entity ? t("ticket.change_signal") : t("ticket.send_signal");
    const selectCustomerOptions = filterByProp(users, { is_active: true }).map(
      (p) => ({
        key: p.id,
        text: `${p.first_name} ${p.last_name}(${p.email})`,
        value: p.id,
        selected: entity && p.id === entity.assignee.id,
      })
    );

    const selectStatusOptions = [
      "open",
      "in_progress",
      "resolved",
      "invalid",
    ].map((state) => {
      return { text: t(`ticketStates.${state}`), value: state };
    });

    const assigneeProps = { ...manager.getAttributes("assignee.id") };
    delete assigneeProps.value;

    return (
      <EditEntity manager={manager} {...this.props} header={header}>
        <Form.Field>
          <label>{t("labels.title")}</label>
          <Form.Input
            placeholder={t("labels.title")}
            error={error.has("title")}
            {...manager.getAttributes("title")}
          />
          {error.has("title") && <Error>{error.get("title")}</Error>}
        </Form.Field>
        <Form.Field>
          <label>{t("labels.description")}</label>
          <MarkdownEdit {...manager.getAttributes("description")} rows={3} />
          {error.has("description") && (
            <Error>{error.get("description")}</Error>
          )}
        </Form.Field>
        <Form.Field>
          <label>{t("labels.type")}</label>
          <Form.Select
            plaholder={t("ticket.issue_type")}
            options={["technical", "other"].map((value) => {
              return { text: t(`ticketTypes.${value}`), value };
            })}
            {...manager.getAttributes("ticket_type")}
          />
        </Form.Field>

        <RoleRequired staff={true} permission={permissions.ticketEdit}>
          <Form.Field>
            <label>{t("labels.status")}</label>
            <Form.Select
              placeholder={t("labels.status")}
              options={selectStatusOptions}
              {...manager.getAttributes("status")}
            />
          </Form.Field>
          <Form.Field>
            <label>{t("labels.assignee")}</label>
            <Dropdown
              placeholder={t("ticket.select_user")}
              search
              selection
              defaultValue={entity && entity.assignee.id}
              options={selectCustomerOptions}
              {...assigneeProps}
            />
            {error.has("assignee") && <Error>{error.get("assignee")}</Error>}
          </Form.Field>

          <Form.Field>
            <Checkbox
              toggle
              label={t("ticket.official")}
              checked={Boolean(manager.getValue("internal"))}
              onClick={() => {
                manager.setValue("internal", !manager.getValue("internal"));
              }}
            />
          </Form.Field>
        </RoleRequired>
        <Form.Field>
          <UserFile name="files" {...manager.getAttributes("files")} />
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.ticket.error),
    loading: state.ticket.pending,
    users: state.user.entities,
    authUser: state.auth.user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const create = (data) => (dispatch) => {
    dispatch(createTicket({ block: ownProps.block.id, ...data })).then(
      (action) => {
        ownProps.history.push(
          reverse(routes.ticketDetails, {
            blockId: ownProps.block.id,
            ticketId: action.result.id,
          })
        );
      }
    );
  };

  const update = (data) => (dispatch) => {
    dispatch(updateTicket({ ...data, files: data.files || [] }));
  };

  const del = (data) => (dispatch) => {
    dispatch(deleteTicket(data)).then(
      ownProps.history.push(
        reverse(routes.ticketList, {
          blockId: ownProps.block.id,
        })
      )
    );
  };

  return bindActionCreators({ create, update, clearError, del }, dispatch);
}

export const TicketEdit = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TicketEditComponent)
);
