import { newApi } from "../restdux/index";

export const boardPostManager = newApi("boardpost", "/api/board-post/");
export const reducer = boardPostManager.reducer;

export function fetchBoardPosts(blockId) {
  return boardPostManager.actions.list({ block: blockId });
}

export function deleteBoardPost(post) {
  return boardPostManager.actions.del(post);
}

export function createBoardPost(post) {
  return boardPostManager.actions.create(post);
}

export function updateBoardPost(post) {
  return boardPostManager.actions.update(post);
}
