import React from "react";

import {
  Button,
  Form,
  FormField,
  Header,
  Input,
  Message,
  Segment,
} from "semantic-ui-react";
import { reset, resetPassword } from "../../redux/modules/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { ErrorWrap } from "../../helpers/error";
import { routes } from "../../routes";
import { StyledLinks } from "./styled-components";
import { t } from "../../constant/translations";

type Props = {
  error?: Object,
  login?: Function,
  setField?: Function,
  resetPassword: Function,
  loading: boolean,
};

class PasswordResetComponent extends React.Component<Props> {
  state = {
    data: {},
  };

  componentDidMount() {
    this.props.reset();
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.resetPassword(this.state.data.email);
  }

  setField(name, value) {
    const data = this.state.data || {};
    if (value) {
      data[name] = value;
    } else {
      delete data[name];
    }
    this.setState({ data: data });
  }

  render() {
    const error = new ErrorWrap(this.props.error);
    return (
      <Form
        action=""
        onSubmit={this.onSubmit.bind(this)}
        error={error.exists()}
      >
        <Segment>
          <Header as={"h4"} dividing={false} textAlign={"center"}>
            {t("login.pass_manage")}
          </Header>
          <FormField>
            <Input
              name="email"
              icon="user"
              iconPosition="left"
              placeholder="Е-mail"
              error={error.exists()}
              onChange={(event) => this.setField("email", event.target.value)}
            />
          </FormField>
          <StyledLinks>
            <Link to={routes.authLogin}>{t("login.tologin")}</Link>
          </StyledLinks>

          <Message error content={error.getGlobal()} />
          <Button
            type="submit"
            fluid
            size="large"
            loading={this.props.loading}
            disabled={!this.state.data.email}
          >
            {t("labels.forwards")}
          </Button>
        </Segment>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    userKey: state.auth.userKey,
    loading: state.auth.loggingIn,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const resetAndRedirect = (data) => {
    return (dispatch) => {
      dispatch(resetPassword(data)).then(() =>
        ownProps.history.push(routes.authPasswordResetSuccess)
      );
    };
  };

  return bindActionCreators(
    { resetPassword: resetAndRedirect, reset },
    dispatch
  );
};

export const PasswordReset = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetComponent);
