/* @flow */
import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addNote, getCreateError } from "../../redux/modules/note";
import { cmdEnter } from "../../helpers/cmd-enter";
import { t } from "../../constant/translations";

type Props = {
  entity: Object,
  entityType: string,
  addNote: Function,
  error?: Object,
};

export class NoteForm extends Component<Props> {
  state = {};

  onSubmit = (event) => {
    event.preventDefault();
    this.props.addNote(
      this.props.entity.id,
      this.props.entityType,
      this.state.content
    );
  };

  render() {
    const { error } = this.props;
    return (
      <Form reply onSubmit={this.onSubmit} error={error !== null}>
        <Form.Field>
          <Form.TextArea
            name="content"
            required
            label={t("note.new_note")}
            onChange={(event) => this.setState({ content: event.target.value })}
            placeholder={t("note.content")}
            onKeyDown={cmdEnter(this.onSubmit)}
          />
        </Form.Field>
        <Message
          error={true}
          header={t("note.error")}
          content={error ? error.message : error}
        />
        <Form.Button type="submit" primary disabled={!this.state.content}>
          {t("note.add")}
        </Form.Button>
      </Form>
    );
  }
}

function stateToProps(state) {
  return { error: getCreateError(state) };
}

function dispatchToProps(dispatch) {
  return bindActionCreators({ addNote }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(NoteForm);
