import styled from "styled-components";
import React from "react";
import {
  Grid,
  GridColumn as Col,
  GridRow as Row,
  Segment,
  Statistic,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { entityGet } from "../../../helpers/filter";
import { RoleRequired } from "../../auth/role-required";
import { amountToBGN } from "../../../helpers/currency";
import { permissions } from "../../../constant/permissions";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import { t } from "../../../constant/translations";

const BlockFundSummary = ({ summary, block, user }) => {
  const totalCharges = summary
    ? summary.charges_total
    : { current: 0, overdue: 0 };

  return (
    <Segment>
      <Grid relaxed>
        <RoleRequired
          admin={true}
          permission={permissions.billingAccountViewBalance}
        >
          <Row>
            <Col computer={16}>
              <Grid columns="equal" divided centered>
                <Col textAlign={"center"}>
                  <Statistic
                    label={t("fund.balance")}
                    value={amountToBGN(block.balance)}
                    size="mini"
                  />
                </Col>
                <Col textAlign={"center"}>
                  <Statistic
                    label={t("block.available")}
                    value={amountToBGN(block.at_disposal)}
                    size="mini"
                  />
                </Col>
              </Grid>
            </Col>
          </Row>
        </RoleRequired>

        <Row>
          <Col computer={16}>
            <DataTable data={block.funds} unstackable={true}>
              <DataTableColumn render={(fund) => fund.name} />
              <DataTableColumn
                name={t("block.current_liabilities")}
                align={"right"}
                render={(fund) => amountToBGN(totalCharges.current[fund.name])}
              />
              <DataTableColumn
                name={t("block.arrears")}
                align={"right"}
                render={(fund) => amountToBGN(totalCharges.overdue[fund.name])}
              />
              <DataTableColumn
                name={t("fund.balance")}
                align={"right"}
                hidden={
                  !user.hasPermission(permissions.billingAccountViewBalance)
                }
                render={(fund) => amountToBGN(fund.balance)}
              />
            </DataTable>
          </Col>
        </Row>
      </Grid>
    </Segment>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.auth.user,
    summary: entityGet(
      state.billingSummary.entities,
      (e) => e.id === props.block.id
    ),
  };
}

export default connect(mapStateToProps)(BlockFundSummary);
