export function formatPhoneNumber(phoneNumber = "") {
  phoneNumber = phoneNumber || "";
  const phoneArray = phoneNumber.split("");

  const groups = [2, 2, 2, 3, 3];
  const result = [];
  let left = groups.shift();

  phoneArray.reverse().forEach((entry) => {
    result.push(entry);
    left -= 1;
    if (left === 0 && groups.length > 0) {
      result.push(" ");
      left = groups.shift();
    }
  });
  return result.reverse().join("");
}
