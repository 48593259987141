import styled from "styled-components";
import {
  clearError,
  deactivateBlock,
  updateBlock,
} from "../../redux/modules/blocks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ErrorWrap } from "../../helpers/error";
import {
  Checkbox,
  Divider,
  Form,
  FormField,
  Input,
  Message,
} from "semantic-ui-react";
import React from "react";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import type { FormProps } from "../../components/common/edit-entity";
import { EditEntity } from "../../components/common/edit-entity";
import { t } from "../../constant/translations";

const StyledSpan = styled.span`
  margin: 0 10px;
`;

const StyledInput = styled(Input)`
  > input {
    width: 50px !important;
  }
`;

export class BlockEditComponent extends React.Component<FormProps> {
  state = {
    formData: null,
  };

  render() {
    const { error, entity, del } = this.props;
    const initialData = getInitialState(
      this.props.entity,
      this.props.initialData
    );
    const manager = new FormdataManager(
      this.state.formData || initialData,
      (formData) => this.setState({ formData }),
      initialData
    );
    const header = this.props.entity
      ? `${t("fund.change_of")} ${this.props.entity.name}`
      : t("block.new_building");

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={header}
        deleteProps={{
          triggerText: t("block.archive"),
          header: `${t("block.to_archive")} ${this.props.entity.name}`,
          content: t("block.archiving_disable_attr"),
        }}
        trigger_props={{
          floated: "right",
        }}
        del={entity && entity.active ? del : null}
      >
        <FormField>
          <label>{t("labels.name")}</label>
          <Input
            placeholder={t("labels.name")}
            {...manager.getAttributes("name")}
          />
        </FormField>
        <FormField>
          <label>{t("block.address")}</label>
          <Input
            placeholder={t("block.address")}
            {...manager.getAttributes("address")}
          />
        </FormField>
        <Divider horizontal>{t("side.accounting")}</Divider>
        <Form.Field>
          <Message>
            <Checkbox
              toggle
              label={t("block.auto_generate_rep")}
              checked={manager.getValue("config.billing.enabled")}
              onClick={() => {
                const name = "config.billing.enabled";
                manager.setValue(name, !manager.getValue(name));
              }}
            />
            <p>{t("block.configured_budget_info")}</p>
          </Message>
        </Form.Field>
        <Form.Field>
          <Message>
            <Checkbox
              toggle
              label={t("block.auto_notifications_debts")}
              checked={manager.getValue("config.billing.charge_emails_enabled")}
              onClick={() => {
                const name = "config.billing.charge_emails_enabled";
                manager.setValue(name, !manager.getValue(name));
              }}
            />
            <p>{t("block.auto_email_notifs")}</p>
          </Message>
        </Form.Field>
        <Form.Field>
          <Message>
            <Checkbox
              toggle
              label={t("block.virtual_dash")}
              checked={manager.getValue("config.board.enabled")}
              onClick={() => {
                const name = "config.board.enabled";
                manager.setValue(name, !manager.getValue(name));
              }}
            />
            <p>{t("block.allows_access_email")}</p>
          </Message>
        </Form.Field>
        <Form.Field>
          <Message>
            <Checkbox
              toggle
              label={t("block.epay_easypay_integr")}
              checked={manager.getValue("config.billing.settlement_enabled")}
              onClick={() => {
                const name = "config.billing.settlement_enabled";
                manager.setValue(name, !manager.getValue(name));
              }}
            />
            <p>{t("block.current_payments_info")}</p>
          </Message>
        </Form.Field>
        <Form.Field>
          <Message>
            <Checkbox
              toggle
              label={t("block.card_payments")}
              checked={manager.getValue(
                "config.billing.card_settlement_enabled"
              )}
              onClick={() => {
                const name = "config.billing.card_settlement_enabled";
                manager.setValue(name, !manager.getValue(name));
              }}
            />
            <p>{t("block.allows_card_payment")}</p>
          </Message>
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.blocks.error),
    loading: state.blocks.pending,
    initialData: {
      config: {
        billing: {
          enabled: true,
          settlement_enabled: true,
          day_of_month: 1,
          months: 1,
        },
        board: {
          enabled: false,
        },
      },
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      update: updateBlock,
      clearError,
      del: deactivateBlock,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockEditComponent);
