import { getReducer } from "./reducer";
import { getRestActionTypes, formatActionTypeBuilder } from "./action-types";
import type { Actions } from "./actions";
import { getActions } from "./actions";

export { request } from "./actions";

type API = {
  resounse: Object,
  url: string,
  actionTypes: Object,
  reducer: Function,
  actions: Actions,
};

export const defaultInitialState = {
  error: {},
  entities: null,
  pending: false,
};

export function newApi(
  resource: string,
  url: string,
  paginated: boolean = false
): API {
  const actionTypes = getRestActionTypes(resource);
  return {
    resource: resource,
    url: url,
    actionTypes: actionTypes,
    reducer: getReducer(actionTypes, defaultInitialState, paginated),
    actions: getActions(url, actionTypes),
    formatAction: formatActionTypeBuilder(resource),
  };
}

export default newApi;
