import { newApi } from "../restdux/index";

export const estateCustomersApi = newApi(
  "estate-customers",
  "/api/estate-customers/"
);

export const reducer = estateCustomersApi.reducer;

export function fetchEstateCustomers(blockId) {
  return estateCustomersApi.actions.list({ block: blockId });
}

export function createEstateCustomer(data) {
  return estateCustomersApi.actions.create(data);
}

export const clearError = estateCustomersApi.actions.clearError;

export function deleteEstateCustomer(data) {
  return estateCustomersApi.actions.del(data);
}
