import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import { t } from "../../../constant/translations";

class ChargeModelSelectComponent extends Component {
  render() {
    let opts = [];
    if (this.props.charges) {
      const optSet = new Set();
      this.props.charges.forEach((charge) => {
        charge.details.forEach((dt) => {
          optSet.add(dt.model);
        });
      });
      opts = Array.from(optSet).map((opt) => {
        return { text: opt, value: opt };
      });
      opts.unshift({ text: t("charge.no_matter"), value: null });
    }

    return (
      <Form.Select
        options={opts}
        value={this.props.value || ""}
        onChange={this.props.onChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    charges: state.charge.entities,
  };
}

export const ChargeModelSelect = connect(mapStateToProps)(
  ChargeModelSelectComponent
);
