import styled from "styled-components";

import React from "react";
import { Route } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { withMedia } from "react-media-query-hoc";
import { bindActionCreators } from "redux";

import Error from "../error";
import AuthWrap from "../auth/auth-wrap";
import { TicketPage } from "../ticket/page";
import BlockMenu from "./menu/top";
import BlockLanding from "../block/list";
import BlockDetailsContainer from "../block/details-container";

import { SideMenu } from "./menu/side";
import { GlobalEstateList } from "../estate/list";

import { reverse, routes } from "../../routes";
import { blockManager, getListError } from "../../redux/modules/blocks";
import { fetchUsers } from "../../redux/modules/user";
import { withRouter } from "react-router";
import { ManagementPage } from "../management/management-page";
import { Toaster } from "react-hot-toast";
import qs from "qs";
import { fetchSettings } from "../../redux/modules/app-settings";

const StyledBlockContainer = styled.div`
  height: 100%;
  background-color: #eeeeee;
`;

class AppContainer extends React.Component {
  state = { sideMenu: false };

  componentDidMount() {
    this.props.fetchSettings();
    this.props.fetchUsers();

    if (!this.props.blocks) {
      this.props.fetchBlocks();
    }
  }

  render() {
    const { media, location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const docked = media.desktop && !params.wide;

    if (!this.props.blocks) {
      return <Loader active />;
    }
    if (this.props.error) {
      return <Error error={this.props.error} />;
    }
    return (
      <StyledBlockContainer>
        <Toaster position={"bottom-center"} />
        <SideMenu
          visible={this.state.sideMenu || docked}
          docked={docked}
          blocks={this.props.blocks}
          selected={this.props.selected}
          toggleVisibility={(open) => this.setState({ sideMenu: open })}
        >
          <BlockMenu
            toggleVisibility={() =>
              this.setState({ sideMenu: !this.state.sideMenu })
            }
            sideMenuVisible={this.state.sideMenu}
            sideMenuDocked={docked}
          />
          <Route path={routes.blockList} component={BlockLanding} />
          <Route path={routes.estateList} component={GlobalEstateList} />
          <Route path={routes.manage} component={ManagementPage} />
          <Route
            path={routes.tickets}
            render={(props) => <TicketPage globalView={true} {...props} />}
          />
          <Route path={routes.blockBase} component={BlockDetailsContainer} />
        </SideMenu>
      </StyledBlockContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const blockState = state.blocks;
  return {
    user: state.auth.user,
    blocks: blockState.entities,
    error: getListError(blockState),
    selected: blockState.selected,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      fetchSettings,
      fetchUsers,
      fetchBlocks: () => (dispatch) => {
        dispatch(blockManager.actions.list()).then((result) => {
          const singleBlock = result.result.length === 1;
          const shouldRedirect = [routes.baseURL, routes.blockList].includes(
            ownProps.location.pathname
          );

          if (shouldRedirect) {
            if (singleBlock) {
              const blockId = result.result[0].id;
              ownProps.history.replace(
                reverse(routes.blockDetails, { blockId })
              );
            } else {
              ownProps.history.replace(routes.blockList);
            }
          }
        });
      },
    },
    dispatch
  );
};

export const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(withMedia(AuthWrap(withRouter(AppContainer))));
