import { applyMiddleware, createStore } from "redux";
import clientMiddleware from "./middleware/client-middleware";
import AppReducer from "./modules/reducer";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import ApiClient from "../helpers/api-client";

export default function createCustomStore() {
  const client = new ApiClient();
  return createStore(
    AppReducer,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware.withExtraArgument(client),
        clientMiddleware(client)
      )
    )
  );
}

export const store = createCustomStore();
