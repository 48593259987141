import React, { useEffect, useState } from "react";
import { apirequest } from "../../helpers/api-client";
import { TotalTable } from "./charge/total";
import { ByPeriodTable } from "./charge/by-period";
import { PerEstateTable } from "./charge/by-property";
import { Grid, Loader } from "semantic-ui-react";
import { connect } from "react-redux";

export const BlockChargesPageComponent = ({ block }) => {
  const [stats, setStats] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const effect = async () => {
      const result = await apirequest("get", `/api/reports/current-charges/`, {
        block: block.id,
      });
      setStats(result.body);
      setLoading(false);
    };
    effect();
  }, [block.id]);

  if (loading) {
    return <Loader active={true} />;
  }
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <ByPeriodTable data={stats} />
        </Grid.Column>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <TotalTable data={stats} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <PerEstateTable
            data={stats}
            blockId={block.id}
            blockName={block.name}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected,
  };
};

export const BlockChargesPage = connect(mapStateToProps)(
  BlockChargesPageComponent
);
