import styled from "styled-components";
import React from "react";
import { Button, Dropdown, Segment } from "semantic-ui-react";
import { BackButton } from "../common/back-button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchEstates } from "../../redux/modules/estates";
import qs from "qs";
import { Loading } from "../../components/common/loading";
import { getUrlParams } from "../../components/common/urlparams";
import { RoleRequired } from "../auth/role-required";
import { withRouter } from "react-router";
import { withMedia } from "react-media-query-hoc";
import { PaginationWithQuery } from "../../components/common/pagination";
import {
  ContentFilterFormBody,
  estateListQueryFilterParams,
} from "./estate-content-filter";
import {
  ContentFilterForm,
  ContentFilterPanel,
  ContentFilterTrigger,
  isFiltered,
} from "../../components/common/content-filter";
import { permissions } from "../../constant/permissions";
import { EstateEditModal } from "./estate-edit-modal";
import { DisplayCreatedSettlement } from "./settlement-created";
import { EstateTableComponent } from "./estate-table";
import { Grid } from "../../components/base/elements";
import { DownloadLink } from "../../helpers/download-link";
import { t } from "../../constant/translations";

const StyledControlHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
`;

const StyledGrid = styled(Grid)`
  min-height: 100%;
`;

const StyledPaginationWithQuery = styled(PaginationWithQuery)({
  "margin-top": "0!important",
  "margin-bottom": "0!important",
});

const EstateTable = Loading(EstateTableComponent);

const EstateSortButton = withRouter(({ location, history }) => {
  const sortBy = (key) => {
    const params = getUrlParams(location);
    if (key) {
      params.ordering = key;
    } else {
      delete params.ordering;
    }
    history.push({
      pathname: location.pathname,
      search: qs.stringify(params, { addQueryPrefix: true }),
    });
  };

  const sortColumn = getUrlParams(location).ordering;
  const sortIcon =
    sortColumn && sortColumn[0] === "-" ? "sort down" : "sort up";
  const transmap = {
    debt: t("billing.obligations"),
    "-debt": t("billing.obligations"),
  };
  return (
    <Dropdown
      icon={null}
      trigger={
        <Button
          size="tiny"
          content={transmap[sortColumn] || t("resources.estate_capital")}
          icon={sortIcon}
          basic={true}
        />
      }
      header={t("estate.sorting")}
      options={[
        { key: 1, text: t("resources.estate_capital"), value: "" },
        { key: 2, text: t("estate.desc_liabiliies"), value: "-debt" },
        { key: 3, text: t("estate.asc_liabiliies"), value: "debt" },
      ]}
      value={sortColumn || ""}
      onChange={(event, data) => {
        sortBy(data.value || null);
      }}
    />
  );
});

const EstateDownloadButton = withRouter(({ location, history, block }) => {
  let download = t("estate.properties") + ".csv";
  let queryParams;
  if (block) {
    download = `${block.name}-${download}`;
  }

  queryParams = qs.stringify(
    {
      ...location.query,
      block: block && block.id,
      format: "csv",
    },
    { addQueryPrefix: false }
  );
  const url = `/api/estate/?${queryParams}`;

  return (
    <DownloadLink
      href={url}
      target={"_blank"}
      download={download}
      icon={"download"}
      size="tiny"
      basic={true}
    />
  );
});

class EstateList extends React.Component {
  state = {
    filterOpen: false,
  };

  componentDidMount() {
    this.loadEstates(this.props);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      this.loadEstates(this.props);
    }
  }

  loadEstates = (props) => {
    this.props.loadEstates(getUrlParams(props.location));
  };

  render() {
    const { estates, loading, meta, location, block, media, user } = this.props;
    const entries = meta ? meta.count : 0;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    return (
      <StyledGrid centered>
        <Grid.Column textAlign={"left"}>
          <StyledControlHead>
            {(query.profile_id || query.name) && <BackButton size={"tiny"} />}
            <StyledPaginationWithQuery entries={entries} />
            <div>
              <EstateSortButton />
              <ContentFilterTrigger
                onClick={() =>
                  this.setState({ filterOpen: !this.state.filterOpen })
                }
                queryParamConfig={estateListQueryFilterParams}
                media={media}
              />
              {block && (
                <RoleRequired staff={true} permission={permissions.estateEdit}>
                  <EstateEditModal
                    initialData={{ charging_enabled: true, block_id: block.id }}
                    buttonContent={
                      media.handheld ? t("estate.new_property") : null
                    }
                  />
                </RoleRequired>
              )}
              <DisplayCreatedSettlement />
              <EstateDownloadButton block={block} />
            </div>
          </StyledControlHead>
          <ContentFilterPanel
            open={this.state.filterOpen}
            onApply={() => {
              this.setState({ filterOpen: false });
            }}
            queryParamConfig={estateListQueryFilterParams}
            FormBody={ContentFilterFormBody}
          >
            <EstateTable
              estates={estates}
              loading={loading}
              blockId={block ? block.id : null}
              user={user}
              media={media}
            />
            {!loading && <StyledPaginationWithQuery entries={entries} />}
          </ContentFilterPanel>
        </Grid.Column>
      </StyledGrid>
    );
  }
}

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    > div {
      max-width: 800px;
    }
  }

  @media (min-width: 100px) {
    padding: 1em 1em;
    > div {
      width: 100%;
    }
  }
`;

const EstateListGlobalComponent = (props) => {
  if (!isFiltered(estateListQueryFilterParams)) {
    return (
      <CenterContent>
        <Segment>
          <h2> {t("estate.property_search")} </h2>
          <ContentFilterForm
            queryParamConfig={estateListQueryFilterParams}
            FormBody={ContentFilterFormBody}
          />
        </Segment>
      </CenterContent>
    );
  }
  return <EstateList {...props} />;
};

const mapStateToPropsGlobal = (state, props) => {
  return {
    estates: state.estates.entities,
    meta: state.estates.meta,
    loading: state.estates.pending,
    user: state.auth.user,
  };
};

const mapDispatchToPropsGlobal = (dispatch, props) => {
  const loadEstates = (params) => {
    const query = { ...params };
    if (!query.q) {
      query.active_only = true;
    }
    return fetchEstates(query);
  };

  return bindActionCreators({ loadEstates }, dispatch);
};

const mapStateToProps = (state, props) => {
  return {
    ...mapStateToPropsGlobal(state, props),
    block: state.blocks.selected,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const loadEstates = (params) => {
    return fetchEstates({ block: props.match.params.blockId, ...params });
  };

  return bindActionCreators({ loadEstates }, dispatch);
};

export const BlockEstateList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withMedia(EstateList))
);
export const GlobalEstateList = withRouter(
  connect(
    mapStateToPropsGlobal,
    mapDispatchToPropsGlobal
  )(withMedia(EstateListGlobalComponent))
);
