export function addEntity(entity, entities, pushToFront = false) {
  let exists = false;
  const checkEntities = entities || [];
  const newEntities = checkEntities.map((checkEntity) => {
    if (checkEntity.id === entity.id) {
      exists = true;
      return entity;
    }
    return checkEntity;
  });

  if (!exists) {
    const method = pushToFront ? "unshift" : "push";
    newEntities[method](entity);
  }
  return newEntities;
}

export function removeEntity(entity, entities) {
  const newEntities = [];
  for (const checkEntity of entities) {
    if (checkEntity.id !== entity.id) {
      newEntities.push(checkEntity);
    }
  }
  return newEntities;
}

export function replaceEntity(prevEntity, newEntity, entities) {
  const newEntities = [];
  for (let checkEntity of entities) {
    if (checkEntity.id === prevEntity.id) {
      checkEntity = newEntity;
    }
    newEntities.push(checkEntity);
  }
  return newEntities;
}

export function getById(entityId, entities) {
  entityId = parseInt(entityId);
  entities = entities || [];
  for (const entity of entities) {
    if (entity.id === entityId) {
      return entity;
    }
  }
}

export function filterByProp(entities, propsForMatching) {
  const matched = [];
  for (const entity of entities) {
    let noMatch = false;
    for (const key of Object.keys(propsForMatching)) {
      if (entity[key] !== propsForMatching[key]) {
        noMatch = true;
      }
    }
    if (!noMatch) {
      matched.push(entity);
    }
  }
  return matched;
}

export function getSpecificError(actionType, state) {
  if (state.error.type === actionType) {
    return state.error.error;
  }
  return null;
}

export function getError(state) {
  return state.error;
}

export function sortByCreatedAt(entities) {
  return entities.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
}

export function modifiedSuccess(entity, state) {
  return state && state.lastModified && state.lastModified.id === entity.id;
}
