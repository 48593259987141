import superagent from "superagent";
import Cookies from "js-cookie";
import { getCurrentLanguage } from "../constant/translations";

const methods = ["get", "post", "put", "patch", "del"];

export default class ApiClient {
  constructor(req) {
    methods.forEach(
      (method) =>
        (this[method] = (path, { params, data } = {}) =>
          new Promise((resolve, reject) => {
            const request = superagent[method](path);

            if (params) {
              request.query(params);
            }

            if (data) {
              request.send(data);
            }

            if (["post", "put", "del"].includes(method)) {
              request.set("X-CSRFToken", Cookies.get("csrftoken"));
            }
            request.set("Content-Type", "application/json; charset=utf-8");
            request.set(
              "Accept-Language",
              localStorage.getItem("current--language")
            );

            request.end((err, { body, status } = {}) => {
              if (err) {
                const err = body || err;
                return reject({ ...err, status });
              } else {
                return resolve(body);
              }
            });
          }))
    );
  }
}

export const apirequest = (method, url, data) => {
  return superagent[method.toLowerCase()](url, data)
    .set("X-CSRFToken", Cookies.get("csrftoken"))
    .set("Content-Type", "application/json; charset=utf-8")
    .set("Accept-Language", getCurrentLanguage());
};

export const apirequestWithFile = (method, url, file) => {
  return superagent[method.toLowerCase()](url)
    .set("X-CSRFToken", Cookies.get("csrftoken"))
    .set("Accept-Language", getCurrentLanguage())
    .attach("file", file);
};
