import getJSONGlobals from "safe-json-globals/get";

export function getStaticUrl(path) {
  const base = getJSONGlobals("static_url");
  return base + path;
}

export function imgSrc(filename) {
  return getStaticUrl("/img/" + filename);
}
