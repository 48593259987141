// @flow
import { newApi } from "../restdux/index";

export const userRoleManager = newApi("userRole", "/api/user-role/");
export const reducer = userRoleManager.reducer;

export type RolePolicy = {
  permissions: Array<string>,
};

export type UserRole = {
  id: number,
  name: string,
  policy: RolePolicy,
};

export function fetchUserRoles() {
  return userRoleManager.actions.list();
}

export function createUserRole(data) {
  return userRoleManager.actions.create(data);
}

export function updateUserRole(data) {
  return userRoleManager.actions.update(data);
}

export function deleteUserRole(data) {
  return userRoleManager.actions.del(data);
}

export const clearError = userRoleManager.actions.clearError;
