import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { QuickDateNavigation } from "../../components/common/quickdate";
import { StringParam, NumberParam } from "use-query-params";
import { t } from "../../constant/translations";
import { connect } from "react-redux";
import { TicketPageComponent } from "./page";
import { permissions } from "../../constant/permissions";
import { RoleRequired } from "../auth/role-required";

export const ticketQueryFilterParams = {
  status: StringParam,
  assignee: StringParam,
  q: StringParam,
  created_after: NumberParam,
  ticket_type: StringParam,
};

export const ContentFilterFormBody = ({ data, onChange, assignee }) => {
  return (
    <Fragment>
      <Form.Input
        icon="search"
        placeholder={t("charge.search_capital")}
        onChange={onChange("q")}
        value={data.q || ""}
        name={"search-ticket"}
      />
      <h5>{t("ticket.created_in_last")}</h5>
      <Form.Group>
        <QuickDateNavigation
          value={data.created_after}
          onClick={(value) => onChange("created_after")(null, { value })}
        />
      </Form.Group>
      <h5>{t("billing.from_type")}</h5>
      <Form.Select
        value={data.ticket_type}
        options={[null, "technical", "other"].map((e) => {
          return { text: t(`ticketTypes.${e}`), value: e };
        })}
        onChange={onChange("ticket_type")}
      />
      <h5>{t("ticketUserTypes.assignee")}</h5>
      <RoleRequired staff={true} permission={permissions.ticketEdit}>
        <Form.Select
          value={data.assignee}
          options={assignee.map((e) => {
            return { text: e.full_name, value: e.id };
          })}
          onChange={onChange("assignee")}
        />
      </RoleRequired>
      <h5>{t("ticket.in_condition")}</h5>
      <Form.Select
        value={data.status}
        options={["all", "open", "in_progress", "closed"].map((e) => {
          return { text: t(`ticketStates.${e}`), value: e };
        })}
        onChange={onChange("status")}
      />
    </Fragment>
  );
};
export const ContentFilterForm = connect((state) => {
  const assignee = state.user.entities
    .filter((entry) => entry.is_active)
    .map(({ full_name, id, email }) => ({
      full_name: full_name || email,
      id,
    }));
  assignee.sort((a, b) => (a.full_name > b.full_name ? 1 : -1));
  return { assignee };
})(ContentFilterFormBody);
