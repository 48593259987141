import styled from "styled-components";
import React, { Fragment } from "react";
import { Dropdown, List } from "semantic-ui-react";
import { reverse, routes } from "../../routes";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { t } from "../../constant/translations";

const StyledDropdown = styled(Dropdown)`
  float: right;
`;

const StyledDescription = styled(List.Description)`
  font-style: italic;
  font-size: 0.9em;
  line-height: normal;
`;

const StyledNoteContent = styled.div`
  margin: 10px 0;
`;

export const NoteList = ({ notes, deleteNote, toggleView }) => (
  <List divided relaxed>
    {notes.map((note) => (
      <List.Item key={`note-${note.id}`}>
        <StyledDropdown icon={"ellipsis vertical"}>
          <Dropdown.Menu>
            {note.block === null && (
              <Dropdown.Item
                key={"note-block-1"}
                text={t("note.cashier_printout")}
                icon={note.tag === "payment" ? "check" : null}
                onClick={() => toggleView(note)}
              />
            )}
            {note.block === null && <Dropdown.Divider />}
            <Dropdown.Item
              text={t("userActions.delete")}
              onClick={() => deleteNote(note)}
            />
          </Dropdown.Menu>
        </StyledDropdown>
        <List.Icon
          name="sticky note"
          size="large"
          verticalAlign="top"
          color={note.tag === "payment" ? "red" : "black"}
          title={note.tag === "payment" ? t("note.cashier_note") : null}
        />
        <List.Content>
          <StyledDescription title={note.created_at}>
            {`${dayjs(note.created_at).format("YYYY-MM-DD")}`}
            {note.author.name && (
              <Fragment>
                {" - "}
                <Link
                  to={reverse(routes.teamDetails, { userId: note.author.id })}
                >
                  {note.author.name}
                </Link>
              </Fragment>
            )}
          </StyledDescription>
          <StyledNoteContent>{note.content}</StyledNoteContent>
        </List.Content>
      </List.Item>
    ))}
  </List>
);
