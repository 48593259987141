import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { t } from "../../constant/translations";

export type DeleteProps = {
  entity: Object,
  del: ?Function,
  floated: ?string,
};

const DeleteButtonWrap = styled.div`
  cursor: pointer;
  text-align: center;

  > img {
    margin: auto auto;
  }
`;

const ConfirmDeleteButton = (props) => {
  return (
    <DeleteButtonWrap>
      <Button
        icon={"delete"}
        negative
        size={"medium"}
        content={t("common.confirm_deletion")}
        onClick={() => {
          props.del(props.entity);
        }}
      />
    </DeleteButtonWrap>
  );
};

export class DeleteModal extends Component<DeleteProps> {
  state = { isOpen: false };

  handleClose = () => this.setState({ isOpen: false });
  handleOpen = () => this.setState({ isOpen: true });

  render() {
    let trigger = (
      <Button
        icon={"delete"}
        negative
        size={"tiny"}
        floated={this.props.floated}
        content={t("labels.deletion")}
        onClick={this.handleOpen}
      />
    );

    return (
      <Modal
        open={this.state.isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        closeIcon={"close"}
        trigger={trigger}
      >
        <Modal.Header>
          {t("common.want_to_delete")} {this.props.entity.name}{" "}
          {t("common.forever")}?
        </Modal.Header>
        <Modal.Content>{t("common.this_op_irreversible")}</Modal.Content>
        <Modal.Actions>
          <ConfirmDeleteButton {...this.props} />
        </Modal.Actions>
      </Modal>
    );
  }
}
