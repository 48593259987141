import { newApi, request } from "../restdux/index";
import { replaceEntity } from "../restdux/utils";

export const manager = newApi(
  "billing-monthly-report",
  "/api/billing-monthly-report/"
);
const ACTION_REGENERATE_PENDING = manager.formatAction("REGENERATE_PENDING");
const ACTION_REGENERATE_SUCCESS = manager.formatAction("REGENERATE_SUCCESS");
const ACTION_REGENERATE_ERROR = manager.formatAction("REGENERATE_ERROR");
export const reducer = (state, action: Action) => {
  switch (action.type) {
    case ACTION_REGENERATE_PENDING:
      return { ...state, regeneratePending: true };
    case ACTION_REGENERATE_ERROR:
      return {
        ...state,
        regeneratePending: false,
        regenerateError: { "@type": action.type, ...action.error },
      };
    case ACTION_REGENERATE_SUCCESS:
      return {
        ...state,
        regeneratePending: false,
        regenerateError: undefined,
        entities: replaceEntity(
          action.prevEntity,
          action.result,
          state.entities
        ),
      };
  }

  return manager.reducer(state, action);
};

export function listMonthlyReports(blockId) {
  return manager.actions.list({ block: blockId });
}

export function togglePublished(report) {
  return manager.actions.update({ ...report, published: !report.published });
}

export function regeneratePeriod(data) {
  return (dispatch, getState, client) => {
    const getPromise = () =>
      client.post(`/api/billing-monthly-report/${data.id}/regenerate/`, {});
    return request(
      ACTION_REGENERATE_PENDING,
      ACTION_REGENERATE_SUCCESS,
      ACTION_REGENERATE_ERROR
    )(getPromise, dispatch, { prevEntity: data });
  };
}
