/* @flow */
import React from "react";
import { Label } from "semantic-ui-react";
import qs from "qs";
import { withRouter } from "react-router";
import { t } from "../../constant/translations";

type Props = {
  location: Object,
  history: Object,
  keyMap: { [string]: string },
};

export class FilterDisplay extends React.Component<Props> {
  state = {
    fieldQ: {},
  };

  componentDidMount() {
    this.parseProps(this.props);
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(oldProps)) {
      this.parseProps(this.props);
    }
  }

  parseProps = (newProps) => {
    const currentQ = qs.parse(newProps.location.search, {
      ignoreQueryPrefix: true,
    });
    delete currentQ.page;
    delete currentQ.pageSize;
    this.setState({ fieldQ: currentQ });
  };

  getValue = (key: string) => {
    const value = this.state.fieldQ[key];
    if (value === "true") {
      return t("labels.yes_low");
    }
    return value;
  };

  onRemove = (key: string) => {
    const fieldQ = this.state.fieldQ;
    delete fieldQ[key];
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: qs.stringify(fieldQ, { addQueryPrefix: true }),
    });
  };

  render() {
    const { keyMap } = this.props;
    const labels = [];
    Object.keys(this.state.fieldQ).forEach((key) => {
      const mappedValue = keyMap[key];
      if (!mappedValue) {
        return;
      }
      let content;

      if (typeof mappedValue === "function") {
        content = mappedValue(this.state.fieldQ[key]);
      } else {
        content = `${mappedValue}: ${this.getValue(key)}`;
      }
      labels.push(
        <Label
          color={"teal"}
          removeIcon="delete"
          onRemove={() => this.onRemove(key)}
          content={content}
        />
      );
    });
    return <div>{labels}</div>;
  }
}

export default withRouter(FilterDisplay);
