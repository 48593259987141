import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { t } from "../../constant/translations";

export class LicenseAgreement extends React.Component {
  state = {
    iframeHeight: "1800px",
    modalOpen: false,
  };

  render() {
    const ref = React.createRef();
    return (
      <Modal
        size={"fullscreen"}
        trigger={
          <a href={"#"} onClick={() => this.setState({ modalOpen: true })}>
            {t("license.terms_of_use")}
          </a>
        }
        closeIcon={true}
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false })}
        scrolling="true"
        style={{ zIndex: 909 }}
      >
        <Modal.Content>
          <iframe
            ref={ref}
            width="100%"
            src={"/license"}
            frameBorder="0"
            style={{
              minWidth: "100%",
              minHeight: "1200px",
              textAlign: "center",
              margin: "0 0",
            }}
            height={this.state.iFrameHeight}
            scrolling="false"
            onLoad={() => {
              this.setState({
                iFrameHeight:
                  ref.current.contentWindow.document.body.scrollHeight + "px",
              });
            }}
          />
          <div style={{ textAlign: "center" }}>
            <Button
              content={t("labels.close")}
              onClick={() => this.setState({ modalOpen: false })}
              primary={true}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
