import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { entityGet } from "../../helpers/filter";
import { Loader, Segment, Statistic } from "semantic-ui-react";
import { getBillingForecast } from "../../redux/modules/billing-forecast";
import { t } from "../../constant/translations";

type Props = {
  estate: Object,
  forecast: Array<Object>,
  loading: boolean,
  getBillingForecast: Function,
};

const StyledStatiscticWrapper = styled.div`
  padding: 10px;
`;

const StyledSegment = styled(Segment)({
  display: "flex",
  "justify-content": "space-between",
  "@media (max-width: 600px)": {
    "flex-direction": "column",
    "text-align": "center",
  },
});

class EstateHeaderComponent extends Component<Props> {
  componentDidMount() {
    if (!this.props.forecast) {
      this.props.getBillingForecast(this.props.estate.id);
    }
  }

  render() {
    const { estate, forecast, loading } = this.props;
    if (loading) {
      return <Loader active={true} />;
    }
    return (
      <StyledSegment>
        <StyledStatiscticWrapper>
          <Statistic
            label={t("estate.inst_to")}
            value={`${forecast ? forecast.total : "--"} ${t("resources.bgn")}`}
            size="mini"
          />
        </StyledStatiscticWrapper>
        <StyledStatiscticWrapper>
          <Statistic
            label={t("block.current_liabilities")}
            value={`${estate.debt} ${t("resources.bgn")}`}
            size="mini"
          />
        </StyledStatiscticWrapper>
        <StyledStatiscticWrapper>
          <Statistic
            label={t("charge.balance")}
            value={`${estate.balance} ${t("resources.bgn")}`}
            size="mini"
          />
        </StyledStatiscticWrapper>
      </StyledSegment>
    );
  }
}

function stateToProps(state, props) {
  return {
    forecast: entityGet(
      state.billingForecast.entities || [],
      (entry) => entry.estate.id === props.estate.id
    ),
    loading: state.billingForecast.pending,
  };
}

function dispatchToProps(dispatch) {
  return bindActionCreators({ getBillingForecast }, dispatch);
}

export const DetailsHeader = connect(
  stateToProps,
  dispatchToProps
)(EstateHeaderComponent);
