import React from "react";
import { Image, Label, Popup } from "semantic-ui-react";

export const FilePreview = ({ file }) => {
  const label = (
    <Label
      key={file.url}
      icon="file"
      content={file.filename}
      as="a"
      href={file.url}
      download
      target={"_blank"}
    />
  );
  const ext = file.filename.split(".").pop();
  if (["jpeg", "jpg", "png"].includes(ext)) {
    return (
      <Popup
        content={<Image src={file.url} size={"medium"} />}
        position={"bottom left"}
        key={"preview"}
        trigger={label}
      />
    );
  }
  return label;
};
