/* @flow */
import * as React from "react";
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router";
import { t } from "../../constant/translations";

type Props = {
  history: Object,
  defaultLocation?: string,
  size?:
    | "mini"
    | "tiny"
    | "small"
    | "medium"
    | "large"
    | "big"
    | "huge"
    | "massive",
};

class BackButtonComponent extends React.Component<Props> {
  render() {
    const { history, size, className } = this.props;
    return (
      <Button
        onClick={history.goBack}
        className={className}
        icon={"arrow left"}
        content={t("common.back")}
        size={size || "medium"}
      />
    );
  }
}

export const BackButton = withRouter(BackButtonComponent);
