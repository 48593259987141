import React from "react";
import { SettlementBreakdown } from "../billing/settlement/breakdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchSettlements } from "../../redux/modules/settlement";
import { getById } from "../../redux/restdux/utils";
import { ErrorWrap } from "../../helpers/error";
import { withRouter } from "react-router";

export const EstateSettlementModalComponent = ({
  settlements,
  match,
  loading,
  onClose,
}) => {
  const settlement = getById(match.params.settleId, settlements);
  if (!settlement) {
    return null;
  }
  return (
    <SettlementBreakdown
      settlement={settlement}
      loading={loading}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const onClose = () => {
    ownProps.history.replace(ownProps.returnURL);
  };
  return {
    settlements: state.settlement.entities,
    loading: state.settlement.pending,
    error: ErrorWrap.fromError(state.settlement.error),
    onClose,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchSettlements }, dispatch);
};

export const SettlementModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EstateSettlementModalComponent)
);
