import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown, Segment } from "semantic-ui-react";
import dayjs from "dayjs";
import { DownloadLink } from "../../../helpers/download-link";
import { entityGetByID } from "../../../helpers/filter";
import { t } from "../../../constant/translations";

const StyledReportList = styled.div`
  display: flex;
  & > :last-child {
    margin-left: 10px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  flex-grow: 1;
`;

const BillingReportsComponent = ({ reports }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (reports && reports.length > 0) {
      setSelected(reports[0]);
    }
  }, [reports]);

  const getDate = (report) => {
    let reportDate = dayjs(report.date).format("YYYY/MM");
    if (reportDate === "1970-01") {
      reportDate = t("billing.old_period");
    }
    return reportDate;
  };

  const getLink = (report) => {
    return `/api/billing-monthly-report/${report.id}/export/?format=pdf`;
  };

  const getFilename = (report) => {
    return `${report.block_name}-${dayjs(report.created_at).format(
      "YYYY/MM"
    )}.pdf`;
  };

  const getReportOptions = (reports) => {
    return reports.map((report) => {
      const reportDate = getDate(report);
      return {
        text: `${t("block.report_to")} ${reportDate}`,
        value: report.id,
      };
    });
  };

  if (!selected) {
    return <Segment content={t("block.no_reports")} />;
  }

  return (
    <Segment>
      <StyledReportList id="noto">
        <StyledDropdown
          options={getReportOptions(reports)}
          onChange={(e, { value }) => {
            setSelected(entityGetByID(reports, value));
          }}
          scrolling
          fluid
          selection
          value={selected.id}
        />
        <DownloadLink
          href={getLink(selected)}
          download={getFilename(selected)}
          icon={"download"}
          size={"small"}
        />
      </StyledReportList>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  reports: state.billingMonthlyReport.entities,
});

export const BillingReports = connect(mapStateToProps)(BillingReportsComponent);
