/* @flow */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  Checkbox,
  Grid,
  GridColumn as Col,
  Icon,
  Table,
} from "semantic-ui-react";
import { ProfileEdit } from "./edit";
import { ContactInfo } from "../../components/profile/contact-info";
import { RoleRequired } from "../auth/role-required";
import { ErrorWrap } from "../../helpers/error";
import type Profile from "../../redux/modules/profile";
import styled from "styled-components";
import { LoginAsUser } from "../auth/login-as-user";
import { permissions } from "../../constant/permissions";
import { DownloadLink } from "../../helpers/download-link";
import { formatPhoneNumber } from "../../helpers/phone-number";
import { Link } from "react-router-dom";
import qs from "qs";
import { reverse, routes } from "../../routes";
import { t } from "../../constant/translations";

const StyledControlHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PointingCell = styled(Table.Cell)`
  cursor: pointer;
`;

type CopyEmailsProps = {
  text: string,
  label: string,
  disabled: boolean,
};

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > button {
    margin: 0 2px !important;
  }
`;

class CopyEmails extends Component<CopyEmailsProps> {
  state = {
    copying: false,
  };

  render() {
    const icon = !this.state.copying ? "copy" : "check";
    return (
      <CopyToClipboard
        text={this.props.text}
        onCopy={() => {
          this.setState({ copying: true });
          setTimeout(() => {
            this.setState({ copying: false });
          }, 2000);
        }}
      >
        <Button
          icon
          labelPosition="right"
          active={!this.state.copying}
          positive={this.state.copying}
          animated={"fade"}
          basic={true}
          disabled={this.props.disabled}
        >
          <span>{this.props.label}</span>
          <Icon name={icon} />
        </Button>
      </CopyToClipboard>
    );
  }
}

type ProfileListProps = {
  deleteProfile: Function,
  profiles: ?Array<Profile>,
  error: Object,
};

class ProfileList extends Component<ProfileListProps> {
  state = {
    selected: new Set(),
    profileIds: new Set(),
  };

  componentDidMount() {
    this.setState({
      profileIds: new Set(this.props.profiles.map((p) => p.id)),
      selected: new Set(),
    });
  }

  toggleChecked(profileId) {
    const selectedSet = new Set(this.state.selected);
    this.state.selected.has(profileId)
      ? selectedSet.delete(profileId)
      : selectedSet.add(profileId);

    this.setState({ selected: new Set(selectedSet) });
  }

  isSuperset() {
    for (let elem of this.state.profileIds) {
      if (!this.state.selected.has(elem)) {
        return false;
      }
    }
    return true;
  }

  renderControls() {
    const emails = this.props.profiles
      .filter(
        (p) =>
          this.state.selected.has(p.id) && p.email !== "" && p.email !== null
      )
      .map((p) => p.email);
    const emailString = Array.from(emails).join(",");
    return (
      <StyledControlHeader>
        <DownloadLink
          href={`/api/profile/?block=${this.props.block}&format=csv`}
          target={"_blank"}
          download={`${this.props.blockName}-${t("profile.residents")}.csv`}
          icon={"download"}
        />
        <CopyEmails
          label={t("profile.copy_selected_emails")}
          text={emailString}
          disabled={this.state.selected.size === 0}
        />
        <RoleRequired staff={true} permission={permissions.customerEdit}>
          <ProfileEdit
            block={this.props.block}
            customTrigger_props={{
              color: "blue",
              icon: "plus",
              content: t("estate.profile"),
              basic: true,
              floated: null,
            }}
          />
        </RoleRequired>
      </StyledControlHeader>
    );
  }

  render() {
    const controls = this.renderControls();

    const buildEstateFilterLink = (profile) => {
      const estateListLink = reverse(routes.blockEstateList, {
        blockId: this.props.block,
      });

      return (
        estateListLink +
        qs.stringify({ profile_id: profile.id }, { addQueryPrefix: true })
      );
    };

    return (
      <Fragment>
        <Grid centered padded>
          <Col mobile={16} textAlign={"left"}>
            {controls}
            <Table striped={true}>
              <Table.Body>
                <Table.Row>
                  {
                    <PointingCell
                      onClick={() => {
                        this.isSuperset()
                          ? this.setState({ selected: new Set() })
                          : this.setState({ selected: this.state.profileIds });
                      }}
                    >
                      <Checkbox
                        checked={
                          this.state.selected.size > 0 && this.isSuperset()
                        }
                      />
                    </PointingCell>
                  }
                </Table.Row>
                {this.props.profiles.map((profile, idx) => {
                  return (
                    <Table.Row key={`profile-${idx}`}>
                      <PointingCell
                        onClick={() => this.toggleChecked(profile.id)}
                      >
                        <Checkbox
                          checked={this.state.selected.has(profile.id)}
                        />
                      </PointingCell>
                      <Table.Cell width={4}>
                        {profile.board_member && (
                          <Icon
                            name={"certificate"}
                            color={"blue"}
                            title={t("profile.management_board")}
                          />
                        )}{" "}
                        {profile.name}
                        <br />
                        <Link to={buildEstateFilterLink(profile)}>
                          {t("side.properties")}: {profile.estate_num}
                          {profile.company && profile.company_code && (
                            <>
                              {" "}
                              ･ {t("profile.unique_ID")}: {profile.company_code}
                            </>
                          )}
                          {profile.company && profile.company_manager && (
                            <>
                              {" "}
                              ･ {t("profile.manager")}:{" "}
                              {profile.company_manager}
                            </>
                          )}
                        </Link>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        {profile.mobile_phone_number && (
                          <Fragment>
                            <Icon name={"phone"} />
                            <a href={`tel:+${profile.mobile_phone_number}`}>
                              +{formatPhoneNumber(profile.mobile_phone_number)}
                            </a>
                          </Fragment>
                        )}
                      </Table.Cell>
                      <Table.Cell width={4}>
                        {profile.email && (
                          <Fragment>
                            <Icon name={"mail"} />
                            <a href={`mailto:${profile.email}`}>
                              {profile.email}
                            </a>
                          </Fragment>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <ContactInfo contactInfo={profile.contact_info} />
                      </Table.Cell>
                      <Table.Cell collapsing={true}>
                        <Controls>
                          <RoleRequired
                            staff={true}
                            permission={permissions.customerEdit}
                          >
                            <ProfileEdit entity={profile} />
                          </RoleRequired>
                          <RoleRequired superuserOnly={true}>
                            {profile.email && <LoginAsUser profile={profile} />}
                          </RoleRequired>
                        </Controls>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Col>
        </Grid>
      </Fragment>
    );
  }
}

function mapDispatchToProps() {
  return {};
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected.id,
    blockName: state.blocks.selected.name,
    profiles: state.profile.entities,
    error: ErrorWrap.fromError(state.profile.error),
    loading: state.profile.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
