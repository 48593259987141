import React, { Component } from "react";
import { t } from "../../../constant/translations";
import { Button, Popup, Table } from "semantic-ui-react";
import dayjs from "dayjs";
import type { Settlement } from "../../../redux/modules/settlement";
import { DeleteSettlementAction } from "./delete-action";
import { Link } from "react-router-dom";
import { RoleRequired } from "../../auth/role-required";
import { reverse, reverseWithSearchParams, routes } from "../../../routes";
import { withRouter } from "react-router";
import { permissions } from "../../../constant/permissions";
import { formatDate } from "../../../helpers/date";
import { amountToBGN } from "../../../helpers/currency";

type SettlementTableProps = {
  settlements: Settlement[],
  estateLink?: boolean,
  location: Object,
  detailsRoute: string,
};

export class SettlementTableComponent extends Component<SettlementTableProps> {
  render() {
    const detailsRoute =
      this.props.detailsRoute || routes.estateSettlementDetails;
    const settlements = this.props.settlements || [];
    const estateLink =
      this.props.estateLink !== null ? this.props.estateLink : true;
    return (
      <Table striped={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("labels.date")}</Table.HeaderCell>
            {estateLink && (
              <Table.HeaderCell>
                {t("resources.estate_capital")}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>{t("settlement.casheer")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("settlement.payment_method")}
            </Table.HeaderCell>
            <RoleRequired
              staff={true}
              permission={permissions.billingAccountView}
            >
              <Table.HeaderCell>{t("settlement.fees")}</Table.HeaderCell>
            </RoleRequired>
            <Table.HeaderCell>{t("labels.deposited_amount")}</Table.HeaderCell>
            <Table.HeaderCell>{t("titles.duties_paid")}</Table.HeaderCell>
            <RoleRequired staff={true} permission={permissions.estateEdit}>
              <Table.HeaderCell> </Table.HeaderCell>
            </RoleRequired>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {settlements.map((settlement: Settlement) => (
            <Table.Row key={settlement.id} negative={settlement.deleted_at}>
              <Table.Cell>
                {dayjs(settlement.settle_date).format("YYYY-MM-DD")}
              </Table.Cell>
              {estateLink && (
                <Table.Cell>
                  <Link
                    to={reverse(routes.estateDetails, {
                      blockId: settlement.block_details.id,
                      estateId: settlement.estate_details.id,
                    })}
                  >
                    {settlement.block_details.name} -{" "}
                    {settlement.estate_details.name}
                  </Link>
                </Table.Cell>
              )}
              <Table.Cell>
                {settlement.creator_details &&
                  settlement.creator_details.first_name}{" "}
              </Table.Cell>
              <Table.Cell>
                {t(`paymentMethods.${settlement.payment_method}`, "")}{" "}
              </Table.Cell>
              <Table.Cell>{amountToBGN(settlement.fee)}</Table.Cell>
              <Table.Cell>
                <Link
                  to={reverseWithSearchParams(detailsRoute, {
                    blockId: settlement.block,
                    estateId: settlement.estate,
                    settleId: settlement.id,
                  })}
                  replace={!estateLink}
                >
                  {amountToBGN(settlement.deposit_amount)}
                </Link>
              </Table.Cell>
              <Table.Cell>{amountToBGN(settlement.settled_amount)}</Table.Cell>
              <RoleRequired staff={true} permission={permissions.estateEdit}>
                <Table.Cell textAlign={"right"}>
                  {!settlement.is_online_payment && !settlement.deleted_at && (
                    <DeleteSettlementAction settlement={settlement} />
                  )}
                  {settlement.deleted_at && (
                    <Popup
                      position={"left center"}
                      trigger={
                        <Button icon={"info"} size={"tiny"} basic={true} />
                      }
                    >
                      <h4>
                        {t("settlement.payment_reversed")}{" "}
                        {formatDate(settlement.deleted_at)}
                      </h4>
                    </Popup>
                  )}
                </Table.Cell>
              </RoleRequired>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export const SettlementTable = withRouter(SettlementTableComponent);
