import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Select } from "semantic-ui-react";

import { ErrorWrap } from "../../../helpers/error";
import type { FormProps } from "../../../components/common/edit-entity";
import { EditEntity } from "../../../components/common/edit-entity";
import {
  clearError,
  createUser,
  updateUser,
} from "../../../redux/modules/user";
import {
  FormdataManager,
  getInitialState,
} from "../../../components/common/formdata";
import { UserFile } from "../../ticket/user-file";
import { fetchUserRoles } from "../../../redux/modules/user-role";
import { t } from "../../../constant/translations";

export class UserEditComponent extends Component<FormProps> {
  state = { formData: null };

  render() {
    const { error, roles } = this.props;
    const initialState = getInitialState(
      this.props.entity,
      this.props.initialData
    );
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState
    );
    const header = this.props.entity
      ? t("management.user_change")
      : t("management.new_user");

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        header={header}
        trigger_props={{
          floated: "right",
        }}
      >
        <Form.Field>
          <label>{t("labels.name")}</label>
          <Form.Input
            placeholder={t("labels.name")}
            error={error.has("first_name")}
            {...manager.getAttributes("first_name")}
          />
          {error.has("first_name") && <span>{error.get("first_name")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("management.last_name")}</label>
          <Form.Input
            placeholder={t("management.last_name")}
            error={error.has("last_name")}
            {...manager.getAttributes("last_name")}
          />
          {error.has("last_name") && <span>{error.get("last_name")}</span>}
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Form.Input
            placeholder="Email"
            error={error.has("email")}
            {...manager.getAttributes("email")}
          />
          {error.has("email") && <span>{error.get("email")}</span>}
        </Form.Field>
        <Form.Field>
          <label>{t("resources.user-role")}</label>
          <Select
            placeholder={t("management.select_role")}
            options={roles.map((role) => {
              return { key: role.name, value: role.name, text: role.name };
            })}
            error={error.has("role_v2")}
            {...manager.getAttributes("role_v2")}
          />
          {error.has("role_v2") && <span>{error.get("role_v2")}</span>}
        </Form.Field>
        <Form.Field>
          <UserFile
            name="profile_picture"
            accept="image/*"
            oneFile={true}
            {...manager.getAttributes("profile_picture")}
          />
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: ErrorWrap.fromError(state.user.error),
    loading: state.user.pending,
    roles: state.userRole.entities || [],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { create: createUser, update: updateUser, fetchUserRoles, clearError },
    dispatch
  );
}

export const UserEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditComponent);
