// @flow
import styled, { css } from "styled-components";
import React, { Component, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Loader, Segment, Table, Label } from "semantic-ui-react";
import { listBillingForecast } from "../../../redux/modules/billing-forecast";
import { noZero } from "../../../helpers/billing";
import { Link } from "react-router-dom";
import { reverse, routes } from "../../../routes";
import { amountToBGN, amountToBGNOrEmpty } from "../../../helpers/currency";
import { apirequest } from "../../../helpers/api-client";
import { ErrorWrap } from "../../../helpers/error";
import { DownloadLink } from "../../../helpers/download-link";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";
import { t } from "../../../constant/translations";

type BudgetRowProps = {
  entity: Object,
  models: [Object],
  displayCalculated: boolean,
  editing: boolean,
};

const Controller = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const BreakdownInput = styled.input`
  background-color: transparent;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const FocusableCell = styled(Table.Cell)`
  [disabled] {
    color: inherit;
  }

  ${(props) =>
    props.$isFocused &&
    css`
      box-shadow: 3px 3px 5px ${props.theme.primary};
      border-radius: 6px 6px 6px 6px;
    `}
  ${(props) =>
    props.$hasError &&
    css`
      background-color: ${props.theme.errorBg};
    `}
`;

const EditableCell = ({ value, editing, mountIdx, onChange, error }) => {
  const inputRef = useRef(null);
  const [focused, setFocus] = useState(false);
  useEffect(() => {
    if (mountIdx === "0-0") {
      inputRef.current.focus();
    }
  }, [editing]);

  return (
    <FocusableCell
      $isFocused={focused}
      $hasError={error}
      onClick={() => {
        editing && inputRef.current.focus();
      }}
    >
      <BreakdownInput
        value={value}
        disabled={!editing ? "disabled" : ""}
        type={editing ? "number" : ""}
        step="0.01"
        onFocus={(event) => {
          event.target.select();
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        ref={inputRef}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </FocusableCell>
  );
};

class BudgetList extends Component {
  state = {
    displayCalculated: true,
    editing: false,
    changelist: {},
    loading: false,
    error: null,
    filterBy: null,
  };

  changelistID = (entity, model) => {
    return entity.estate.id.toString() + model.id.toString();
  };

  storeChanges = () => {
    this.setState({ loading: true, error: null });
    apirequest("post", "/api/billing-attribute-bulk-upsert/", {
      attributes: Object.values(this.state.changelist),
    }).then(
      (success) => {
        this.props.listBillingForecast(this.props.block);
        this.setState({ editing: false, loading: false, changelist: {} });
      },
      (err) => {
        this.setStatus({
          loading: false,
          error: ErrorWrap.fromError(result.response.body),
        });
      }
    );
  };

  getBreakdown = (entity, model) => {
    const changedValue = this.state.changelist[
      this.changelistID(entity, model)
    ];
    return changedValue || entity.breakdown[model.type] || {};
  };

  getTotals = (entity, models) => {
    let total = 0;
    models.forEach((model) => {
      const breakdown = this.getBreakdown(entity, model);
      const amount = parseFloat(breakdown.amount);
      if (!isNaN(amount)) {
        total = total + amount;
      }
    });
    return amountToBGN(noZero(total));
  };

  onFieldChange = (entity, model, value) => {
    if (value < 0) {
      return;
    }

    const changelist = this.state.changelist;
    const change = {
      estate: entity.estate.id,
      model: model.id,
      type: model.type,
      value: value,
    };

    if (value) {
      const modelPrice = parseFloat(model.price);
      const modelMinPrice = parseFloat(model.minimum);
      change.amount = Math.max(value * modelPrice, modelMinPrice);
    } else {
      change.amount = 0;
    }

    changelist[this.changelistID(entity, model)] = change;
    this.setState({
      error: null,
      changelist,
    });
  };

  render() {
    const { models, loading } = this.props;

    let forecast = this.props.forecast;

    if (!forecast || !models || loading) {
      return <Loader active={true} />;
    }

    if (this.state.filterBy) {
      forecast = forecast.filter((x) =>
        x.forecast.hasOwnProperty(this.state.filterBy)
      );
    }

    // Calculate the sum of all values for each attribute and the sum of all row totals
    const perAttributeTotal = [];
    let totalsTotal = 0.0;
    forecast.forEach((entity, rowIdx) => {
      models.forEach((model, modelIdx) => {
        const breakdown = this.getBreakdown(entity, model);
        let value = breakdown.amount;
        if (perAttributeTotal.length <= modelIdx) {
          perAttributeTotal.push(0.0);
        }
        if (value) {
          totalsTotal += Number(value);
          perAttributeTotal[modelIdx] += Number(value);
        }
      });
    });

    return (
      <Segment>
        <Controller>
          <DownloadLink
            href={`/api/billing-forecast?block=${this.props.block}&format=csv`}
            target={"_blank"}
            download={`${this.props.block}-` + t("billing.budget") + `.csv`}
            icon={"download"}
            disabled={this.state.editing}
          />
          <Button
            size={"tiny"}
            toggle={true}
            disabled={this.state.editing}
            active={!this.state.displayCalculated && !this.state.editing}
            onClick={() =>
              this.setState({
                displayCalculated: !this.state.displayCalculated,
              })
            }
            content={t("billing.attributes")}
          />
          <RoleRequired
            admin={true}
            permission={permissions.billingAttributeEdit}
          >
            <Button
              size={"tiny"}
              icon={this.state.editing ? "close" : "edit"}
              secondary={this.state.editing}
              onClick={() => {
                this.setState({
                  editing: !this.state.editing,
                  displayCalculated: this.state.editing,
                  changelist: {},
                });
              }}
            />
          </RoleRequired>
        </Controller>
        <div style={{ overflow: "auto", maxHeight: 550, margin: "10px 0" }}>
          <Table
            unstackable={true}
            celled={true}
            striped={true}
            selectable={true}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("resources.estate_capital")}
                </Table.HeaderCell>
                {models.map((entry, idx) => {
                  return (
                    <Table.HeaderCell key={idx}>
                      {entry.type}
                      <div>{`(${amountToBGN(
                        noZero(perAttributeTotal.at(idx))
                      )})`}</div>
                    </Table.HeaderCell>
                  );
                })}
                <Table.HeaderCell>
                  {t("billing.full_payment")}
                  <div>{`(${amountToBGN(noZero(totalsTotal))})`}</div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {forecast.map((entity, rowIdx) => {
                const totals = this.getTotals(entity, models);
                if (totals !== amountToBGN(noZero(entity.total))) {
                }
                return (
                  <Table.Row key={entity.estate.id}>
                    <Table.Cell>
                      <Link
                        tabIndex={this.state.editing ? "-1" : "0"}
                        to={reverse(routes.estateDetails, {
                          blockId: entity.estate.block_id,
                          estateId: entity.estate.id,
                        })}
                      >
                        {entity.estate.name}
                      </Link>
                    </Table.Cell>
                    {models.map((model, idx) => {
                      const breakdown = this.getBreakdown(entity, model);
                      let value = this.state.displayCalculated
                        ? amountToBGNOrEmpty(breakdown.amount)
                        : breakdown.value;
                      value = value || "";
                      return (
                        <EditableCell
                          key={idx}
                          mountIdx={`${rowIdx}-${idx}`}
                          value={value}
                          editing={this.state.editing}
                          onChange={(value) => {
                            this.onFieldChange(entity, model, value);
                          }}
                          error={breakdown.error}
                        />
                      );
                    })}
                    <Table.Cell
                      warning={
                        this.state.editing &&
                        totals !== amountToBGN(noZero(entity.total))
                      }
                    >
                      {totals}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <Controller>
          {this.state.editing && (
            <Button
              size={"tiny"}
              icon={"check"}
              floated={"right"}
              loading={this.state.loading}
              onClick={() => {
                this.storeChanges();
              }}
              positive={true}
              content={t("billing.save")}
            />
          )}
        </Controller>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const models = state.billingModel.entities;
  return {
    forecast: state.billingForecast.entities,
    models: models,
    loading: state.billingForecast.pending || state.billingModel.pending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      listBillingForecast,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetList);
