import { combineReducers } from "redux";
import { genDeps } from "../multideps/mutlideps";
import { fetchBillingModels } from "./billing-models";
import { fetchBillingAttributes } from "./billing-attributes";
import { getBillingForecast } from "./billing-forecast";

export const blockDeps = genDeps("block");
export const estateBillDeps = genDeps("estateBill");

export function loadEstateBillingDeps(ids) {
  const getPromises = () => [
    fetchBillingModels(ids.block),
    fetchBillingAttributes(ids.estate),
    getBillingForecast(ids.estate),
  ];
  return estateBillDeps.loadDeps(getPromises);
}

function getCombinedReducers(...args) {
  const result = {};
  for (const dep of args) {
    result[[dep.name]] = dep.reducer;
  }
  return combineReducers(result);
}

export const reducer = getCombinedReducers(blockDeps, estateBillDeps);
