import styled from "styled-components";

import { Button, Dropdown, Form, Input, Label } from "semantic-ui-react";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDirectories, uploadFiles } from "../../redux/modules/file";
import { t } from "../../constant/translations";

type Props = {
  block: Object,
  dirs: string,
  currentFiles: int,
  loading: ?boolean,
};

const StyledDropdown = styled(Dropdown)`
  min-height: 44px !important;
`;

const StyledButton = styled(Button)`
  margin: 1.6em 0.4em 0.1em 0.6em !important;
`;

export class UploadFormComponent extends React.Component<Props> {
  state = {
    files: null,
    dirOptions: null,
    dir: t("file.common"),
  };

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentFiles !== prevProps.currentFiles) {
      this.fileInput.current.value = "";
      this.setState({ files: null, dir: t("file.common") });
    }
  }

  uploadFiles = () => {
    this.props.uploadFiles(
      {
        block: this.props.block.id,
        directory_name: this.state.dir,
      },
      this.state.files
    );
  };

  mapToOptions = (dirs) => {
    dirs = dirs || [];
    return dirs.map((element) => {
      return { key: element, text: element, value: element };
    });
  };

  render() {
    const dirOptions =
      this.state.dirOptions || this.mapToOptions(this.props.dirs);
    return (
      <Form>
        <Form.Field>
          <label>{t("file.document_select")}</label>
          <input
            className={"dropzone"}
            type={"file"}
            multiple
            ref={this.fileInput}
            onChange={(event) =>
              this.setState({ files: Array.from(event.target.files) })
            }
          />
        </Form.Field>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>{t("file.directory")}</label>
            <StyledDropdown
              name={"dir"}
              options={dirOptions}
              placeholder={t("file.folder")}
              search
              selection
              fluid
              allowAdditions
              value={this.state.dir}
              onAddItem={(e, { value }) => {
                this.setState({
                  dirOptions: [{ text: value, value }, ...dirOptions],
                });
              }}
              onChange={(e, { value }) => {
                this.setState({ dir: value });
              }}
            />
          </Form.Field>
          <Form.Field>
            <StyledButton
              fluid={true}
              primary={true}
              type={"submit"}
              onClick={this.uploadFiles}
              loading={this.props.loading}
              disabled={!this.state.files || this.props.loading}
            >
              {t("file.attach")}
            </StyledButton>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    block: state.blocks.selected,
    dirs: getDirectories(state.file.entities),
    currentFiles: state.file.entities ? state.file.entities.length : 0,
    loading: state.file.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ uploadFiles }, dispatch);
}

export const UploadForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFormComponent);
