import { translationBG } from "./bg";
import { translationEN } from "./en";

export const translationResource = {
  "en-US": {
    translation: translationEN,
  },
  "bg-BG": {
    translation: translationBG,
  },
};
