import { defaultInitialState, newApi } from "../restdux/index";
import { addEntity, getById, getSpecificError } from "../restdux/utils";
import { noteManager } from "./note";

export const SELECT = "mecasa/estate/SELECT";

const initialState = {
  ...defaultInitialState,
  selected: null,
};

export const estatesApi = newApi("estate", "/api/estate/", true);

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT:
      return {
        ...state,
        status: SELECT,
        selected: getById(action.estateId, state.entities),
      };
    case noteManager.actionTypes.CREATE_SUCCESS:
      if (action.entityType === "estate") {
        const estate = getById(action.result.estate, state.entities);
        if (estate) {
          estate.notes_num += 1;
          return { ...state, entities: addEntity(estate, state.entities) };
        }
      }
      return state;
    case noteManager.actionTypes.DELETE_SUCCESS:
      if (action.entityType === "estate") {
        const estate = getById(action.entity.estate, state.entities);
        if (estate) {
          estate.notes_num -= 1;
          return { ...state, entities: addEntity(estate, state.entities) };
        }
      }
      return state;
    default:
      return estatesApi.reducer(state, action);
  }
}

export function selectEstate(estateId) {
  return { type: SELECT, estateId: parseInt(estateId) };
}

export function getListError(estateState) {
  return getSpecificError(estatesApi.actionTypes.LIST_ERROR, estateState);
}

export function fetchEstates(params) {
  return estatesApi.actions.list(params);
}

export const withRefreshEstate = (method, estateId, inBackground = true) => (
  ...args
) => (dispatch) => {
  return dispatch(method(...args)).then((res) => {
    if (!res.error) {
      dispatch(getEstate(estateId, inBackground));
    }
  });
};

export function getEstate(estateId, inBackground = false) {
  return estatesApi.actions.get({ id: estateId }, { inBackground });
}

export function createEstate(data) {
  return estatesApi.actions.create(data);
}

export function updateEstate(data) {
  return estatesApi.actions.update(data, { inBackground: true });
}

export function deleteEstate(data) {
  return estatesApi.actions.del(data);
}

export const clearError = estatesApi.actions.clearError;
