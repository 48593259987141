import React from "react";

import { Button, Form, FormField, Input, Message } from "semantic-ui-react";
import { register, reset } from "../../redux/modules/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ErrorWrap } from "../../helpers/error";
import { StyledErrorSpan } from "./styled-components";
import styled from "styled-components";
import { reverse, routes } from "../../routes";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { t } from "../../constant/translations";

type Props = {
  error?: Object,
  register?: Function,
};

const StyledSubtext = styled.p`
  font-style: italic;
  margin: 6px 0 0 6px;
`;

const StyledLink = styled(Link)`
  vertical-align: baseline;
`;

class RegisterComponent extends React.Component<Props> {
  state = {
    data: {},
  };

  onSubmit(e) {
    e.preventDefault();
    this.props.register(
      this.state.data.email,
      this.state.data.full_name,
      this.state.data.mobile_phone_number
    );
  }

  setField(name, value) {
    const data = this.state.data || {};
    if (value) {
      data[name] = value;
    } else {
      delete data[name];
    }
    this.setState({ data: data });
  }

  render() {
    const error = new ErrorWrap(this.props.error);
    const isComplete = this.state.data.email && this.state.data.full_name;
    return (
      <Form
        action=""
        onSubmit={this.onSubmit.bind(this)}
        error={error.exists()}
      >
        <Message
          positive={true}
          icon={"sign-in"}
          content={<>{t("register.tryout")}</>}
        />

        <FormField>
          <Input
            name="full_name"
            icon="user"
            placeholder={t("labels.names")}
            iconPosition="left"
            error={error.has("full_name")}
            onChange={(event) => this.setField("full_name", event.target.value)}
          />
          {error.has("full_name") && (
            <StyledErrorSpan>{error.get("full_name")}</StyledErrorSpan>
          )}
        </FormField>
        <FormField>
          <Input
            name="email"
            icon="mail"
            iconPosition="left"
            placeholder="Е-mail"
            error={error.has("email")}
            onChange={(event) => this.setField("email", event.target.value)}
          />
          {error.has("email") && (
            <StyledErrorSpan>{error.get("email")}</StyledErrorSpan>
          )}
        </FormField>
        <FormField>
          <Input
            name="mobile_phone_number"
            icon="phone"
            iconPosition="left"
            placeholder="359889000000"
            error={error.has("mobile_phone_number")}
            onChange={(event) =>
              this.setField("mobile_phone_number", event.target.value)
            }
          />
          {error.has("mobile_phone_number") && (
            <StyledErrorSpan>
              {error.get("mobile_phone_number")}
            </StyledErrorSpan>
          )}
        </FormField>
        <Message error content={error.getGlobal()} />
        <StyledSubtext>
          {t("register.existing")}{" "}
          <StyledLink to={routes.authLogin}>{t("labels.here")}</StyledLink>
        </StyledSubtext>
        <Button
          type="submit"
          fluid
          primary={true}
          size="large"
          loading={this.props.loading}
          disabled={!isComplete || this.props.loading}
        >
          {t("register.demo")}
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    userKey: state.auth.userKey,
    loading: state.auth.loggingIn,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const registerAndRedirect = (email, full_name, mobile_phone_number) => {
    return (dispatch) =>
      dispatch(register({ email, full_name, mobile_phone_number })).then(
        () => {
          window.location.href = reverse(routes.baseURL);
        },
        (err) => {
          return err;
        }
      );
  };

  return bindActionCreators({ register: registerAndRedirect, reset }, dispatch);
};

export const Register = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterComponent)
);
