/* @flow */
import styled from "styled-components";
import * as React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import qs from "qs";

const StyledLink = styled(Link)({
  "margin-right": "1em",
});

type Props = {
  estate: Object,
  location: Object,
};

class CustomerListPanelComponent extends React.Component<Props> {
  render() {
    const { estate, location } = this.props;
    const profiles = estate.customers;
    return profiles.map((profile) => {
      return (
        <StyledLink
          to={{
            pathname: location.pathname,
            search: qs.stringify({ name: profile.name }),
          }}
          key={profile.name}
        >
          <Icon name={"user"} />
          {profile.name}
        </StyledLink>
      );
    });
  }
}

export const CustomerListPanel = withRouter(CustomerListPanelComponent);
