import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Checkbox, Form, Select } from "semantic-ui-react";
import { permissions } from "../../constant/permissions";
import type { FormProps } from "../../components/common/edit-entity";
import { EditEntity } from "../../components/common/edit-entity";
import {
  clearError,
  createEstate,
  deleteEstate,
  updateEstate,
} from "../../redux/modules/estates";
import {
  FormdataManager,
  getInitialState,
} from "../../components/common/formdata";
import { ErrorWrap } from "../../helpers/error";
import { t } from "../../constant/translations";
import { t as asd } from "../../constant/translations";
import { withRouter } from "react-router";
import { reverse, routes } from "../../routes";

export class EstateEditModalComponent extends Component<FormProps> {
  state = {
    formData: null,
  };

  buildTypeOptions = () => {
    const availableTypes = [
      "apartment",
      "garage",
      "office",
      "store",
      "parking_space",
      "studio",
      "shop",
      "atelier",
      "restaurant",
      "gym",
      "laboratory",
      "other",
    ];
    return availableTypes.map((entry) => {
      return {
        key: entry,
        value: entry,
        text: t(`estateTypes.${[entry]}`) || entry,
      };
    });
  };

  render() {
    const { error, entity, user, del } = this.props;
    const initialState = getInitialState(
      this.props.entity,
      this.props.initialData
    );
    const manager = new FormdataManager(
      this.state.formData || initialState,
      (formData) => this.setState({ formData }),
      initialState,
      (change) => this.props.clearError()
    ).withError(error);

    // Check if the user has the delete permission
    const canDeleteEstate =
      user && user.hasPermission(permissions.estateDelete);

    return (
      <EditEntity
        manager={manager}
        {...this.props}
        del={canDeleteEstate ? del : undefined} // Conditionally pass del based on permission
        header={
          !entity ? asd("estate.add_estate") : asd("estate.change_estate")
        }
        trigger_props={{
          content: this.props.buttonContent,
        }}
      >
        <Form.Field>
          <label>{asd("labels.name")}</label>
          <Form.Input
            placeholder={asd("labels.name")}
            {...manager.getAttributes("name")}
          />
        </Form.Field>
        <Form.Field>
          <label>{asd("labels.type")}</label>
          <Select
            placeholder={asd("estate.select_type")}
            options={this.buildTypeOptions()}
            {...manager.getAttributes("etype")}
          />
        </Form.Field>
        <Form.Field>
          <label>{asd("estate.floor")}</label>
          <Form.Input
            type="number"
            placeholder={asd("estate.floor")}
            {...manager.getAttributes("floor")}
          />
        </Form.Field>
        <Form.Field>
          <label>{asd("estate.preferred_payment_method")}</label>
          <Select
            placeholder={asd("estate.pick_payment_method")}
            options={[
              { key: "cash", value: "cash", text: asd("billing.cash") },
              { key: "bank", value: "bank", text: asd("billing.bank") },
              {
                key: "easypay",
                value: "easypay",
                text: asd("billing.easypay"),
              },
              { key: "epay", value: "epay", text: asd("billing.epay") },
            ]}
            {...manager.getAttributes("preferred_payment_method")}
          />
          {error.has("preferred_payment_method") && (
            <span>{error.get("preferred_payment_method")}</span>
          )}
        </Form.Field>
        <Form.Field>
          <Checkbox
            toggle
            label={asd("estate.automatic_tax")}
            checked={Boolean(manager.getValue("charging_enabled"))}
            onClick={() => {
              manager.setValue(
                "charging_enabled",
                !manager.getValue("charging_enabled")
              );
            }}
          />
        </Form.Field>
      </EditEntity>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.estates.pending,
    error: ErrorWrap.fromError(state.estates.error),
    lastModified: state.estates.lastModified,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const create = (data) => (dispatch) => {
    return dispatch(createEstate(data)).then((success) => {
      const estate = success.result;
      ownProps.history.push(
        reverse(routes.estateDetails, {
          blockId: estate.block.id,
          estateId: estate.id,
        })
      );
    });
  };
  const del = (data) => (dispatch) => {
    dispatch(deleteEstate(data)).then(() => {
      ownProps.history.push(
        reverse(routes.blockEstateList, {
          blockId: data.block.id,
        })
      );
    });
  };

  return bindActionCreators(
    { create, update: updateEstate, clearError, del },
    dispatch
  );
}

export const EstateEditModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EstateEditModalComponent)
);
